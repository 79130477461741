.code-editor{
  height:100%;
} 

.code-editor #console{  height:100%;}
.code-editor #console .CodeMirror{  height:100%;}

.codeditor .page-title{
  font-size:26px;
  margin-bottom:0;
  margin-top:10px;
}

.codeditor .description{
  color: #888;
}

.page-aside.codeditor .compose{
  margin-top: 10px;
  font-size:14px;
  font-weight: 300;
  text-transform:uppercase;
  text-align:center;
  padding:20px 25px;
}

.page-aside.codeditor .compose .btn{
  width:100%;
  border:0;
  border-radius:3px !important;
  max-width:180px;
}

.code-cont .main-app{
  height:100%;
}

@media (max-width: 767px) {
  .cl-mcont.aside{
    display:block;
  }
  
  .page-aside.codeditor{
    display:block;
  }

  .aside .content{
    display:block;
  }
  
  .page-aside > div {
    width:100%;
  }
  
  .cl-mcont.aside > .content {
    display: block;
  }
  
  .page-aside.codeditor .mail-nav ul li {
    display: block;
  }
  
  .page-aside.codeditor .mail-nav{
    margin-top:0;
    padding:0;
    width:100%;
    border-bottom:1px solid #E5E5E5;
  }
  
  .page-aside.codeditor .mail-nav .compose{
    border-top:1px solid #E5E5E5;
    background:#FCFCFC;
  }
  
  .page-aside.codeditor div.content{
    border-bottom:1px solid #E5E5E5;
  }
  
  .page-aside.codeditor .mail-nav.collapse{display:none;}
  .message .mail{border-top: 0;}
}

.CodeMirror {
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
