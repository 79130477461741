/*Forms*/
label{
	font-weight:600;
	margin-bottom:7px;
}

.form-group{margin-top:10px;}

.form-horizontal.group-border .form-group{
	margin:0;
	padding:20px 0;
	border-bottom: 1px solid #efefef;
}

.form-horizontal.group-border .form-group:last-child{border-bottom:0;}

.form-horizontal.group-border-dashed .form-group{
	margin:0;
	padding:10px 0;
	border-bottom: 1px dashed #efefef;
}

.form-horizontal.group-border-dashed .form-group:last-child{border-bottom:0;}

.form-control {
	padding:6px 8px;
	font-size:13px;
  font-weight: 400;
	border-radius: 1px;
	-webkit-border-radius: 1px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
}

.input-group .btn{
	margin:0 !important;
	margin-left:-1px !important;
	padding-bottom:6px;
	padding-top:6px;
	box-shadow:none;
	line-height: 20px;
}

.input-group-btn > .btn + .btn {
    margin-left: -5px !important;
}

.input-group-lg > .input-group-addon{line-height:0.33;  border-radius: 3px 0 0 3px;-webkit-border-radius: 3px 0 0 3px;}
.input-group-addon {
	border-radius:2px;
	-webkit-border-radius:2px;
	padding: 6px 11px;
    background-color: @pqsgreen;
    border-color: @pqsgreen;
    color: #fff;
}

.input-group-addon-fixed-width {
	min-width: 40px;
}

.form-control:focus {
	border-color:@pqsgreen;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
}

select.form-control, textarea.form-control{
	padding:6px 8px;
}

.input-group{
	margin-bottom:15px;
}

/*Crhome Voice Recognition*/
.speechbutton{
  /*display:none;*/
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  position:absolute;
  border:0;
  background:transparent;
  opacity:0;
  cursor:pointer;
}

.speech-button i:before{position:relative;z-index:2;}
.speech-button i:after{
  z-index:1;
  font-family: 'FontAwesome';
  content:"?";
  font-size: 14px;
  color:#fff;
  position:absolute;
  left: 0;
  display:inline-block;
  vertical-align:middle;
  height:100%;
  width:100%;
  opacity:0;
}

@keyframes blur {from {font-size: 14px;opacity:1;margin-top:0;} to {font-size:25px;opacity:0;margin-top:-6px;}}
@-webkit-keyframes blur { 0% {font-size: 14px;opacity:1;margin-top:0;} 90% {font-size:25px;opacity:0;margin-top:-6px;} 100%{font-size:14px;}}
@-moz-keyframes blur {from {font-size: 14px;opacity:1;margin-top:0;} to {font-size:25px;opacity:0;margin-top:-6px;}}
@-o-keyframes blur {from {font-size: 14px;opacity:1;margin-top:0;} to {font-size:25px;opacity:0;margin-top:-6px;}}


.speech-button i.blur:after{animation: blur 900ms infinite;-webkit-animation: blur 900ms ease infinite;-moz-animation: blur 900ms infinite;-o-animation: blur 900ms infinite;}

input::-webkit-input-speech-button {
 -webkit-transform: scale(3,2.2) translate(-2px,-1px);
  background-color: #fff;
  display: block !important;
}
