
/*Accordion*/
.accordion{
	margin-bottom:40px;
}

.accordion .panel-heading{
	background: #fff;
	padding:0;
}

// .accordion .panel-title {
// 	font-size: 22px;
// }

.accordion .panel-heading a{
	font-size:14px;
	line-height: 18px;
	padding:17px 16px;
	display:block;
	color:#3078EF;

	span {
		font-size:18px;
	}
}

.accordion .panel-heading a .fa{
	margin-right:10px;
	margin-left:10px;
	color:@pqslink;
	&:before {
		content:"\f146";
	}
}

.accordion .panel-heading a.collapsed{
	color:#555;
}

.accordion .panel-heading a.collapsed .fa:before{
	content:"\f0fe";
}

.accordion  .panel-heading + .panel-collapse .panel-body {
	border-top:0;
	padding:3px 20px 20px 34px;
}

.accordion .panel{
	border-radius:2px;
	-webkit-border-radius:2px;
	border-bottom:0;
	border-left:1px solid #ECECEC;
	border-right:1px solid #ECECEC;
	margin-bottom: 5px;
	border-top:0;
	box-shadow:0 1px 0 0 rgba(0, 0, 0, 0.04);

	&.inner {
		border: 1px solid #ECECEC;
	}
}

.accordion .panel:not(.inner):first-child{
	border-top:0;
}

.accordion .panel:last-child{

}

.accordion.panel-group .panel + .panel {
    margin-top: 0px;
}

/*Primary color accordion*/
.accordion.accordion-color .panel-heading a{
	background-color: @pqsgreen;
	color:#FFF;
}

.accordion.accordion-color .panel-heading a.collapsed{
	background-color: #FFF;
	color:#555;
}

.accordion.accordion-color .panel-collapse .panel-body{
	background-color: @pqsgreen;
	color:#FFF;
}

/*Semi color accordion*/
.accordion.accordion-semi .panel-heading a{
	background-color: @pqsgreen;
	color:#FFF;
	transition:background-color 200ms ease-in-out;
	border-bottom:1px solid darken(@pqsgreen, 5%);
}

.accordion.accordion-semi .panel-heading.success a{background-color: #60C060;border-bottom:1px solid #54A754;}
.accordion.accordion-semi .panel-heading.warning a{background-color: #FC9700;border-bottom:1px solid #D68000;}
.accordion.accordion-semi .panel-heading.danger a{background-color: #DA4932;border-bottom:1px solid #CA452E;}


.accordion.accordion-semi .panel-heading a.collapsed{
	background-color: #FFF;
	color:#555;
	border-bottom:0;
}

.accordion.accordion-semi .panel-collapse .panel-body{
	// padding:15px 20px 20px 34px;
	padding: 17px 25px;
}
