/*Todo Widget*/

.list-group.todo .list-group-item{
  font-size:13px;
  border:0;
  margin-bottom:10px; 
  background:#FFF;
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #EFEFEF;
  border-radius: 3px;
  border-right: 1px solid #EFEFEF;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  padding: 13px 15px;
} 

.list-group.todo .list-group-item .date{
  font-size:12px;
  float:right;
}

