.main-app .options{
  padding: 20px 0 5px 0;
}

.main-app .options .radio-inline:first-child{
  padding-left: 0;
}

.main-app .options .radio-inline{
  margin: 0;
}

.main-app .options .form-group{
  margin:0;
}

.main-app .items.products .item .price{
  font-size: 22px;
  font-weight: 300;
}

.main-app .items.products .item .product-image{
  float:left;
  margin-right:10px;
}

.app.filters .app-nav .form-group{
  margin-bottom:35px;
}

.app.filters .app-nav .content{
  padding-top:0;
}

@media (max-width: 767px) {

}
