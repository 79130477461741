table thead th{
	padding:5px;
	color: #3c3c3b;
	font-weight: 500;
}

table tbody td{
	padding:7px 8px;
}

table .primary-emphasis, table .primary-emphasis-dark{
	background: #4D90FD;
	color: #FFF;
	border-color: #4D90FD;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .primary-emphasis-dark{background-color:#437edd;}

table .success-emphasis, table .success-emphasis-dark{
	background: #60C060;
	color: #FFF;
	border-color: #60C060;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .success-emphasis-dark{background-color:#58b058;border-color:#58b058;}

table .warning-emphasis, table .warning-emphasis-dark{
	background: #FC9700;
	color: #FFF;
	border-color: #FC9700;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .warning-emphasis-dark{background-color:#fc8800;border-color:#fc8800;}

table .danger-emphasis, table .danger-emphasis-dark{
	background: #DA4932;
	color: #FFF;
	border-color: #DA4932;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .danger-emphasis-dark{background-color:#c8432e;border-color:#c8432e;}


 table{
	border-collapse:collapse;
	width:100%;
}

table.no-border{
	border:0;
}

table .right{text-align:right;}
table .left{text-align:left;}
.red thead th{color:#d36442;}
.blue thead th{color:#3078EF;}
.violet thead th{color:#8b12ae;}
.green thead th{color:#4da60c;}
table thead th span{color:#333;}

table thead th{
	vertical-align:middle;
	border-bottom:1px solid #DADADA;
	border-left:1px solid #DADADA;
	border-top:1px solid #DADADA;
	padding: 10px 8px 5px 8px;
}

table thead th:last-child{border-right:1px solid #DADADA;}


table thead span{
	font-size:15px;
	display:block;
}

table td{
	border-left:1px solid #DADADA;
	border-bottom: 1px solid #dadada;
	padding: 7px 8px;
}

table.padding-sm td{padding: 4px 6px;}

table td .progress{margin:0;}

table.hover tbody tr:hover{
	background: #f3f3f3;
}

//table td i{
//	font-size:15px;
//	display: inline-block;
//	text-align: center;
//	width: 23px;
//}

 table td:last-child{border-right:1px solid #dadada;}
 table tr:nth-child(2n){
	background: #f8f8f8;
}

table.no-strip tr:nth-child(2n){background:transparent;}

/*No-Internal borders in thead*/
table .no-border th{border-left:0;}
table .no-border tr th:first-child{border-left:1px solid #dadada;}


/*No-Internal borders in tbody x and y*/
 table .no-border-x td{border-bottom:0;}
 table .no-border-x tr:last-child td{border-bottom:1px solid #dadada;}

 table .no-border-y td{border-left:0;}
 table .no-border-y tr td:first-child{border-left:1px solid #dadada;}


/*No-External borders general table*/
 table.no-border tr th{border-top:0;}
 table.no-border tr th:first-child{border-left:0;}
 table.no-border tr th:last-child{border-right:0;}

 table.no-border tr td:first-child{border-left:0;}
 table.no-border tr td:last-child{border-right:0;}
 table.no-border tr:last-child td{border-bottom:0;}

/*No-External borders when .no-padding in block*/
 .no-padding table th:first-child{border-left:0;}
 .no-padding table th:last-child{border-right:0;}
 .no-padding table tr td:first-child{border-left:0;}
 .no-padding table tr td:last-child{border-right:0;}
 .no-padding table tr:last-child td{border-bottom:0;}

table tbody .toggle-details{cursor:pointer;}
table tbody .details{
  background:#FFF;
}

table tbody td .btn{margin-bottom:0 !important;}
table tbody td .btn-group .dropdown-menu {margin-top:-1px;min-width:130px;}
table tbody td .btn-group .dropdown-menu li > a {padding:5px 12px;text-align:left;}

table td .flag{    text-align: center;  padding: 0 4px;}
table td .legend{width:10px;height:10px;background:#efefef;}

div.dataTables_length label select{
  display:inline !important;
}

.table td .btn i{
  width:inherit;
}

// Tables

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {

    border-top: 2px solid #ddd;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}