/*Flot Charts*/
.blue-chart .flot-text, .red-chart .flot-text{color:#FFF !important;}

.flot-text{font-size: 10px !important;}

.legend table td{
	font-size:11px !important;
}

.blue-chart{
	border-left:1px solid #258fec;
	border-right:1px solid #258fec;
	border-bottom:1px solid #1e73bd;
	border-top:1px solid #499de5;
	background-image:-moz-linear-gradient(center top , #52AEFF 45%, #2180D3 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#52AEFF), to(#2180D3)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #52AEFF, #2180D3); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #52AEFF, #2180D3); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #52AEFF, #2180D3);
	background-color:#3290E2;
}

.block-flat .blue-chart{	padding:20px !important;}

.blue-chart h2{
	text-shadow: 0 -1px 0 #357FC0;
}

.easyPieChart{display:inline-block;}

