/*Page Title*/
.page-head {
    background: none repeat scroll 0 0 #FFFFFF;
    border-bottom: 1px solid #E9E9E9;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05);
    padding: 10px 30px;
    position: relative;
}

.page-head h2 {
    margin-bottom: 5px;
    margin-top: 6px;
	font-family: @pqsfont, sans-serif;
    font-weight: 300;
}

.page-head .breadcrumb {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    margin-bottom: 0;
    padding: 4px;
}

.custom-header {
    margin-bottom: 5px;
    h1 {
        margin-bottom: 0px;
    }
}
