
/*Login*/
#cl-wrapper.login-container, #cl-wrapper.error-container{
	margin:0;
}

.login-container .middle-login{
	width:430px;
	left: 50%;
	//top: 50%;
	position:absolute;
	//margin-top: -220px;
	margin-left: -215px;
}

.login-container .block-flat{
	padding:0;
	border:0;
	overflow:hidden;
	border-radius:2px;
	-webkit-border-radius:2px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, .3);
	margin-bottom:20px;
}

.login-container .block-flat .header{
	padding: 9px 20px;
	background: @pqsgreen;
	border-bottom: 1px solid @pqsgreenshadow;
}

.login-container .block-flat .header h3{
	line-height:35px;
	margin:0;
	color:#FFF;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}

.login-container .header .logo-img{
  margin-right:3px;
}

.login-container .block-flat .content{
	padding:30px;
	padding-bottom:5px;

	&.slim-top-padding {
		padding-top: 8px;
	}
}

.login-container .block-flat .content .form-control{padding:10px 8px;height:auto;}
.login-container .block-flat .content .form-group {
	margin-bottom:10px;
}

.login-container .block-flat .content .title{margin-top:0;margin-bottom:20px;}

.login-container .block-flat .foot{
	text-align:right;
	padding:0 30px 15px;
}

.login-container .block-flat .foot .btn{min-width:70px;}

.login-container  .out-links{
	padding-right: 3px;
}

.out-links a{  color: #C9D4F6; text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);}

.ssip-footer {
  max-height: 80px;

  margin: 6px 20px 0 0;

}

.login-logo {
  max-height: 90px;
}

.login-col-margin {
    //margin-bottom: 110px;
}

.new-login-container {
    position: relative;
    height: 100%;
}