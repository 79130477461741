.multiselect__tag {
  background: #7ebd1d;
}

.multiselect__option--highlight {
  background: #7ebd1d;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #7ebd1d;
}

.multiselect, .multiselect__input, .multiselect__single {

  font-size: 13px;
}
