
/*Profile menu*/
.profile_menu{
	margin-left:10px;
	cursor:pointer;
}

.not-nav{
	margin:0 !important;
}

.navbar-collapse.in .not-nav > li{
  float:none !important;
  display:inline-block;
  padding: 10px 5px;
}

.user-nav{
	margin:0;
	margin-right: -15px;
}

.profile_menu .dropdown-toggle{
	padding: 10px 10px;
	vertical-align: middle;
	height: 54px;
    line-height: 30px;
}

.profile_menu .dropdown-toggle span{
  display:inline-block;
  margin-top: 4px;
}

div.avatar-img {
	position: relative;
	display: block;
	margin-right: 8px;
	width: 32px;
	height: 32px;
	float: left;

	&:after {
		content: '';
		position: absolute;
		display: block;
		height: 100%;
		width: 100%;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .4);
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, .3);
	}
}

.profile_menu img { 
	display: block;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	float: left;
	background-color: white;
}

/*END - Profile menu*/