/*Validation*/
.parsley-errors-list{
	margin-top:4px;
	padding:0;
}

.parsley-errors-list li{
	padding:0;
	list-style:none;
	color:#cc0000;
}

.parsley-error{
	border-color: #c00 !important;
}
