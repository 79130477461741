/*Tickets Widget*/

.list-group.tickets .list-group-item{
  overflow:hidden;
  margin-bottom:10px;
  background:#F2F2F2;
  border-radius:4px;
  border:0;  
} 

.list-group.tickets .list-group-item{
  padding: 13px 15px;
}

.list-group.tickets .list-group-item .avatar{
  float:left; 
  margin-right:10px;
  border-radius:50%;
}

.list-group.tickets .list-group-item .name{
  margin:0 0 5px 0;
  font-size:13px;
  font-weight:600;
}

.list-group.tickets .list-group-item p{
  margin:0;
  line-height: 12px;
}


.list-group.tickets .list-group-item .date{
  font-size:11px;
  color:#999;
}

.list-group.tickets .list-group-item .label{
  line-height:16px;
}

