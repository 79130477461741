/*CONFIG AND GENERAL STYLE*/
/*General colors palette*/
/*General elements*/
/*colors palette*/
/*social colors*/
/*Functions*/
html {
  height: 100%;
}
body {
  position: relative;
  padding: 0;
  background-color: #F6F6F6;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #111;
  opacity: 0;
  margin-left: -20px;
  min-height: 100%;
}
h1,
.h1 {
  font-weight: 300;
}
h2,
h3,
.h2,
.h3 {
  font-weight: 300;
}
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 27px;
}
a {
  color: #4c8b00;
  text-decoration: none;
  outline: 0 none;
}
a:focus,
a:hover,
a:active {
  outline: 0 none;
  text-decoration: none;
  color: #428100;
}
p {
  line-height: 22px;
}
b {
  font-weight: 400;
}
blockquote p {
  font-size: 15px;
}
ul {
  padding-left: 30px;
}
.overflow-hidden {
  overflow: hidden;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-y {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-margin-x {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.big-text {
  font-size: 61px;
  line-height: 70px !important;
  text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
}
.jumbotron {
  margin-top: 20px;
}
#pcont {
  margin-left: 216px;
  position: relative;
  padding: 0;
  min-height: 100%;
  height: auto;
}
.back-to-top {
  position: fixed;
  bottom: 0;
  right: 0px;
  text-decoration: none;
  color: #FFF;
  background-color: rgba(39, 41, 48, 0.8);
  font-size: 12px;
  padding: 9px 12px;
  display: none;
}
.back-to-top i {
  font-size: 15px;
}
.back-to-top:hover {
  color: #FFF;
  background-color: #272930;
}
.back-to-top:focus {
  color: #FFF;
}
.break-all {
  word-break: break-all;
}
.spacer {
  margin-top: 15px !important;
}
.spacer2 {
  margin-top: 30px !important;
}
.spacer3 {
  margin-top: 50px !important;
}
.spacer-bottom-xs {
  margin-bottom: 10px !important;
}
.spacer-bottom {
  margin-bottom: 30px !important;
}
.spacer-bottom-sm {
  margin-bottom: 15px !important;
}
.spacer-bottom-lg {
  margin-bottom: 40px !important;
}
.rad-1 {
  border-radius: 1px;
  -webkit-border-radius: 1px;
  overflow: hidden;
}
.rad-2 {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  overflow: hidden;
}
.rad-3 {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}
.dash-cols .block .no-padding {
  padding: 0;
}
.no-padding {
  padding: 0 !important;
}
.clear {
  clear: both;
}
.hthin {
  font-family: 'Lato', sans-serif !important;
  font-weight: 100 !important;
}
/*Pre & Code*/
pre {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-top: 10px;
}
/*CONTENT*/
.cont h2 {
  font-family: 'Lato', sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin: 0 0 10px 0;
}
.cont h5 {
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
}
.cont p {
  color: #333;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
}
.cont b {
  font-weight: 500;
}
/*Well*/
.well p:last-child {
  margin: 0;
}
/*Text Colors*/
.color-primary {
  color: #3078EF;
}
.color-primary.fa {
  color: #4C94FF;
}
.color-success {
  color: #5FBF5F;
}
.color-warning {
  color: #FC9700;
}
.color-danger {
  color: #ee5037;
}
/*HEADER*/
/*navigation*/
.navbar {
  border: 0;
  background-color: #7ebd1d;
  padding-right: 25px;
}
.navbar-default .navbar-brand {
  position: relative;
  z-index: 9;
  padding-left: 12px;
  padding: 10px 0 10px 18px;
  text-align: left;
  width: 184px;
  color: #FFF;
  height: auto;
}
@media (min-width: 768px) {
  .navbar-default .navbar-brand,
  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    background-color: #F6F6F6;
  }
  .navbar-header:after {
    color: transparent;
    border-right: 33px solid;
    border-top: 28px solid #F6F6F6;
    border-bottom: 28px solid #F6F6F6;
    display: inline-block;
    content: '';
    position: absolute;
    top: -1px;
    overflow: hidden;
  }
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #FFF;
}
.navbar-default .navbar-brand:hover {
  color: #FFF;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-nav > .active > a:focus {
  color: #fff;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
  font-weight: 600;
}
.navbar-default .navbar-nav > li.button {
  padding: 10px 6px;
  float: left;
  height: 50px;
}
.navbar-default .navbar-nav > li.button > a {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  padding: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 30px;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
  text-align: center;
}
.navbar-default .navbar-nav > li.button > a:hover {
  background: rgba(255, 255, 255, 0.07);
}
.navbar-default .navbar-nav > li.button > a:active {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.07) inset;
}
.navbar-default .navbar-nav > li.button > a > i {
  font-size: 14px;
}
.navbar-default .navbar-nav > li.button .bubble {
  position: absolute;
  top: -6px;
  left: 20px;
  background-color: #e44c34;
  color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border-color: #E23923 #D83722 #C0311E;
  border-left: 1px solid #D83722;
  border-width: 1px;
  box-shadow: -1px 1px 0 rgba(0, 0, 0, 0.25);
  padding: 1px 3px 1px 2px;
  font: bold 9px Tahoma;
}
.navbar-default .navbar-nav > li.button.open:after {
  position: absolute;
  bottom: 0;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  border-left: 6px solid transparent;
  content: '';
  left: 50%;
  margin-left: -6px;
}
.navbar-default .navbar-nav > li > a:focus {
  color: #FFF;
}
.navbar-nav > li.button .dropdown-menu {
  padding: 0;
  min-width: 260px;
  max-width: 280px;
}
.navbar-nav > li.button .dropdown-menu .content > ul {
  padding: 0;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li ul > li > a {
  font-weight: 400;
  color: #444;
  padding: 11px 10px;
  padding-left: 33px;
  border-bottom: 1px solid #ececec;
  position: relative;
}
.navbar-default .navbar-nav > li.button .messages li ul > li > a {
  padding: 12px 10px;
  padding-left: 48px;
  white-space: normal;
  line-height: 16px;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a:hover {
  color: #444;
  background: #F8F8F8;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li:last-child > a {
  border-bottom: 0;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a > i {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  width: 20px;
  vertical-align: middle;
  position: absolute;
  left: 10px;
  top: 14px;
}
.navbar-default .navbar-nav > li.button .messages li > a > img {
  position: absolute;
  left: 10px;
  top: 16px;
  height: 30px;
  width: 30px;
}
.navbar-default .navbar-nav > li.button .messages li > a .name {
  display: block;
  color: #555555;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a > i.success {
  color: #60C060;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a > i.info {
  color: #4D90FD;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a > i.warning {
  color: #FC9700;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li > a > i.danger {
  color: #DA4932;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .nano {
  height: 200px;
}
.navbar-default .navbar-nav > li.button .dropdown-menu li a .date {
  display: block;
  font-size: 11px;
  color: #999999;
  font-style: italic;
  line-height: 18px;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .title {
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #DDDDDF;
  padding: 8px 17px;
  color: #444;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .foot {
  padding: 0;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .foot > li {
  list-style: none;
}
.navbar-default .navbar-nav > li.button .dropdown-menu .foot a {
  background: #F7F7F7;
  padding: 8px 17px;
  color: #444;
  text-align: center;
}
/*Dropdown Sub menu*/
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 0 3px 3px;
  -moz-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-menu li > a:hover {
  background-color: #7ebd1d;
  background-image: none;
  color: #FFF;
}
.navbar-default .navbar-nav > li > .dropdown-menu {
  border-top: 0;
}
.navbar-default .navbar-nav > .dropdown > a .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #fff;
  border-top-color: #FFF;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-nav > .open > a:focus {
  background-color: #4c8b00;
  color: #FFF;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #4c8b00;
  color: #FFF;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  color: #FFF;
}
.navbar-default .navbar-toggle {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
  padding: 6px 10px;
}
.navbar-default .navbar-toggle:hover {
  background: rgba(255, 255, 255, 0.07);
}
.navbar-default .navbar-toggle:active {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.07) inset;
}
.navbar-default .navbar-toggle:focus {
  background: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #FFFFFF;
}
.navbar-default .navbar-toggle .fa {
  color: #FFFFFF;
  font-size: 18px;
}
.navbar-collapse {
  max-height: none;
  position: relative;
}
body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 0;
}
/*Navbar Inverse*/
.navbar.navbar-inverse {
  background-color: #272930;
}
.navbar-inverse .navbar-brand {
  color: #FFF;
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #1A1C20;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #1A1C20;
}
.navbar-inverse .navbar-toggle {
  border-color: #32353E;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
  background-color: #2c2e36;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #292A30;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    height: 2px;
    background-color: #1A1C20;
    border-bottom: 1px solid #32353E;
  }
}
.dropdown-menu > li > a,
.dropdown-menu li > a {
  font-size: 12px;
  padding: 5px 20px;
  clear: both;
  color: #333333;
  display: block;
  font-weight: normal;
  line-height: 1.42857;
}
.dropdown-header {
  color: #7ebd1d;
  margin-top: 6px;
  padding-left: 11px;
  cursor: pointer;
}
.dropdown-header i {
  font-size: 14px;
  margin-right: 4px;
  vertical-align: middle;
}
.dropdown-menu {
  background-clip: none;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.18);
}
.col-menu-2 {
  min-width: 405px;
  padding-bottom: 10px;
  padding-top: 7px;
}
.col-menu-2 > li:first-child ul {
  border-right: 1px solid #E8E8E8;
}
.col-menu-2 > li > ul > li a {
  padding-left: 32px;
}
.col-menu-2 > li > ul {
  padding: 0;
}
.col-menu-2 > li > ul > li {
  list-style: none;
}
/*END - Dropdown menu*/
/*Profile menu*/
.profile_menu {
  margin-left: 10px;
  cursor: pointer;
}
.not-nav {
  margin: 0 !important;
}
.navbar-collapse.in .not-nav > li {
  float: none !important;
  display: inline-block;
  padding: 10px 5px;
}
.user-nav {
  margin: 0;
  margin-right: -15px;
}
.profile_menu .dropdown-toggle {
  padding: 10px 10px;
  vertical-align: middle;
  height: 54px;
  line-height: 30px;
}
.profile_menu .dropdown-toggle span {
  display: inline-block;
  margin-top: 4px;
}
div.avatar-img {
  position: relative;
  display: block;
  margin-right: 8px;
  width: 32px;
  height: 32px;
  float: left;
}
div.avatar-img:after {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.profile_menu img {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  float: left;
  background-color: white;
}
/*END - Profile menu*/
/*Page Title*/
.page-head {
  background: none repeat scroll 0 0 #FFFFFF;
  border-bottom: 1px solid #E9E9E9;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05);
  padding: 10px 30px;
  position: relative;
}
.page-head h2 {
  margin-bottom: 5px;
  margin-top: 6px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.page-head .breadcrumb {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  margin-bottom: 0;
  padding: 4px;
}
.custom-header {
  margin-bottom: 5px;
}
.custom-header h1 {
  margin-bottom: 0px;
}
.page-aside {
  background: none repeat scroll 0 0 #FFFFFF;
  width: 280px;
  height: 100%;
  position: relative;
  display: table-cell;
  border-right: 1px solid #E9E9E9;
}
.page-aside > div {
  width: 280px;
}
.page-aside div.fixed {
  position: fixed;
  height: 100%;
}
.page-aside div.content {
  padding: 20px 20px;
}
@media (max-width: 767px) {
  .page-aside {
    width: 100%;
    display: block;
    height: auto;
    position: relative;
  }
  .page-aside div.fixed,
  .page-aside > div {
    width: 100%;
    display: block;
    position: relative;
  }
}
/*BODY*/
html,
body {
  height: 100%;
}
.container-fluid,
.cl-mcont {
  min-height: 100%;
  height: auto !important;
  /* cross-browser */
  height: 100%;
  /* cross-browser */
}
.cl-mcont {
  max-width: 1800px;
  background-color: #F6F6F6;
  color: inherit;
  font-weight: 500;
  line-height: 21px;
  padding: 15px 20px 200px 20px;
  margin-top: 0;
}
.cl-mcont.dashboard {
  min-height: 100%;
  height: auto !important;
  /* cross-browser */
  height: 100%;
  /* cross-browser */
  margin-top: -17px;
  padding-top: 0;
  padding-right: 15px;
  padding-bottom: 110px;
}
.cl-mcont.white {
  background: #fff;
}
.cl-mcont h1 {
  line-height: 1.3em;
}
#cl-wrapper {
  padding-top: 52px;
}
/*Vertical Navigation*/
.cl-sidebar {
  background: #2B2D34;
  position: absolute;
  width: 216px;
  z-index: 2;
}
.cl-sidebar .cl-toggle {
  display: none;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}
.cl-sidebar .cl-toggle i {
  font-size: 24px;
  color: #C9D4F6;
}
.cl-vnavigation {
  margin: 0 0 0 0;
  padding: 0;
  border-top: 1px solid #1a1c20;
  border-bottom: 1px solid #2f323a;
}
.cl-vnavigation .parent a {
  background: url("../images/arrow.png") no-repeat scroll right 14px top 18px transparent;
}
.cl-vnavigation li a {
  border-top: 1px solid #32353e;
  border-bottom: 1px solid #1a1c20;
  display: block;
  padding: 14px 18px 13px 15px;
  color: #DEE0D8;
  font-size: 14px;
  font-weight: 400;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}
.cl-vnavigation li a:hover {
  background-color: #26272C;
}
/*Collapsed Sidebar*/
.sb-collapsed .cl-sidebar {
  width: 55px;
}
.sb-collapsed #pcont {
  margin-left: 55px;
}
.sb-collapsed .cl-vnavigation li {
  position: relative;
}
.sb-collapsed .cl-vnavigation .parent a {
  background-image: none;
}
.sb-collapsed .cl-vnavigation li a i {
  margin: 0;
  font-size: 17px;
}
.sb-collapsed .cl-vnavigation > li > a > span {
  opacity: 0;
  width: 0;
  height: 0;
}
#cl-wrapper.animate-collapse .cl-sidebar {
  -webkit-transition: width 300ms linear;
  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: width 300ms linear;
  /* Firefox 4-15 */
  -o-transition: width 300ms linear;
  /* Opera 10.50�12.00 */
  transition: width 500ms linear;
}
#cl-wrapper.animate-collapse #pcont {
  transition: margin 500ms ease;
}
#cl-wrapper.animate-collapse .cl-vnavigation li a span {
  -webkit-transition: opacity 500ms linear;
  -moz-transition: opacity 500ms linear;
  -o-transition: opacity 500ms linear;
  transition: opacity 500ms linear;
}
#cl-wrapper.animate-collapse .cl-vnavigation li a i {
  -webkit-transition: margin 500ms linear, font-size 500ms linear;
  -moz-transition: margin 500ms linear, font-size 500ms linear;
  -o-transition: margin 500ms linear, font-size 500ms linear;
  transition: margin 500ms linear, font-size 500ms linear;
}
.cl-sidebar .collapse-button {
  background: #1F2126;
  position: fixed;
  width: 216px;
  bottom: 0;
  border-top: 1px solid #131519;
  box-shadow: 0 1px 0 #2A2C31 inset;
}
.cl-sidebar .collapse-button .search {
  width: 155px;
  display: inline-block;
  margin-right: 3px;
  border-radius: 2px;
  height: 33px;
  background: none repeat scroll 0 0 #13151D;
  font-size: 11px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.07), 0 1px 2px rgba(0, 0, 0, 0.15) inset;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.cl-sidebar .collapse-button button {
  margin: 0 !important;
  background: transparent;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1) inset;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}
.cl-sidebar .collapse-button button:hover {
  background: #23252A;
}
#cl-wrapper.fixed-menu .menu-space {
  position: fixed;
  width: 216px;
  background: #2B2D34;
}
#cl-wrapper.fixed-menu .menu-space .content {
  outline: none;
}
#cl-wrapper.sb-collapsed.fixed-menu .menu-space {
  width: 55px;
}
#cl-wrapper .cl-sidebar .side-user {
  padding: 18px 10px;
}
.sb-collapsed .cl-sidebar .side-user {
  display: none;
}
#cl-wrapper .cl-sidebar .side-user .avatar {
  display: inline-block;
  margin-right: 3px;
}
#cl-wrapper .cl-sidebar .side-user .avatar img {
  border-radius: 25px;
  margin-right: 5px;
}
#cl-wrapper .cl-sidebar .side-user .info {
  display: inline-block;
  vertical-align: middle;
}
#cl-wrapper .cl-sidebar .side-user .info a {
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
  display: block;
}
#cl-wrapper .cl-sidebar .side-user .info span {
  color: #DBDBDB;
  font-size: 11px;
}
#cl-wrapper.animate-collapse .cl-sidebar .collapse-button {
  -webkit-transition: width 300ms linear;
  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: width 300ms linear;
  /* Firefox 4-15 */
  -o-transition: width 300ms linear;
  /* Opera 10.50�12.00 */
  transition: width 500ms linear;
}
.sb-collapsed .cl-sidebar .collapse-button .search {
  display: none;
}
.sb-collapsed .cl-sidebar .collapse-button {
  width: 55px;
}
.sb-collapsed .cl-vnavigation li.open ul {
  display: none !important;
}
#sub-menu-nav ul {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.22);
  color: #333;
  background: #FFF;
  padding: 5px 0;
  min-width: 190px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 0;
  border-radius: 3px;
}
#sub-menu-nav ul li:first-child {
  padding-top: 0;
}
#sub-menu-nav ul li:last-child {
  padding-bottom: 0;
}
#sub-menu-nav ul li.dropdown-header i {
  text-align: center;
  width: 15px;
}
#sub-menu-nav ul li.dropdown-header {
  padding: 1px 11px;
  padding-bottom: 6px;
}
#sub-menu-nav ul {
  padding: 5px 0;
}
#sub-menu-nav ul li {
  list-style: none;
}
#sub-menu-nav ul li a {
  margin-right: -1px;
  display: block;
  font-size: 11px;
  border-bottom: 0;
  color: #333333;
  text-shadow: none;
  text-align: left;
  background: transparent;
  padding: 6px 20px 6px 31px;
}
#sub-menu-nav ul li a:hover {
  background-color: #7ebd1d;
  color: #FFF;
}
#sub-menu-nav ul:after {
  background: url(./images/menu-tail-left.png) no-repeat right 10px;
  top: 0px;
  content: "";
  display: inline-block;
  left: -9px;
  height: 43px;
  width: 10px;
  position: absolute;
}
/*.sb-collapsed .cl-vnavigation li:hover ul{display: block !important;}
	.sb-collapsed .cl-vnavigation li.ocult ul{display:none !important;}

*/
/*End Collapsed*/
.cl-vnavigation li a i {
  text-align: center;
  width: 20px;
  font-size: 14px;
  margin-right: 7px;
}
.cl-vnavigation li a span {
  display: inline-block;
}
.cl-vnavigation li ul {
  background-color: #1A1C20;
  display: none;
  margin: 0;
  padding: 0;
  padding-top: 4px;
}
.cl-vnavigation .parent ul li:last-child {
  padding-bottom: 13px;
}
.cl-vnavigation .parent ul li {
  background-color: #1a1c20;
  list-style: none;
}
.cl-vnavigation .parent ul li.dropdown-header {
  display: none;
}
.cl-vnavigation .parent ul li a {
  font-size: 11px;
  background-image: none;
  padding: 7px 18px 7px 42px;
  background-color: #1a1c20;
}
.cl-vnavigation .parent ul li.active a {
  background-color: #1e2025;
  border-left: 1px solid #2494F2;
}
.cl-vnavigation .parent ul li a:hover {
  background-color: #1e2025;
}
.cl-vnavigation .parent ul li a:first-child {
  border-top: 0;
}
.cl-vnavigation .active > a {
  background: #2A2C34;
  border-left: 1px solid #2494F2;
}
.cl-vnavigation .selected:hover a {
  background-color: #2494F2;
}
.cl-vnavigation .selected:hover ul a {
  background-color: #1A1C20;
}
body {
  height: 100%;
}
/*
.cl-sidebar{
  min-height:100%;
}*/
#cl-wrapper {
  display: table;
  width: 100%;
  position: absolute;
  height: 100%;
}
.cl-sidebar {
  display: table-cell;
  position: relative;
  vertical-align: top;
  padding-bottom: 49px;
}
#pcont {
  display: table-cell;
  vertical-align: top;
}
.cl-mcont .row {
  margin-top: 10px;
}
.cl-mcont .block {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
  border: 0;
  margin-bottom: 30px;
  background: #FFF;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.block-flat,
.block-wizard {
  margin-bottom: 40px;
  padding: 20px 20px;
  background: #FFF;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}
.block-transparent {
  margin-bottom: 40px;
}
.block-transparent .header {
  padding: 0 5px;
  border-bottom: 1px solid #E1E1E1;
  margin-bottom: 15px;
}
.block-transparent .header h4 {
  font-weight: 400;
}
.block-transparent .header h4 .fa {
  font-size: 14px;
}
/*Forms*/
label {
  font-weight: 600;
  margin-bottom: 7px;
}
.form-group {
  margin-top: 10px;
}
.form-horizontal.group-border .form-group {
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
}
.form-horizontal.group-border .form-group:last-child {
  border-bottom: 0;
}
.form-horizontal.group-border-dashed .form-group {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px dashed #efefef;
}
.form-horizontal.group-border-dashed .form-group:last-child {
  border-bottom: 0;
}
.form-control {
  padding: 6px 8px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
}
.input-group .btn {
  margin: 0 !important;
  margin-left: -1px !important;
  padding-bottom: 6px;
  padding-top: 6px;
  box-shadow: none;
  line-height: 20px;
}
.input-group-btn > .btn + .btn {
  margin-left: -5px !important;
}
.input-group-lg > .input-group-addon {
  line-height: 0.33;
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}
.input-group-addon {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  padding: 6px 11px;
  background-color: #7ebd1d;
  border-color: #7ebd1d;
  color: #fff;
}
.input-group-addon-fixed-width {
  min-width: 40px;
}
.form-control:focus {
  border-color: #7ebd1d;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
}
select.form-control,
textarea.form-control {
  padding: 6px 8px;
}
.input-group {
  margin-bottom: 15px;
}
/*Crhome Voice Recognition*/
.speechbutton {
  /*display:none;*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border: 0;
  background: transparent;
  opacity: 0;
  cursor: pointer;
}
.speech-button i:before {
  position: relative;
  z-index: 2;
}
.speech-button i:after {
  z-index: 1;
  font-family: 'FontAwesome';
  content: "?";
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 100%;
  opacity: 0;
}
@keyframes blur {
  from {
    font-size: 14px;
    opacity: 1;
    margin-top: 0;
  }
  to {
    font-size: 25px;
    opacity: 0;
    margin-top: -6px;
  }
}
@-webkit-keyframes blur {
  0% {
    font-size: 14px;
    opacity: 1;
    margin-top: 0;
  }
  90% {
    font-size: 25px;
    opacity: 0;
    margin-top: -6px;
  }
  100% {
    font-size: 14px;
  }
}
@-moz-keyframes blur {
  from {
    font-size: 14px;
    opacity: 1;
    margin-top: 0;
  }
  to {
    font-size: 25px;
    opacity: 0;
    margin-top: -6px;
  }
}
@-o-keyframes blur {
  from {
    font-size: 14px;
    opacity: 1;
    margin-top: 0;
  }
  to {
    font-size: 25px;
    opacity: 0;
    margin-top: -6px;
  }
}
.speech-button i.blur:after {
  animation: blur 900ms infinite;
  -webkit-animation: blur 900ms ease infinite;
  -moz-animation: blur 900ms infinite;
  -o-animation: blur 900ms infinite;
}
input::-webkit-input-speech-button {
  -webkit-transform: scale(3, 2.2) translate(-2px, -1px);
  background-color: #fff;
  display: block !important;
}
/*PAGES*/
/*Login*/
#cl-wrapper.login-container,
#cl-wrapper.error-container {
  margin: 0;
}
.login-container .middle-login {
  width: 430px;
  left: 50%;
  position: absolute;
  margin-left: -215px;
}
.login-container .block-flat {
  padding: 0;
  border: 0;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.login-container .block-flat .header {
  padding: 9px 20px;
  background: #7ebd1d;
  border-bottom: 1px solid #6aa909;
}
.login-container .block-flat .header h3 {
  line-height: 35px;
  margin: 0;
  color: #FFF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
}
.login-container .header .logo-img {
  margin-right: 3px;
}
.login-container .block-flat .content {
  padding: 30px;
  padding-bottom: 5px;
}
.login-container .block-flat .content.slim-top-padding {
  padding-top: 8px;
}
.login-container .block-flat .content .form-control {
  padding: 10px 8px;
  height: auto;
}
.login-container .block-flat .content .form-group {
  margin-bottom: 10px;
}
.login-container .block-flat .content .title {
  margin-top: 0;
  margin-bottom: 20px;
}
.login-container .block-flat .foot {
  text-align: right;
  padding: 0 30px 15px;
}
.login-container .block-flat .foot .btn {
  min-width: 70px;
}
.login-container .out-links {
  padding-right: 3px;
}
.out-links a {
  color: #C9D4F6;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.ssip-footer {
  max-height: 80px;
  margin: 6px 20px 0 0;
}
.login-logo {
  max-height: 90px;
}
.new-login-container {
  position: relative;
  height: 100%;
}
/*Login*/
#cl-wrapper.sign-up-container,
#cl-wrapper.error-container {
  margin: 0;
}
.sign-up-container .middle-sign-up {
  max-width: 100%;
  width: 470px;
  left: 50%;
  position: absolute;
  margin-left: -235px;
}
.sign-up-container .middle-sign-up.large {
  width: 550px;
  margin-left: -250px;
}
.forgotpassword-container .middle {
  width: 470px;
  margin: 20px auto 0 auto;
}
.sign-up-container .block-flat,
.forgotpassword-container .block-flat {
  padding: 0;
  border: 0;
  overflow: hidden;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}
.sign-up-container .block-flat .header,
.forgotpassword-container .block-flat .header {
  padding: 9px 20px;
  background: #7ebd1d;
  border-bottom: 1px solid #7ebd1d;
}
.sign-up-container .block-flat .header h3,
.forgotpassword-container .block-flat .header h3 {
  line-height: 35px;
  margin: 0;
  color: #FFF;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  min-height: 40px;
}
.sign-up-container .block-flat .content,
.forgotpassword-container .block-flat .content {
  padding: 30px;
  padding-bottom: 20px;
}
.sign-up-container .block-flat .content.slim-top-padding,
.forgotpassword-container .block-flat .content.slim-top-padding {
  padding-top: 8px;
}
.sign-up-container .block-flat .content .form-control,
.forgotpassword-container .block-flat .content .form-control {
  padding: 10px 8px;
  height: auto;
}
.sign-up-container .block-flat .content .form-group {
  margin-bottom: 10px;
}
.sign-up-container .block-flat .content .title {
  margin-top: 0;
  margin-bottom: 20px;
}
.sign-up-container .block-flat .foot,
.forgotpassword-container .block-flat .foot {
  text-align: right;
  padding: 0 30px 15px;
}
.sign-up-container .block-flat .foot .btn,
.forgotpassword-container .block-flat .foot .btn {
  min-width: 70px;
}
.sign-up-container .out-links,
.forgotpassword-container .out-links {
  padding-right: 3px;
}
.sign-up-container .out-links a,
.forgotpassword-container .out-links a {
  color: #C9D4F6;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 767px) {
  .sign-up-container .middle-sign-up {
    width: 100%;
    position: static;
    margin: 0;
  }
  .forgotpassword-container .middle {
    width: 100%;
  }
  .forgotpassword-container {
    padding: 20px;
  }
  .sign-up-container .middle-sign-up .block-flat {
    border-radius: 0;
  }
}
/*404 Page*/
.page-error {
  margin-top: 80px;
  margin-bottom: 40px;
}
.page-error .number {
  color: #FFF;
  font-size: 150px;
  font-family: Arial;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}
.page-error .description {
  color: #FFF;
  font-size: 40px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}
.page-error h3 {
  color: #FFF;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
}
.error-container .copy,
.error-container .copy a {
  color: #C9D4F6;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
body.texture {
  background-color: #7ebd1d;
}
.gallery-cont .item {
  width: 25%;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
.gallery-cont .item.w2 {
  width: 50%;
}
.gallery-cont .photo {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
}
.gallery-cont .img {
  position: relative;
}
.gallery-cont .img .over .func {
  margin-top: -80px;
  position: relative;
  top: 50%;
  text-align: center;
  transition: margin-top 200ms ease-in-out;
}
.gallery-cont .img .over .func a {
  display: inline-block;
  height: 50px;
  margin-right: 2px;
  width: 50px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
.gallery-cont .img .over .func a:hover {
  background: rgba(255, 255, 255, 0.3);
}
.gallery-cont .img .over .func i {
  font-size: 20px;
  color: #FFF;
  line-height: 2.4;
}
.gallery-cont .item .img:hover .over {
  opacity: 1;
}
.gallery-cont .item .img:hover .over .func {
  margin-top: -25px;
}
.gallery-cont .item .head {
  padding: 10px 10px;
  background: #FFF;
}
.gallery-cont .item .head h4 {
  margin: 0;
  font-size: 17px;
}
.gallery-cont .item .head span {
  color: #BDBDBD;
  font-size: 14px;
}
.gallery-cont .item .head span.active {
  color: #92d131;
}
.gallery-cont .item .head .desc {
  color: #999;
  font-size: 12px;
}
.gallery-cont .item img {
  width: 100%;
}
/*Photo Hover*/
.gallery-cont .item .over {
  top: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(126, 189, 29, 0.8);
  transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
}
@media (max-width: 767px) {
  .gallery-cont .item {
    width: 50%;
  }
  .gallery-cont .item.w2 {
    width: 100%;
  }
}
.email .page-title,
.app .page-title {
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 10px;
}
.email .description,
.app .description {
  color: #888;
}
.page-aside.email .header,
.page-aside.app .header {
  padding: 20px;
}
.page-aside.email .content {
  padding: 0;
  padding-bottom: 50px;
}
.page-aside.email .compose {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 25px;
}
.page-aside.email .compose .btn {
  background: #58BE89;
  width: 100%;
  border: 0;
  border-radius: 3px !important;
  max-width: 180px;
}
.page-aside.email .mail-nav,
.page-aside.app .app-nav {
  display: block;
  margin-top: 13px;
  padding: 0;
}
.page-aside.email .navbar-toggle,
.page-aside.app .navbar-toggle {
  background: rgba(0, 0, 0, 0.015);
}
.page-aside.email .navbar-toggle span,
.page-aside.app .navbar-toggle span {
  font-size: 14px;
}
.page-aside.email .mail-nav ul li,
.page-aside.app .app-nav ul.nav li {
  margin: 0;
}
.page-aside.email .mail-nav ul li a,
.page-aside.app .app-nav ul.nav li a {
  border-radius: 0;
  color: #555;
  padding: 12px 15px;
}
.page-aside.email .mail-nav ul li a:hover,
.page-aside.app .app-nav ul.nav li a:hover {
  background: rgba(0, 0, 0, 0.01);
}
.page-aside.email .mail-nav ul li.active a,
.page-aside.app .app-nav ul.nav li.active a {
  color: #555;
  background: rgba(0, 0, 0, 0.025);
}
.page-aside.email .mail-nav ul li a .label,
.page-aside.app .app-nav ul.nav li a .label {
  border-radius: 2px;
}
.page-aside.email .mail-nav ul li a .label.label-default,
.page-aside.app .app-nav ul.nav li a .label.label-default {
  background: #b5b5b5;
}
.page-aside.email .mail-nav ul li a .label.label1,
.page-aside.app .app-nav ul li a .label.label1 {
  background: #19B698;
}
.page-aside.email .mail-nav ul li a .label.label2,
.page-aside.app .app-nav ul li a .label.label2 {
  background: #E85647;
}
.page-aside.email .mail-nav ul li a .label.label3,
.page-aside.app .app-nav ul li a .label.label3 {
  background: #F4A425;
}
.page-aside.email .mail-nav ul li a i,
.page-aside.app .app-nav ul.nav li a i {
  font-size: 14px;
  width: 24px;
  text-align: center;
}
.page-aside.email .mail-nav .title,
.page-aside.app .app-nav .title {
  color: #888;
  margin: 5px 0 0 0;
  padding: 8px 13px 4px;
  border-bottom: 1px solid #E5E5E5;
}
/*inbox*/
.mail-inbox .head,
.main-app .head {
  background: #FFF;
  padding: 20px 25px;
}
.mail-inbox .head h3,
.main-app .head h3 {
  margin: 9px 0 15px 0;
}
.mail-inbox .head h3 span,
.main-app .head h3 span {
  color: #888;
  font-weight: 300;
  font-size: 16px;
}
.mail-inbox .head input[type="text"],
.main-app .head input[type="text"] {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) inset;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
}
.mail-inbox .head input[type="text"]:focus,
.main-app .head input[type="text"]:focus {
  border-color: #43b6ff;
}
.mail-inbox .filters,
.main-app .filters {
  padding: 9px 20px 10px 20px;
  border-bottom: 1px solid #E5E5E5;
}
.mail-inbox .filters .checkbox,
.main-app .filters .checkbox {
  margin-right: 8px;
}
.mail-inbox .mails,
.main-app .items {
  background: #FFF;
}
.mail-inbox .mails .item,
.main-app .items .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
}
.mail-inbox .mails .item:nth-child(even),
.main-app .items .item:nth-child(even) {
  background: rgba(0, 0, 0, 0.015);
}
.mail-inbox .mails .item:hover,
.main-app .items .item:hover {
  background: rgba(0, 0, 0, 0.035);
}
.mail-inbox .mails .item > div,
.main-app .items .item > div {
  vertical-align: top;
  display: table-cell;
}
.mail-inbox .mails .item > div:first-child,
.main-app .items .item > div:first-child {
  width: 35px;
}
.mail-inbox .mails .item > div:first-child > div,
.main-app .items .item > div:first-child > div {
  margin-top: 2px;
}
.mail-inbox .checkbox,
.main-app .checkbox {
  background-color: #FFF;
}
.mail-inbox .mails .item .from,
.main-app .items .item .from {
  font-weight: 600;
  font-size: 13px;
  margin: 0 0 3px 0;
}
.mail-inbox .mails .item .date i,
.main-app .items .item .date i {
  margin-right: 7px;
  font-size: 14px;
}
.mail-inbox .mails .item .msg,
.main-app .items .item .msg {
  font-size: 12px;
  margin: 0;
}
/*Message*/
.message .head {
  background: #FFF;
  padding: 20px 25px 24px;
}
.message .head h3 {
  margin: 9px 0 15px 0;
}
.message .head h3 span {
  font-weight: 300;
  font-size: 20px;
  float: right;
}
.message .head h3 span a {
  color: #888;
  margin-right: 15px;
}
.message .head h4 span {
  font-weight: 100;
  font-size: 15px;
  float: right;
}
.message .head h4 span a {
  color: #FEA200;
  margin-right: 10px;
}
.message .mail {
  background: #FFF;
  border-top: 1px solid #E5E5E5;
  padding: 30px;
  font-size: 26px;
}
.message .mail p {
  font-size: 15px;
  font-weight: 100;
}
.message .mails .item {
  border-bottom: 1px solid #E5E5E5;
  padding: 17px 20px;
  display: table;
  width: 100%;
  cursor: pointer;
}
.message .mails .item:nth-child(even) {
  background: rgba(0, 0, 0, 0.015);
}
.message .mails .item:hover {
  background: rgba(0, 0, 0, 0.035);
}
.message .mails .item > div {
  vertical-align: top;
  display: table-cell;
}
.message .mails .item > div:first-child {
  width: 35px;
}
.message .mails .item > div:first-child > div {
  margin-top: 2px;
}
.message .checkbox {
  background-color: #FFF;
}
.message .mails .item .from {
  font-weight: 600;
  font-size: 13px;
  margin: 0 0 3px 0;
}
.message .mails .item .date i {
  margin-right: 7px;
  font-size: 14px;
}
.message .mails .item .msg {
  font-size: 12px;
  margin: 0;
}
.message .head .subject input {
  font-weight: 300;
  font-size: 25px;
  height: auto;
  padding: 10px 0;
  border: 0;
  width: 100%;
}
.message .to,
.message .cc {
  overflow: hidden;
  border-top: 1px solid #E5E5E5;
  padding: 10px 25px 15px;
  background: #FFF;
}
.message .to {
  padding-bottom: 0;
}
.message .cc {
  padding-top: 2px;
  padding-bottom: 20px;
  border-top-width: 0;
}
.message .to .form-group > .control-label {
  width: 45px;
  padding-top: 10px;
  padding-left: 5px;
}
.message .to .select2-container-multi .select2-choices {
  border-color: #D8D8D8;
}
.message .to .select2-container-multi.select2-container-active .select2-choices {
  border-color: #5897FB;
}
@media (max-width: 767px) {
  .page-aside.email .mail-nav,
  .page-aside.app .app-nav {
    margin-top: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
  }
  .page-aside.email .mail-nav .compose,
  .page-aside.app .app-nav .compose {
    border-top: 1px solid #E5E5E5;
    background: #FCFCFC;
  }
  .page-aside.email div.content,
  .page-aside.app div.content {
    border-bottom: 1px solid #E5E5E5;
  }
  .page-aside.email .mail-nav.collapse,
  .page-aside.app .app-nav.collapse {
    display: none;
  }
  .page-aside.email .mail-nav.collapse.in,
  .page-aside.app .app-nav.collapse.in {
    display: block;
  }
  .page-aside.email .nano .content {
    position: static;
    padding: 0;
    overflow: visible;
  }
  .message .mail {
    border-top: 0;
  }
}
.profile-info > .row {
  margin-top: 0;
}
.profile-info .avatar {
  padding-top: 11px;
}
.profile-info .avatar img {
  max-width: 130px;
  max-height: 130px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.profile-info .personal .btn {
  padding-right: 12px;
  border: 0;
  margin: 0;
}
.profile-info .personal .btn .fa {
  margin-right: 5px;
}
.profile-info .skills td {
  font-size: 13px;
}
.profile-info .skills .progress {
  border-radius: 2px;
  height: 11px;
}
.spk-widget {
  margin-top: 15px;
}
.side-right {
  margin-top: 39px;
}
.bars-widget h4 {
  margin-bottom: 0;
}
.bars-widget h3 {
  margin: 0;
}
table.information td {
  font-size: 13px;
  vertical-align: top;
  padding: 6px;
}
table.information td i {
  width: 15px;
  font-size: 14px;
}
.fa-mobile-phone {
  font-size: 18px !important;
}
table.information td.category {
  padding-top: 8px;
}
table.information > tbody > tr > td {
  padding-bottom: 30px;
}
.friend-widget {
  cursor: default;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 300ms ease-in-out;
  -webkit-transition: background-color 300ms ease-in-out;
}
.friend-widget img {
  float: left;
  margin-right: 10px;
  border: 3px solid #F2F2F2;
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  border-radius: 50%;
  transition: border-color 300ms ease-in-out;
  -webkit-transition: border-color 300ms ease-in-out;
}
.friend-widget:hover {
  background: #f2f2f2;
}
.friend-widget:hover img {
  border-color: #4D90FD;
}
.friend-widget h4 {
  font-size: 15px;
  margin: 3px 0 0 0;
}
.friend-widget p {
  color: #999;
  margin: 0;
}
.friends-list > div {
  padding-left: 5px;
  padding-right: 5px;
}
.widget-title {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 15px;
}
.avatar-upload {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.avatar-upload img {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}
.avatar-upload input {
  cursor: pointer;
  direction: ltr;
  font-size: 200px;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  outline: 0;
}
.avatar-upload .overlay {
  background: #4D90FD;
  height: 3px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 300ms ease 0s;
  width: 0%;
  vertical-align: middle;
}
.crop-image img {
  max-width: 480px;
  max-height: 380px;
  box-shadow: 0 0 0 4px #e9e9e9;
}
.jcrop-holder {
  display: inline-block;
}
@media (max-width: 767px) {
  table.information .btn span {
    display: none;
  }
  .profile-info .personal,
  .profile-info .avatar {
    text-align: center;
  }
  .profile-info .avatar {
    margin-bottom: 10px;
  }
}
.main-app .options {
  padding: 20px 0 5px 0;
}
.main-app .options .radio-inline:first-child {
  padding-left: 0;
}
.main-app .options .radio-inline {
  margin: 0;
}
.main-app .options .form-group {
  margin: 0;
}
.main-app .items.products .item .price {
  font-size: 22px;
  font-weight: 300;
}
.main-app .items.products .item .product-image {
  float: left;
  margin-right: 10px;
}
.app.filters .app-nav .form-group {
  margin-bottom: 35px;
}
.app.filters .app-nav .content {
  padding-top: 0;
}
.code-editor {
  height: 100%;
}
.code-editor #console {
  height: 100%;
}
.code-editor #console .CodeMirror {
  height: 100%;
}
.codeditor .page-title {
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 10px;
}
.codeditor .description {
  color: #888;
}
.page-aside.codeditor .compose {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 25px;
}
.page-aside.codeditor .compose .btn {
  width: 100%;
  border: 0;
  border-radius: 3px !important;
  max-width: 180px;
}
.code-cont .main-app {
  height: 100%;
}
@media (max-width: 767px) {
  .cl-mcont.aside {
    display: block;
  }
  .page-aside.codeditor {
    display: block;
  }
  .aside .content {
    display: block;
  }
  .page-aside > div {
    width: 100%;
  }
  .cl-mcont.aside > .content {
    display: block;
  }
  .page-aside.codeditor .mail-nav ul li {
    display: block;
  }
  .page-aside.codeditor .mail-nav {
    margin-top: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
  }
  .page-aside.codeditor .mail-nav .compose {
    border-top: 1px solid #E5E5E5;
    background: #FCFCFC;
  }
  .page-aside.codeditor div.content {
    border-bottom: 1px solid #E5E5E5;
  }
  .page-aside.codeditor .mail-nav.collapse {
    display: none;
  }
  .message .mail {
    border-top: 0;
  }
}
.CodeMirror {
  height: auto;
}
.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
/*ELEMENTS*/
table thead th {
  padding: 5px;
  color: #3c3c3b;
  font-weight: 500;
}
table tbody td {
  padding: 7px 8px;
}
table .primary-emphasis,
table .primary-emphasis-dark {
  background: #4D90FD;
  color: #FFF;
  border-color: #4D90FD;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .primary-emphasis-dark {
  background-color: #437edd;
}
table .success-emphasis,
table .success-emphasis-dark {
  background: #60C060;
  color: #FFF;
  border-color: #60C060;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .success-emphasis-dark {
  background-color: #58b058;
  border-color: #58b058;
}
table .warning-emphasis,
table .warning-emphasis-dark {
  background: #FC9700;
  color: #FFF;
  border-color: #FC9700;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .warning-emphasis-dark {
  background-color: #fc8800;
  border-color: #fc8800;
}
table .danger-emphasis,
table .danger-emphasis-dark {
  background: #DA4932;
  color: #FFF;
  border-color: #DA4932;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
}
table .danger-emphasis-dark {
  background-color: #c8432e;
  border-color: #c8432e;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table.no-border {
  border: 0;
}
table .right {
  text-align: right;
}
table .left {
  text-align: left;
}
.red thead th {
  color: #d36442;
}
.blue thead th {
  color: #3078EF;
}
.violet thead th {
  color: #8b12ae;
}
.green thead th {
  color: #4da60c;
}
table thead th span {
  color: #333;
}
table thead th {
  vertical-align: middle;
  border-bottom: 1px solid #DADADA;
  border-left: 1px solid #DADADA;
  border-top: 1px solid #DADADA;
  padding: 10px 8px 5px 8px;
}
table thead th:last-child {
  border-right: 1px solid #DADADA;
}
table thead span {
  font-size: 15px;
  display: block;
}
table td {
  border-left: 1px solid #DADADA;
  border-bottom: 1px solid #dadada;
  padding: 7px 8px;
}
table.padding-sm td {
  padding: 4px 6px;
}
table td .progress {
  margin: 0;
}
table.hover tbody tr:hover {
  background: #f3f3f3;
}
table td:last-child {
  border-right: 1px solid #dadada;
}
table tr:nth-child(2n) {
  background: #f8f8f8;
}
table.no-strip tr:nth-child(2n) {
  background: transparent;
}
/*No-Internal borders in thead*/
table .no-border th {
  border-left: 0;
}
table .no-border tr th:first-child {
  border-left: 1px solid #dadada;
}
/*No-Internal borders in tbody x and y*/
table .no-border-x td {
  border-bottom: 0;
}
table .no-border-x tr:last-child td {
  border-bottom: 1px solid #dadada;
}
table .no-border-y td {
  border-left: 0;
}
table .no-border-y tr td:first-child {
  border-left: 1px solid #dadada;
}
/*No-External borders general table*/
table.no-border tr th {
  border-top: 0;
}
table.no-border tr th:first-child {
  border-left: 0;
}
table.no-border tr th:last-child {
  border-right: 0;
}
table.no-border tr td:first-child {
  border-left: 0;
}
table.no-border tr td:last-child {
  border-right: 0;
}
table.no-border tr:last-child td {
  border-bottom: 0;
}
/*No-External borders when .no-padding in block*/
.no-padding table th:first-child {
  border-left: 0;
}
.no-padding table th:last-child {
  border-right: 0;
}
.no-padding table tr td:first-child {
  border-left: 0;
}
.no-padding table tr td:last-child {
  border-right: 0;
}
.no-padding table tr:last-child td {
  border-bottom: 0;
}
table tbody .toggle-details {
  cursor: pointer;
}
table tbody .details {
  background: #FFF;
}
table tbody td .btn {
  margin-bottom: 0 !important;
}
table tbody td .btn-group .dropdown-menu {
  margin-top: -1px;
  min-width: 130px;
}
table tbody td .btn-group .dropdown-menu li > a {
  padding: 5px 12px;
  text-align: left;
}
table td .flag {
  text-align: center;
  padding: 0 4px;
}
table td .legend {
  width: 10px;
  height: 10px;
  background: #efefef;
}
div.dataTables_length label select {
  display: inline !important;
}
.table td .btn i {
  width: inherit;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 2px solid #ddd;
}
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}
/*Stats*/
.stats_bar {
  text-align: center;
  margin-top: 15px;
}
.butpro {
  vertical-align: top;
}
.butstyle {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #f8f8f8;
  background-image: -moz-linear-gradient(center top, #FFFFFF 50%, #f8f8f8 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#f8f8f8));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #FFFFFF, #f8f8f8);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #FFFFFF, #f8f8f8);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #FFFFFF 50%, #f8f8f8 100%);
  border-color: #DEDFE0 #C8C8C8 #C8C8C8;
  border-image: none;
  border-right: 1px solid #C8C8C8;
  border-style: solid;
  border-width: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: 'Open Sans', Verdana, sans-serif;
  font-size: 11px;
  margin-right: 1.8%;
  margin-bottom: 15px;
  width: 14.2%;
  padding: 6px 9px 4px;
  transition: border-color 0.21s ease-out 0s;
}
.butstyle.flat {
  /* Chrome, Safari 4+ */
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  /* Opera 11.10-12.00 */
  background-image: none;
  background: #FFF;
  border: 0;
  color: #777;
}
.butstyle:first-child {
  margin-left: 0;
}
.butstyle:hover {
  border-color: #4D90FE;
}
.butpro .sub h2 {
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
}
.butpro .sub span {
  color: #434D55;
  display: block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
}
.butpro .stat {
  padding: 4px 0;
  text-align: center;
}
.butpro .stat .equal {
  background: url("./less/images/stat-same.png") no-repeat scroll left center transparent;
  font-size: 12px;
  padding: 0 0 0 19px;
}
.butpro .stat .up {
  background: url("./less/images/stat-up.png") no-repeat scroll left center transparent;
  font-size: 12px;
  padding: 0 0 0 19px;
}
.butpro .stat .down {
  background: url("./less/images/stat-down.png") no-repeat scroll left center transparent;
  font-size: 12px;
  padding: 0 0 0 19px;
}
.butpro .sub {
  padding-top: 4px;
}
/*Flot Charts*/
.blue-chart .flot-text,
.red-chart .flot-text {
  color: #FFF !important;
}
.flot-text {
  font-size: 10px !important;
}
.legend table td {
  font-size: 11px !important;
}
.blue-chart {
  border-left: 1px solid #258fec;
  border-right: 1px solid #258fec;
  border-bottom: 1px solid #1e73bd;
  border-top: 1px solid #499de5;
  background-image: -moz-linear-gradient(center top, #52AEFF 45%, #2180D3 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#52AEFF), to(#2180D3));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #52AEFF, #2180D3);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #52AEFF, #2180D3);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #52AEFF, #2180D3);
  background-color: #3290E2;
}
.block-flat .blue-chart {
  padding: 20px !important;
}
.blue-chart h2 {
  text-shadow: 0 -1px 0 #357FC0;
}
.easyPieChart {
  display: inline-block;
}
.red-chart {
  border-left: 1px solid #F27B56;
  border-right: 1px solid #F27B56;
  border-bottom: 1px solid #d66543;
  border-top: 1px solid #ee815e;
  background-image: -moz-linear-gradient(center top, #ff8a65 45%, #e26a45 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#e26a45));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #ff8a65, #e26a45);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #ff8a65, #e26a45);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #ff8a65 45%, #e26a45 102%);
  background-color: #3290E2;
}
.red-box {
  background-image: -moz-linear-gradient(center top, #ff8a65 45%, #F07853 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#F07853));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #ff8a65, #F07853);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #ff8a65, #F07853);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #ff8a65 45%, #F07853 102%);
  background-color: #ff8a65;
  color: #FFFFFF;
}
.red2-box {
  background-image: -moz-linear-gradient(center top, #FF7877 45%, #ED5554 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FF7877), to(#ED5554));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #FF7877, #ED5554);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #FF7877, #ED5554);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #FF7877 45%, #ED5554 102%);
  background-color: #FF7877;
  color: #FFFFFF;
}
.blue-box {
  background-image: -moz-linear-gradient(center top, #52AEFF 45%, #3D9CEF 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#52AEFF), to(#3D9CEF));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #52AEFF, #3D9CEF);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #52AEFF, #3D9CEF);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #52AEFF 45%, #3D9CEF 102%);
  background-color: #52AEFF;
  color: #FFFFFF;
}
.blue-box.borders {
  border-color: #499DE5 #258FEC #1E73BD;
  border-left: 1px solid #258FEC;
  border-right: 1px solid #258FEC;
  border-style: solid;
  border-width: 1px;
}
.green-box {
  background-image: -moz-linear-gradient(center top, #82c261 45%, #70BE46 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#82c261), to(#70BE46));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #82c261, #70BE46);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #82c261, #70BE46);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #82c261 45%, #70BE46 102%);
  background-color: #82c261;
  color: #FFFFFF;
}
.violet-box {
  background-image: -moz-linear-gradient(center top, #b450b2 45%, #853685 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b450b2), to(#853685));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #b450b2, #853685);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #b450b2, #853685);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #b450b2 45%, #853685 102%);
  background-color: #82c261;
  color: #FFFFFF;
}
.yellow-box {
  background-image: -moz-linear-gradient(center top, #FFC91C 45%, #FAB219 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFC91C), to(#FAB219));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #FFC91C, #FAB219);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #FFC91C, #FAB219);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #FFC91C 45%, #FAB219 102%);
  background-color: #82c261;
  color: #FFFFFF;
}
.white-box {
  background: #FFF;
}
.widget-block.calendar-box {
  overflow: hidden;
}
.widget-block.calendar-box h2 {
  font-size: 28px;
}
.widget-block.calendar-box .day {
  text-align: center;
  font-size: 102px;
}
.widget-block.weather-box {
  overflow: hidden;
  color: #FFF;
}
.widget-block.weather-box .photo {
  background: url("./less/images/sunset2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 260px;
}
.widget-block.weather-box h2 {
  margin-top: 60px;
  font-size: 28px;
  font-weight: 400;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}
.widget-block.weather-box .day {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 70px;
  margin-top: 3px;
}
.fb-box {
  margin-top: 0 !important;
  background-image: -moz-linear-gradient(center top, #3E80BF 50%, #325fa0 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3E80BF), to(#325fa0));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #3E80BF, #325fa0);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #3E80BF, #325fa0);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #3E80BF 50%, #325fa0 102%);
  background-color: #366eab;
  color: #FFFFFF;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35) !important;
}
.fb-box h2 {
  font-size: 19px;
  font-weight: 400;
  margin: 0 0 5px 0;
  padding-top: 10px;
}
.fb-box h3 {
  font-size: 13px;
  font-weight: 200;
  margin: 0 0 20px 0;
}
.fb-box h4 {
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 5px 0;
}
.fb-box img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 3px solid #FFFFFF;
  margin-bottom: 15px;
  margin-top: 20px;
}
.fb-box .content {
  padding: 20px;
  text-align: center;
}
.fb-box .info {
  background: #264A7E;
  color: #FFF;
  border-top: 1px solid #426ca5;
  text-align: center;
}
.fb-box .info button {
  box-shadow: none;
  max-width: 195px;
  width: 80%;
  padding: 13px;
  background: #4884c0;
  border: 0;
  margin: 5px 0;
  text-transform: uppercase;
}
.fb-box .info button i {
  margin-right: 3px;
}
.fb-box .info div {
  padding: 15px 10px;
}
.fb-box .row {
  margin: 0;
}
.block .cl,
.widget-block .cl {
  margin-bottom: 20px;
}
.widget-block {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.21);
  border: 0;
  margin-bottom: 30px;
  margin-top: 0;
}
.widget-block .row {
  margin: 0;
}
.widget-block .padding {
  padding: 20px 20px;
}
.widget-block .vpadding {
  padding: 20px 0;
}
.widget-block .hpadding {
  padding: 0 20px;
}
.block .header,
.widget-block .header,
.block-flat .header {
  padding: 10px;
  background-color: #FEFEFE;
  border-bottom: 1px solid #dadada;
}
.block .header {
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  padding: 10px;
}
.block-flat .header {
  background: transparent;
  padding: 3px 0;
}
.block-flat .header.no-border {
  border: 0;
}
.block-flat .header h3 {
  margin-top: 3px;
  font-family: "Raleway", Helvetica, sans-serif;
}
.block-flat .content {
  padding: 15px 3px 0 3px;
}
.block-flat .content.full-width {
  padding: 0;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
.block-flat .content h4 {
  margin-top: 20px;
  font-size: 16px;
}
.block-flat .content .title {
  margin: 5px 0 15px 0;
}
.block-flat.no-padding .content {
  padding: 0 !important;
}
.legend-container table {
  width: auto;
  margin: 20px 0 0 20px;
}
.legend-container table tr {
  background: transparent;
}
.legend-container table td {
  border: 0;
  padding: 0 5px 3px 5px;
}
.legend-container .legendColorBox > div {
  border: 0 !important;
}
.legend-container .legendColorBox > div > div {
  border-width: 6px !important;
}
.block-flat.no-padding .content table td,
.block-flat.no-padding .content table th {
  padding: 11px 10px 7px 10px;
}
.block .no-border {
  border-bottom: 0;
}
.block .content h2,
.widget-block .content h2 {
  font-family: 'Open Sans', sans-serif;
}
.block .header h2,
.widget-block .header h2,
.block-flat .header h2 {
  margin: 0;
  font-size: 29px;
  line-height: 38px;
}
.block .header h3,
.widget-block .header h3 {
  margin: 0;
  font-size: 13px;
  font-weight: 200;
  line-height: 21px;
  color: #666;
}
.block .header .actions i,
.widget-block .header .actions i {
  font-size: 18px;
  color: #6F6F6F;
  display: inline-block;
  padding: 0;
  text-align: center;
  cursor: pointer;
  width: 20px;
  vertical-align: top;
}
.block .header .actions i:hover,
.widget-block .header .actions i:hover {
  color: #858585;
}
.widget-block .fb-head {
  background: #1784c7;
  border: 0;
  color: #FFF;
}
.widget-block .fb-head i {
  display: inline-block;
  text-align: center;
  width: 28px;
}
.bg-darkgray {
  background-color: #888e97;
  color: #fff;
}
.bg-blue {
  background-color: #43A0F2;
  color: #fff;
}
.bg-blue .caret,
.bg-darkgray .caret {
  border-color: #fff transparent;
  border-width: 5px 5px 0;
}
.bg-blue a,
.bg-darkgray a {
  color: #FFF;
}
.cl-mcont .block .content {
  padding: 20px;
}
.cl-mcont .block .stat-data,
.cl-mcont .block-flat .stat-data {
  padding: 10px;
  float: left;
  width: 50%;
}
.cl-mcont .block .stat-data h2,
.cl-mcont .block-flat .stat-data h2 {
  margin: 0 0 5px 0;
}
.cl-mcont .block .stat-data span,
.cl-mcont .block-flat .stat-data span {
  font-size: 18px;
}
.cl-mcont .block .stat-data .stat-blue,
.cl-mcont .block-flat .stat-data .stat-blue {
  background: #43A0F2;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  text-align: center;
  color: #FFF;
  padding: 20px 10px;
}
.cl-mcont .block .stat-data .stat-number,
.cl-mcont .block-flat .stat-data .stat-number {
  border-bottom: 1px solid #dadada;
}
.cl-mcont .block .stat-data .stat-number:last-child,
.cl-mcont .block-flat .stat-data .stat-number:last-child {
  border-bottom: 0;
}
.cl-mcont .block .stat-data .stat-number div,
.cl-mcont .block-flat .stat-data .stat-number div {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 5px;
  font-size: 16px;
  line-height: 20px;
}
.cl-mcont .block .stat-data .stat-number h2,
.cl-mcont .block-flat .stat-data .stat-number h2 {
  margin: 0;
  font-size: 35px;
  color: #444;
  font-weight: 300;
}
.cl-mcont .block .stat-data .stat-number span,
.cl-mcont .block-flat .stat-data .stat-number span {
  font-size: 12px;
}
.block .fact-data,
.widget-block .fact-data,
.block-flat .fact-data {
  margin: 25px 0;
  padding: 0 20px;
  display: inline-block;
  width: 49%;
  vertical-align: top;
}
.block .total-data,
.widget-block .total-data,
.block-flat .total-data {
  padding: 15px;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
}
.block .dropdown-menu,
.widget-block .dropdown-menu,
.block-flat .dropdown-menu {
  margin: -1px 0 0 0;
  border-radius: 1px;
  -webkit-border-radius: 1px;
}
.btn-group .dropdown-menu {
  margin-top: -6px;
}
.btn-group {
  margin-right: 5px;
  margin-left: 4px;
}
.static-mn,
.widget-block .static-mn {
  display: block;
  position: static;
}
.static-mn,
.widget-block .static-mn {
  display: block;
  position: static;
}
.static-lg-menu .static-mn,
.widget-block .static-lg-menu .static-mn {
  min-width: 90%;
}
.block .items,
.widget-block .items {
  margin: 0;
  padding: 0;
}
.block .items li,
.widget-block .items li {
  border-top: 1px solid #e8e8e8;
  list-style: none;
  font-size: 14px;
  line-height: 17px;
  padding: 13px;
  background-color: #FFF;
  transition: background 0.25s ease-in-out;
  -moz-transition: background 0.25s ease-in-out;
  -webkit-transition: background 0.25s ease-in-out;
}
.block .items li i,
.widget-block .items li i {
  color: #555555;
  display: block;
  float: left;
  font-size: 28px;
  height: 38px;
  margin-left: 4px;
  margin-right: 15px;
  margin-top: 2px;
}
.block .items li small,
.widget-block .items li small {
  display: block;
}
.block .items li:hover,
.widget-block .items li:hover {
  background-color: #F4F4F4;
}
.block .items li .value,
.widget-block .items li .value {
  font-size: 15px;
  margin-top: 7px;
}
.block .items li:first-child,
.widget-block .items li:first-child {
  border-top: 0;
}
.block .total-data h2,
.widget-block .total-data h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}
.block .total-data h3,
.widget-block .total-data h3 {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
.block .total-data h4,
.widget-block .total-data h4 {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
.widget-block .fact-data {
  margin: 27px 0;
}
.block .fact-data:first-child {
  border-right: 1px solid #eaeaea;
}
.block .fact-data h2,
.widget-block .fact-data h2 {
  margin: 0;
  font-size: 32px;
  font-weight: 300;
}
.block .fact-data h3,
.widget-block .fact-data h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 200;
  font-size: 13px;
}
.widget-block .fact-data h3 {
  margin-bottom: 13px;
  font-size: 17px;
}
.widget-block .fact-data h2 {
  margin-bottom: 4px;
}
.widget-block .fact-data p {
  font-size: 12px;
}
.widget-block .no-padding {
  padding: 0;
}
.widget-block h3 {
  font-size: 17px;
  margin: 5px 0 0 0;
  font-weight: 200;
}
.widget-block h2 {
  font-size: 32px;
  font-weight: 300;
}
.block h2 i {
  font-size: 24px;
  color: #555;
  margin-right: 6px;
}
/*Widget Notes*/
.cl-mcont .block.widget-notes {
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #EFEFEF;
  border-right: 1px solid #EFEFEF;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}
.cl-mcont .block.widget-notes .header {
  border-bottom: 0;
}
.cl-mcont .block.widget-notes .header h4 {
  margin: 0;
  font-size: 15px;
}
.cl-mcont .block.widget-notes .content {
  padding: 0;
}
.paper {
  line-height: 30px;
  font-size: 14px;
  padding: 30px 55px 27px;
  position: relative;
  outline: none;
  background: white;
  background: -webkit-linear-gradient(top, #DFE8EC 0%, white 8%) 0 0px;
  background: -moz-linear-gradient(top, #DFE8EC 0%, white 8%) 0 0px;
  background: linear-gradient(center top, #DFE8EC 0%, white 8%) 0 0px;
  -webkit-background-size: 100% 30px;
  -moz-background-size: 100% 30px;
  -ms-background-size: 100% 30px;
  background-size: 100% 30px;
}
.paper::after {
  content: '';
  position: absolute;
  width: 0px;
  top: 0;
  left: 39px;
  bottom: 0;
  border-left: 1px solid #F8D3D3;
}
/*Pie Widget*/
.pie-widget {
  margin-bottom: 20px;
}
.pie-widget .stats {
  padding-top: 20px;
  border-top: 1px solid #DADADA;
}
.pie-widget .stats p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.pie-widget .stats h5 {
  margin: 0;
}
@media (max-width: 767px) {
  .pie-widget .stats > div:first-child {
    margin-bottom: 20px;
  }
}
/*UI Datepicker*/
.ui-datepicker {
  position: relative;
}
.ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 5px;
  margin-bottom: 4px;
}
.ui-datepicker .ui-datepicker-title {
  cursor: default;
  line-height: 1.8em;
  margin: 0 2.3em;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  height: 1.8em;
  position: absolute;
  top: 2px;
  width: 1.8em;
  color: #FFF;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  overflow: hidden;
  display: block;
  text-indent: -99999px;
}
.ui-datepicker .ui-datepicker-prev span:after,
.ui-datepicker .ui-datepicker-next span:after {
  cursor: pointer;
  content: "";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  text-indent: 0;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.ui-datepicker .ui-datepicker-next span:after {
  content: "";
  right: 0;
}
.ui-datepicker table th {
  border: 0;
  padding: 3px;
}
.ui-datepicker table th span {
  font-size: 14px;
  color: #FFF;
}
.ui-datepicker table tr {
  background: transparent !important;
}
.ui-datepicker table td {
  border: 0;
  padding: 3px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
  color: #FFF;
}
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active.active:hover,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled.active:hover {
  background-color: #4E9DFF;
}
.datetimepicker table tr td.active,
.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover {
  background-image: none;
  background: #4E9DFF;
}
/*Daterange*/
/*Wizard*/
.wizard-row {
  position: relative ;
  width: 100%;
  margin: 0;
}
.wizard-row .fuelux {
  padding: 0;
  position: absolute;
  width: 100%;
}
.block-wizard {
  padding: 0;
}
.block-wizard .step-content {
  padding: 20px 20px;
}
.fuelux .wizard {
  box-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  border: 0;
  border-bottom: 1px solid #E2E2E2;
  background: #FFF;
}
.fuelux .wizard ul li.active {
  background: #FFF;
  color: #3078EF;
}
.fuelux .wizard ul li.complete {
  color: #fff;
  background: #4D90FD;
}
.fuelux .wizard ul li.complete:hover {
  background: #5c9aff;
}
.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left-color: #5c9aff;
}
.fuelux .wizard ul li.complete .chevron {
  border-left-color: #1e7dcc;
}
.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #4D90FD;
}
.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #FFF;
}
.fuelux .wizard ul li:first-child {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.fuelux .wizard ul li {
  font-size: 15px;
}
@media (max-width: 767px) {
  .wizard-row .fuelux {
    position: static;
  }
}
/*TABS*/
.nav-tabs {
  border-bottom: 1px solid transparent;
}
.nav-tabs > li {
  font-size: 14px;
  margin-right: 2px;
}
.nav-tabs > li.active {
  position: relative;
}
.nav-tabs > li > a {
  padding: 9px 15px;
  font-size: 13px;
  margin-right: 0;
  min-width: 80px;
  text-align: center;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:active {
  background: transparent;
  border: 1px solid transparent;
  color: #000;
  border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-top: 2px solid #7ebd1d;
  border-radius: 0;
  -webkit-border-radius: 0;
  border-bottom: 0;
  padding-bottom: 10px;
  padding-top: 8px;
  box-shadow: 1px -3px 4px -3px rgba(0, 0, 0, 0.08);
  border-left: 1px solid #ECECEC;
  border-right: 1px solid #ECECEC;
  position: static;
}
.nav-tabs > li.active > a:after,
.nav-tabs > li.active > a:hover:after,
.nav-tabs > li.active > a:focus:after {
  content: "";
  display: block;
  height: 2px;
  background: #7ebd1d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.tab-content {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #ECECEC;
  border-radius: 0 3px 3px;
  -webkit-border-radius: 0 3px 3px;
  border-right: 1px solid #ECECEC;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 40px;
}
.tab-content h3,
h2,
h1:first-child {
  margin-top: 0;
}
/*Tab Bottom*/
.tab-bottom .nav-tabs {
  margin-bottom: 40px;
}
.tab-bottom .tab-content {
  margin-bottom: 0;
}
.tab-bottom .nav-tabs > li.active > a:after,
.tab-bottom .nav-tabs > li.active > a:hover:after,
.tab-bottom .nav-tabs > li.active > a:focus:after {
  bottom: 0;
  top: auto;
}
.tab-bottom .nav-tabs > li.active > a,
.tab-bottom .nav-tabs > li.active > a:hover,
.tab-bottom .nav-tabs > li.active > a:focus {
  border-top: 0;
  border-bottom: 2px solid #7ebd1d;
  margin-top: -1px;
  box-shadow: 1px 3px 4px -3px rgba(0, 0, 0, 0.08);
}
.tab-bottom .tab-content {
  border-radius: 3px 3px 3px 0;
  -webkit-border-radius: 3px 3px 3px 0;
}
/*Tab Left*/
.tab-left .nav-tabs {
  float: left;
}
.tab-left .nav-tabs > li {
  float: none;
  margin-right: 0;
  margin-bottom: 0;
}
.tab-left .nav-tabs > li > a {
  text-align: left;
  min-width: 38px;
  padding: 12px 10px;
  border-radius: 0;
  -webkit-border-radius: 0;
}
.tab-left .nav-tabs > li .fa {
  font-size: 14px;
}
.tab-left .nav-tabs > li.active > a:after,
.tab-left .nav-tabs > li.active > a:hover:after,
.tab-left .nav-tabs > li.active > a:focus:after {
  bottom: 0;
  top: auto;
  width: 2px;
  height: 100%;
}
.tab-left .nav-tabs > li.active > a,
.tab-left .nav-tabs > li.active > a:hover,
.tab-left .nav-tabs > li.active > a:focus {
  border-top: 0;
  border-bottom: 1px solid #ECECEC;
  border-right: 0;
  border-left: 2px solid #7ebd1d;
  margin-right: -1px;
  box-shadow: -1px 3px 4px -3px rgba(0, 0, 0, 0.08);
  padding: 13px 10px 12px 9px;
}
.tab-left .tab-content {
  overflow: auto;
  border-radius: 0 3px 3px 3px;
  -webkit-border-radius: 0 3px 3px 3px;
}
/*Tab Right*/
.tab-right .nav-tabs {
  float: right;
}
.tab-right .tab-content {
  overflow: auto;
  border-radius: 3px 0 3px 3px;
  -webkit-border-radius: 3px 0 3px 3px;
}
.tab-right .nav-tabs > li {
  float: none;
  margin-right: 0;
  margin-bottom: 2px;
}
.tab-right .nav-tabs > li > a {
  text-align: left;
  min-width: 38px;
  padding: 12px 10px;
}
.tab-right .nav-tabs > li .fa {
  font-size: 14px;
}
.tab-right .nav-tabs > li.active > a:after,
.tab-right .nav-tabs > li.active > a:hover:after,
.tab-right .nav-tabs > li.active > a:focus:after {
  bottom: 0;
  right: 0;
  left: auto;
  top: auto;
  width: 2px;
  height: 100%;
}
.tab-right .nav-tabs > li.active > a,
.tab-right .nav-tabs > li.active > a:hover,
.tab-right .nav-tabs > li.active > a:focus {
  border-top: 0;
  border-bottom: 1px solid #ECECEC;
  border-left: 0;
  border-right: 2px solid #7ebd1d;
  margin-left: -1px;
  box-shadow: 1px 3px 4px -3px rgba(0, 0, 0, 0.08);
  padding: 13px 10px 12px 12px;
}
.shadow.tab-content {
  border-bottom: 1px solid #DADADA;
  border-left: 1px solid #DADADA;
  border-right: 1px solid #DADADA;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
}
/*Accordion*/
.accordion {
  margin-bottom: 40px;
}
.accordion .panel-heading {
  background: #fff;
  padding: 0;
}
.accordion .panel-heading a {
  font-size: 14px;
  line-height: 18px;
  padding: 17px 16px;
  display: block;
  color: #3078EF;
}
.accordion .panel-heading a span {
  font-size: 18px;
}
.accordion .panel-heading a .fa {
  margin-right: 10px;
  margin-left: 10px;
  color: #4c8b00;
}
.accordion .panel-heading a .fa:before {
  content: "\f146";
}
.accordion .panel-heading a.collapsed {
  color: #555;
}
.accordion .panel-heading a.collapsed .fa:before {
  content: "\f0fe";
}
.accordion .panel-heading + .panel-collapse .panel-body {
  border-top: 0;
  padding: 3px 20px 20px 34px;
}
.accordion .panel {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  border-bottom: 0;
  border-left: 1px solid #ECECEC;
  border-right: 1px solid #ECECEC;
  margin-bottom: 5px;
  border-top: 0;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}
.accordion .panel.inner {
  border: 1px solid #ECECEC;
}
.accordion .panel:not(.inner):first-child {
  border-top: 0;
}
.accordion.panel-group .panel + .panel {
  margin-top: 0px;
}
/*Primary color accordion*/
.accordion.accordion-color .panel-heading a {
  background-color: #7ebd1d;
  color: #FFF;
}
.accordion.accordion-color .panel-heading a.collapsed {
  background-color: #FFF;
  color: #555;
}
.accordion.accordion-color .panel-collapse .panel-body {
  background-color: #7ebd1d;
  color: #FFF;
}
/*Semi color accordion*/
.accordion.accordion-semi .panel-heading a {
  background-color: #7ebd1d;
  color: #FFF;
  transition: background-color 200ms ease-in-out;
  border-bottom: 1px solid #6fa71a;
}
.accordion.accordion-semi .panel-heading.success a {
  background-color: #60C060;
  border-bottom: 1px solid #54A754;
}
.accordion.accordion-semi .panel-heading.warning a {
  background-color: #FC9700;
  border-bottom: 1px solid #D68000;
}
.accordion.accordion-semi .panel-heading.danger a {
  background-color: #DA4932;
  border-bottom: 1px solid #CA452E;
}
.accordion.accordion-semi .panel-heading a.collapsed {
  background-color: #FFF;
  color: #555;
  border-bottom: 0;
}
.accordion.accordion-semi .panel-collapse .panel-body {
  padding: 17px 25px;
}
/*Nestable lists*/
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  cursor: default;
  display: block;
  margin: 5px 0;
  padding: 7px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #ddd;
  background: #fff;
}
.dd-handle:hover {
  color: #FFF;
  background: #4D90FD;
  border-color: #428BCA;
}
.dd-item > button {
  color: #555;
  font-family: FontAwesome;
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 8px 2px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 10px;
  line-height: 1;
  text-align: center;
}
.dd-item > button:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '';
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #FFF;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 7px 10px 7px 40px;
  color: #333;
  text-decoration: none;
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #DDDDDD;
  color: #333333;
}
.dd3-content:hover {
  background: #fff;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 35px;
}
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: all-scroll;
  width: 34px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #3680BF;
  background: #4D90FD;
  height: 36px;
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.dd3-handle:before {
  content: '=';
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}
.dd3-handle:hover {
  background: #4E9DFF;
}
.bootstrap-switch {
  border: 0;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  min-width: 74px;
  background-clip: padding-box;
}
.bootstrap-switch.bootstrap-switch-focused {
  box-shadow: none;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background: #7ebd1d;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  background: #E3E3E3;
}
.bootstrap-switch .bootstrap-switch-container {
  position: relative;
  z-index: 1;
  -webkit-transform: none;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  background: #7ebd1d;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  background: #E3E3E3;
}
.bootstrap-switch .bootstrap-switch-label:after {
  content: " ";
  display: block;
  width: 29px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  border: 3px solid #7ebd1d;
}
.bootstrap-switch.bootstrap-switch-large {
  min-width: 100px;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label:after {
  width: 42px;
  border-width: 4px;
}
.bootstrap-switch.bootstrap-switch-large.bootstrap-switch-on .bootstrap-switch-label:after {
  border-width: 4px;
}
.bootstrap-switch.bootstrap-switch-small {
  min-width: 60px;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label:after {
  width: 25px;
}
.bootstrap-switch.bootstrap-switch-mini {
  min-width: 44px;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label:after {
  width: 18px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
  background: #7ebd1d;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  background: #e3e3e3;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #e3e3e3;
  left: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on {
  padding-left: 6px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.bootstrap-switch .bootstrap-switch-label {
  position: relative;
}
/*ON Colors*/
.bootstrap-switch.bootstrap-switch-onclr-success.bootstrap-switch-on .bootstrap-switch-handle-on {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-onclr-success.bootstrap-switch-on .bootstrap-switch-label {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-onclr-success.bootstrap-switch-on .bootstrap-switch-label:after {
  border-color: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-onclr-success.bootstrap-switch-off .bootstrap-switch-container {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-onclr-info.bootstrap-switch-on .bootstrap-switch-handle-on {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-onclr-info.bootstrap-switch-on .bootstrap-switch-label {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-onclr-info.bootstrap-switch-on .bootstrap-switch-label:after {
  border-color: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-onclr-info.bootstrap-switch-off .bootstrap-switch-container {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-onclr-warning.bootstrap-switch-on .bootstrap-switch-handle-on {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-onclr-warning.bootstrap-switch-on .bootstrap-switch-label {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-onclr-warning.bootstrap-switch-on .bootstrap-switch-label:after {
  border-color: #FF9900;
}
.bootstrap-switch.bootstrap-switch-onclr-warning.bootstrap-switch-off .bootstrap-switch-container {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-onclr-danger.bootstrap-switch-on .bootstrap-switch-handle-on {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-onclr-danger.bootstrap-switch-on .bootstrap-switch-label {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-onclr-danger.bootstrap-switch-on .bootstrap-switch-label:after {
  border-color: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-onclr-danger.bootstrap-switch-off .bootstrap-switch-container {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-onclr-default.bootstrap-switch-on .bootstrap-switch-handle-on {
  background: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-onclr-default.bootstrap-switch-on .bootstrap-switch-label {
  background: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-onclr-default.bootstrap-switch-on .bootstrap-switch-label:after {
  border-color: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-onclr-default.bootstrap-switch-off .bootstrap-switch-container {
  background: #E3E3E3;
}
/*Off Colors*/
.bootstrap-switch.bootstrap-switch-offclr-primary.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #4D90FD;
}
.bootstrap-switch.bootstrap-switch-offclr-primary.bootstrap-switch-off .bootstrap-switch-label {
  background: #4D90FD;
}
.bootstrap-switch.bootstrap-switch-offclr-primary.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #4D90FD;
}
.bootstrap-switch.bootstrap-switch-offclr-primary.bootstrap-switch-on .bootstrap-switch-container {
  background: #4D90FD;
}
.bootstrap-switch.bootstrap-switch-offclr-info.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-offclr-info.bootstrap-switch-off .bootstrap-switch-label {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-offclr-info.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-offclr-info.bootstrap-switch-on .bootstrap-switch-container {
  background: #5BC0DE;
}
.bootstrap-switch.bootstrap-switch-offclr-success.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-offclr-success.bootstrap-switch-off .bootstrap-switch-label {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-offclr-success.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-offclr-success.bootstrap-switch-on .bootstrap-switch-container {
  background: #5CB85C;
}
.bootstrap-switch.bootstrap-switch-offclr-warning.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-offclr-warning.bootstrap-switch-off .bootstrap-switch-label {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-offclr-warning.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #FF9900;
}
.bootstrap-switch.bootstrap-switch-offclr-warning.bootstrap-switch-on .bootstrap-switch-container {
  background: #FF9900;
}
.bootstrap-switch.bootstrap-switch-offclr-danger.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-offclr-danger.bootstrap-switch-off .bootstrap-switch-label {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-offclr-danger.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-offclr-danger.bootstrap-switch-on .bootstrap-switch-container {
  background: #DF4B33;
}
.bootstrap-switch.bootstrap-switch-offclr-default.bootstrap-switch-off .bootstrap-switch-handle-off {
  background: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-offclr-default.bootstrap-switch-off .bootstrap-switch-label {
  background: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-offclr-default.bootstrap-switch-off .bootstrap-switch-label:after {
  border-color: #E3E3E3;
}
.bootstrap-switch.bootstrap-switch-offclr-default.bootstrap-switch-on .bootstrap-switch-container {
  background: #E3E3E3;
}
/*Select 2*/
.select2-container .select2-choice {
  border: 1px solid #CCCCCC;
  background: #fff;
  padding: 4px 8px;
  height: auto;
  border-radius: 1px;
  -webkit-border-radius: 1px;
}
/*box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.18);*/
.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background: #fff;
}
.select2-drop.select2-drop-above {
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
}
.select2-container .select2-choice .select2-arrow {
  width: 30px;
  background-color: #FFFFFF;
  background-image: linear-gradient(to bottom, #FFFFFF 60%, #F9F9F9 100%);
}
.select2-container .select2-choice .select2-arrow b {
  background-position: 7px 4px;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -13px 3px;
}
.select2-container.select2-drop-above .select2-choice {
  border-radius: 0 0 2px 2px;
  -webkit-border-radius: 0 0 2px 2px;
}
/*Tags*/
.select2-container-multi .select2-choices .select2-search-choice {
  border: 1px solid #CCCCCC;
  background-color: #FFFFFF;
  background-image: linear-gradient(to bottom, #FFFFFF 60%, #F9F9F9 100%);
  padding: 3px 5px 5px 18px;
}
.select2-container-multi .select2-choices {
  padding: 5px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
}
.select2-container-multi .select2-choices .select2-search-field input {
  width: 100% !important;
}
/*Slider*/
.slider.slider-vertical {
  margin-right: 13px;
}
.slider-selection {
  background: #4D90FD;
  border: 1px solid #437DDC;
  box-shadow: none;
}
.slider-handle {
  opacity: 1;
  background-color: #FFFFFF;
  background-image: linear-gradient(to bottom, #FFFFFF, #F9F9F9);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F9F9F9));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #FFFFFF, #F9F9F9);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -moz-linear-gradient(top, #FFFFFF, #F9F9F9);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, #FFFFFF, #F9F9F9);
  /* Opera 11.10-12.00 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.39);
  height: 23px;
  width: 23px;
  transition: box-shadow 200ms ease-in-out;
}
.slider-handle:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.45);
}
.slider.slider-horizontal .slider-handle {
  margin-top: -7px;
}
.slider.slider-vertical .slider-handle {
  margin-left: -6px;
}
/*CHAT*/
.chat-wi .chat-space {
  height: 377px;
  padding: 15px;
}
.chat-wi .chat-content {
  padding: 15px;
  background: #fff;
}
.chat-wi .chat-conv {
  position: relative;
  text-align: right;
  padding: 10px;
  margin-bottom: 15px;
}
.chat-wi .chat-conv .c-avatar {
  position: absolute;
  border: 1px solid #D6DAE0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.09);
  height: 50px;
  width: 50px;
  vertical-align: top;
  right: 0;
}
.chat-wi .chat-conv .c-bubble {
  position: relative;
  display: inline-block;
  padding: 8px 9px 5px 9px;
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
  margin: 0 60px 0 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
  border-top: 1px solid #4aa4ec;
  border-bottom: 1px solid #2f7ee5;
  box-shadow: 0 1px 0 #7fc6fe inset, 1px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  background-image: -moz-linear-gradient(center top, #50b1fe 45%, #348bfc 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#50b1fe), to(#348bfc));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #50b1fe, #348bfc);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #50b1fe, #348bfc);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #50b1fe 45%, #348bfc 102%);
  background-color: #43a2ff;
}
.chat-wi .chat-conv .c-bubble span {
  background: url(./less/images/bubble-tail.png) no-repeat;
  height: 21px;
  width: 12px;
  display: block;
  position: absolute;
  right: -12px;
  top: 5px;
}
.chat-wi .sent {
  text-align: left;
}
.chat-wi .sent .c-bubble {
  margin: 0 0 0 60px;
  border-bottom: 1px solid #f3f3f3;
  border-top: 1px solid #e3e3e3;
  box-shadow: 0 1px 0 #FFF inset, 0 1px 2px rgba(0, 0, 0, 0.25);
  text-shadow: none;
  color: #666;
  background-image: -moz-linear-gradient(center top, #FFFFFF 45%, #F3F3F3 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#FFFFFF), to(#F3F3F3));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #FFFFFF, #F3F3F3);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #FFFFFF, #F3F3F3);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #FFFFFF 45%, #F3F3F3 100%);
}
.chat-wi .sent .c-avatar {
  left: 0;
}
.chat-wi .sent .c-bubble span {
  background: url(./less/images/bubble-tail-l-white.png) no-repeat;
  left: -12px;
}
.chat-wi .chat-in {
  border-top: 1px solid #DADADA;
  background: #F6F8FB;
}
.chat-wi .chat-in input[type="text"] {
  width: 100%;
  border: 0;
  height: 47px;
  padding: 10px;
  font-size: 15px;
  font-weight: 200;
  color: #555;
}
.chat-wi .chat-tools {
  background: #F6F8FB;
  border-top: 1px solid #dfdfdf;
  color: #6f6f6f;
}
.chat-wi .chat-tools i {
  display: inline-block;
  width: 38px;
  padding: 13px 8px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.chat-wi .chat-tools i:hover {
  background: #e5e7ea;
}
.chat-wi .chat-in button,
.chat-wi .chat-in input[type="submit"] {
  box-shadow: none;
  background: #E5E7EA;
  border: 1px solid #E5E7EA;
  border-left: 1px solid #dadada;
  border-radius: 0;
  -webkit-border-radius: 0;
  height: 47px;
  min-width: 70px;
  color: #777;
  transition: border-color 0.21s ease-out 0s;
  margin: 0;
}
.chat-wi .chat-in button:hover {
  border-color: #4D90FE;
  color: #555;
}
.chat-wi .chat-in .input {
  margin-right: 70px;
}
/*Alerts*/
.alert {
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);
}
.alert .sign {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
  text-align: center;
  width: 25px;
  display: inline-block;
}
.alert-success {
  background-color: #DBF6D3;
  border-color: #AED4A5;
  color: #569745;
}
.alert-info {
  background-color: #D9EDF7;
  border-color: #98cce6;
  color: #3A87AD;
}
.alert-warning {
  background-color: #FCF8E3;
  border-color: #F1DAAB;
  color: #C09853;
}
.alert-danger {
  background-color: #F2DEDE;
  border-color: #e0b1b8;
  color: #B94A48;
}
.alert-white {
  background-image: linear-gradient(to bottom, #FFFFFF, #F9F9F9);
  border-top-color: #d8d8d8;
  border-bottom-color: #bdbdbd;
  border-left-color: #cacaca;
  border-right-color: #cacaca;
  color: #404040;
  padding-left: 61px;
  position: relative;
}
.alert-white .icon {
  text-align: center;
  width: 45px;
  height: 103%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid #BDBDBD;
  padding-top: 15px;
}
.alert-white .icon:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #BDBDBD;
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  top: 50%;
  right: -6px;
  margin-top: -3px;
  background: #fff;
}
.alert-white.rounded {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.alert-white.rounded .icon {
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}
.alert-white .icon i {
  font-size: 20px;
  color: #FFF;
  left: 12px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
.alert-white.alert-danger .icon,
.alert-white.alert-danger .icon:after {
  border-color: #ca452e;
  background: #da4932;
}
.alert-white.alert-info .icon,
.alert-white.alert-info .icon:after {
  border-color: #3A8ACE;
  background: #4D90FD;
}
.alert-white.alert-warning .icon,
.alert-white.alert-warning .icon:after {
  border-color: #D68000;
  background: #FC9700;
}
.alert-white.alert-success .icon,
.alert-white.alert-success .icon:after {
  border-color: #6aa909;
  background: #7ebd1d;
}
.dashboard-alert {
  margin-top: 20px;
  margin-bottom: 0;
}
/*Labels*/
.label {
  border-radius: 0;
  -webkit-border-radius: 0;
  font-weight: normal;
  font-size: 95%;
}
.label-default {
  background-color: #a1a1a1;
}
.label i {
  font-size: 100% !important;
  color: inherit !important;
  width: auto !important;
  min-width: 11px;
}
.label-success {
  background-color: #60C060;
}
.label-primary {
  background-color: #4D90FD;
}
.label-warning {
  background-color: #fcad37;
}
.label-danger {
  background-color: #ED5B56;
}
.label-info {
  background-color: #5DC4EA;
}
/*Bagde*/
.badge {
  font-weight: normal;
  font-size: 95%;
  padding: 4px 6px;
  background-color: #a1a1a1;
}
.badge-primary {
  background-color: #4D90FD !important;
}
.badge-success {
  background-color: #60C060 !important;
}
.badge-info {
  background-color: #5DC4EA !important;
}
.badge-warning {
  background-color: #fcad37 !important;
}
.badge-danger {
  background-color: #ED5B56 !important;
}
/*Progress*/
.progress {
  height: 18px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.progress .progress-bar {
  font-size: 11px;
  line-height: 17px;
}
.progress-bar-info {
  background-color: #4D90FD;
}
.progress-bar-success {
  background-color: #60C060;
}
.progress-bar-warning {
  background-color: #FC9700;
}
.progress-bar-danger {
  background-color: #DA4932;
}
/*Pagination*/
.pagination {
  margin: 18px 0;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #2083D6;
}
/*Nav Pills*/
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #4D90FD;
}
/*List Group*/
.list-group-item:first-child {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.list-group-item:last-child {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #4D90FD;
}
.list-group-item i {
  min-width: 15px;
  display: inline-block !important;
  text-align: center;
}
.list-group-item h4 {
  margin-bottom: 8px;
  font-size: 17px;
}
.list-group-item h4 {
  margin-bottom: 8px;
  font-size: 15px;
}
.list-group-item {
  font-size: 12px;
  padding: 13px 15px;
  line-height: 22px;
}
a.list-group-item:hover {
  border-left: 5px solid #4D90FD;
  border-color: #4D90FD;
  padding-left: 11px;
}
a.list-group-item:hover + a.list-group-item {
  border-top-color: #4D90FD;
}
.list-group .active:hover {
  padding-left: 15px;
  border-left: 1px solid #428BCA;
}
/*DarkList Widget*/
.dark-list {
  color: #FFF;
}
.dark-list .header {
  background-color: #272930;
  border-bottom-color: #1A1C20;
}
.dark-list .header h3 {
  color: #999;
}
.dark-list .items li {
  background-color: #272930;
  border-bottom: 1px solid #1A1C20;
  border-top: 1px solid #32353E;
}
.dark-list .items li:hover {
  background-color: #2A2C34;
}
.dark-list .items li:first-child {
  border-top: 1px solid #32353E;
}
.dark-list .items li:last-child {
  border-bottom: 0;
}
.dark-list .items li i {
  color: #FFF;
}
.dark-list .total-data {
  border-top: 1px solid #131519;
  box-shadow: 0 1px 0 #2A2C31 inset;
  background: #1F2126;
  border-radius: 0 0 3px 3px;
}
.dark-list .total-data > a {
  color: #C9D4F6;
}
/*Icons*/
.fa-mobile-phone {
  font-size: 18px;
}
.icon-16 {
  font-size: 16px !important;
}
.icon-19 {
  font-size: 19px !important;
}
.icon-24 {
  font-size: 24px !important;
}
.icon-32 {
  font-size: 32px !important;
}
.icon-64 {
  font-size: 64px !important;
}
.icon-show i {
  font-size: 14px !important;
  margin-right: 5px;
  width: 20px;
}
.icon-show > div {
  margin: 5px 0;
}
/*Buttons*/
.btn {
  border-color: #CCCCCC;
  border-radius: 0;
  -webkit-border-radius: 0;
  outline: none;
  margin-bottom: 5px !important;
  margin-left: 4px;
  font-size: 13px;
  padding: 7px 11px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.btn-default,
.btn-default:focus {
  background-color: #FFFFFF;
  border-color: #CCCCCC;
  color: #333333;
  background-image: linear-gradient(to bottom, #FFFFFF 60%, #F9F9F9 100%);
}
.btn-default i {
  color: #444;
}
.btn-primary,
.btn-primary:focus {
  background-color: #7ebd1d !important;
  border-color: #6aa909 !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #6aa909;
  border-color: #387700;
}
.btn-primary:active {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125) inset;
}
.btn-success,
.btn-success:focus {
  background-color: #7ebd1d;
  border-color: #6aa909;
}
.btn-success:hover,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #6aa909;
  border-color: #387700;
}
.btn-info,
.btn-info:focus {
  background-color: #5BC0DE;
  border-color: #28a1c4;
}
.btn-info:hover,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #5fc8e7;
  border-color: #28A1C4;
}
.btn-warning,
.btn-warning:focus {
  background-color: #FF9900;
  border-color: #E38800;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #ffa800;
}
.btn-danger,
.btn-danger:focus {
  background-color: #DF4B33;
  border-color: #CA452E;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #e64d35;
}
.btn-rad {
  border-radius: 2px;
  -webkit-border-radius: 2px;
}
.btn i {
  font-size: 14px;
  margin-right: 2px;
  display: inline-block;
  min-width: 10px;
}
.btn-group-vertical {
  margin: 3px 3px;
}
/*Specific icons size*/
.btn-lg {
  padding: 12px 14px;
  font-size: 15px;
  font-weight: 300;
}
.btn-lg i {
  font-size: 18px;
}
.btn-sm {
  padding: 4px 7px;
  font-size: 12px;
}
.btn-sm i {
  font-size: 14px;
}
.btn-xs {
  padding: 2px 6px;
  font-size: 11px;
}
.btn-xs i {
  font-size: 12px;
}
.btn-shadow {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
}
.btn-shadow.btn-success {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn-shadow.btn-primary {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn-shadow.btn-warning {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn-shadow.btn-danger {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn-shadow.btn-info {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
.btn-flat,
.btn-flat:focus {
  box-shadow: none;
}
.btn-flat.btn-default {
  background: #fff;
}
.btn-flat:active.btn-default,
.btn-flat.btn-default.active,
.btn-flat.open .dropdown-toggle.btn-default {
  background: #f5f5f5;
}
.btn-flat:active.btn-primary,
.btn-flat.btn-primary.active,
.btn-flat.open .dropdown-toggle.btn-primary {
  background: #4785ea;
}
.btn-flat:active.btn-success,
.btn-flat.btn-success.active,
.btn-flat.open .dropdown-toggle.btn-success {
  background: #5db95d;
}
.btn-flat:active.btn-info,
.btn-flat.btn-info.active,
.btn-flat.open .dropdown-toggle.btn-info {
  background: #56b7d3;
}
.btn-flat:active.btn-warning,
.btn-flat.btn-warning.active,
.btn-flat.open .dropdown-toggle.btn-warning {
  background: #ff9600;
}
.btn-flat:active.btn-danger,
.btn-flat.btn-danger.active,
.btn-flat.open .dropdown-toggle.btn-danger {
  background: #d04630;
}
.btn-group-vertical > .btn {
  margin-bottom: 0 !important;
}
.btn-group-vertical > .btn:first-child {
  margin-left: 0;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}
.btn-group-vertical > .btn:last-child {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}
/*Social buttons*/
.btn-facebook i {
  color: #3b5998;
}
.btn-twitter i {
  color: #00aced;
}
.btn-google-plus i {
  color: #dd4b39;
}
.btn-dribbble i {
  color: #ea4c89;
}
.btn-linkedin i {
  color: #0e76a8;
}
.btn-youtube i {
  color: #c4302b;
}
.btn-pinterest i {
  color: #c8232c;
}
.btn-tumblr i {
  color: #34526f;
}
.btn-flickr i {
  color: #ff0084;
}
.btn-facebook.bg {
  background: #3b5998;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-twitter.bg {
  background: #00aced;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-google-plus.bg {
  background: #dd4b39;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-dribbble.bg {
  background: #ea4c89;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-linkedin.bg {
  background: #0e76a8;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-youtube.bg {
  background: #c4302b;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-pinterest.bg {
  background: #c8232c;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-tumblr.bg {
  background: #34526f;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-flickr.bg {
  background: #0063dc;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn-github.bg {
  background: #333;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.btn.bg i {
  color: #FFF;
}
.btn:focus {
  outline: none;
}
.btn-group.btn-blurbg {
  background: url("./less/images/blur_bg.png") no-repeat;
  padding: 40px;
  margin-bottom: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.btn.btn-transparent {
  border-top: 1px solid #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35);
}
.btn.btn-transparent:hover {
  background: rgba(255, 255, 255, 0.32);
}
.video-player {
  position: relative;
  display: inline-block;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.video-player video {
  display: block;
  max-width: 320px;
  width: 100%;
}
.video-player .btn-controls {
  text-align: center;
  bottom: 5px;
  position: absolute;
  width: 100%;
}
.video-player .btn-controls .btn-group {
  margin: 0;
}
.video-player .btn-controls .btn-group {
  float: none;
}
button::-moz-focus-inner {
  border: 0;
}
.btn-group .dropdown-menu {
  border-radius: 1px;
}
.input-group-btn .btn[type="submit"] {
  padding-bottom: 7px 10px;
}
.btn-link {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/*Bootstrap 3.1.0 Fix*/
.btn-group .dropdown-backdrop + .btn {
  margin-left: -1px;
}
.input-group-addon.btn-primary,
.input-group-addon.btn-success,
.input-group-addon.btn-danger,
.input-group-addon.btn-warning {
  color: #fff;
}
/*Modals*/
.modal {
  overflow: auto;
}
.modal-content {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 0;
}
.custom-width {
  width: 40%;
  max-width: none;
}
.modal-content > .close {
  margin-right: 10px;
  margin-top: 10px;
}
.md-content {
  background: #FFFFFF;
  border: 0 none;
  border-radius: 5px;
  color: #555555;
  overflow: hidden;
}
.md-content .modal-header {
  border-bottom: 0 none;
}
.modal-content .modal-header {
  border-bottom: 0;
}
.colored-header .modal-header {
  padding: 20px;
  border-bottom: 1px solid #6aa909 !important;
  background-color: #7ebd1d;
  color: #fff;
}
.colored-header.success .modal-header {
  padding: 20px;
  border-bottom: 1px solid #4cac4c !important;
  background-color: #60C060;
  color: #fff;
}
.colored-header.info .modal-header {
  padding: 20px;
  border-bottom: 1px solid #47acca !important;
  background-color: #5BC0DE;
  color: #fff;
}
.colored-header.warning .modal-header {
  padding: 20px;
  border-bottom: 1px solid #eb8500 !important;
  background-color: #FF9900;
  color: #fff;
}
.colored-header.danger .modal-header {
  padding: 20px;
  border-bottom: 1px solid #cb371f !important;
  background-color: #DF4B33;
  color: #fff;
}
.md-dark .modal-header {
  background-color: #7ebd1d;
  color: #fff;
}
.md-dark .modal-header {
  background-color: #7ebd1d;
  color: #fff;
}
.md-dark .md-content {
  background-color: #23262B;
  color: #BBBEC3;
}
.md-dark .md-content input {
  background-color: transparent;
  color: #b2b2b2;
}
.md-dark .modal-content .modal-footer {
  border: 0;
  background-color: transparent;
}
.md-dark .md-content .modal-footer {
  border: 0;
  background-color: transparent;
}
.md-dark .md-content .modal-footer .btn {
  margin-bottom: 0 !important;
}
.modal-content h4,
.modal-content h4 {
  font-weight: 400;
}
.modal-content h3,
.modal-content h3 {
  font-weight: 300;
  margin-top: 0;
}
.modal-content h2 {
  margin: 0;
}
.modal-content .modal-footer {
  background: #FBFBFB;
  border-radius: 0 0 5px 5px;
  -webkit-border-radius: 0 0 5px 5px;
}
@media (min-width: 768px) and (max-width: 979px) {
  .custom-width {
    width: 60%;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .custom-width {
    width: 90%;
    max-width: none;
  }
}
/*Circle*/
.i-circle {
  border: 5px solid #F1F1F1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 80px;
  width: 80px;
  display: inline-block;
  padding-top: 15px;
  margin-bottom: 7px;
}
.i-circle i {
  font-size: 34px;
  vertical-align: middle;
  color: #666;
}
.i-circle.success i {
  color: #60C060;
}
.i-circle.primary i {
  color: #4D90FD;
}
.i-circle.info i {
  color: #5BC0DE;
}
.i-circle.warning i {
  color: #FC9700;
}
.i-circle.danger i {
  color: #DA4932;
}
/*Gritter Notifications*/
#gritter-notice-wrapper {
  width: 320px;
  top: 45px;
}
.gritter-item-wrapper {
  background: url(./less/js/jquery.gritter/images/gritter-bg.png);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.32);
}
.gritter-top {
  background: transparent;
}
.gritter-item {
  font-family: 'Open Sans', sans-serif;
  background: transparent;
  color: #FFF;
  padding: 2px 20px 12px;
  padding-right: 35px;
  padding-left: 10px;
}
.gritter-bottom {
  background: transparent;
}
.gritter-item p {
  font-size: 12px;
  line-height: 19px;
}
.gritter-title {
  text-shadow: none;
  font-weight: 300;
  font-size: 17px;
}
.gritter-close {
  display: block !important;
  top: 0;
  right: 0;
  left: auto;
  height: 30px;
  width: 35px;
  background: transparent;
  text-indent: inherit;
}
.gritter-close:after {
  content: '×';
  position: absolute;
  color: #FFF;
  left: 10px;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}
.gritter-without-image,
.gritter-with-image {
  padding-left: 7px;
  width: 224px;
}
.gritter-item-wrapper.clean {
  background: #FFF;
}
.gritter-item-wrapper.clean .gritter-item {
  color: #555;
}
.gritter-item-wrapper.clean .gritter-close {
  display: block !important;
  top: 0;
  right: 0;
  left: auto;
  height: 100%;
  width: 35px;
  border-left: 1px solid #258fec;
  border-top: 1px solid #52C0FF;
  background-image: -moz-linear-gradient(center top, #52AEFF 45%, #2180D3 102%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#52AEFF), to(#2180D3));
  /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, #52AEFF, #2180D3);
  /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -o-linear-gradient(top, #52AEFF, #2180D3);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, #52AEFF, #2180D3);
  background-color: #3290E2;
  text-indent: inherit;
}
.gritter-item-wrapper.clean .gritter-close:after {
  content: '×';
  position: absolute;
  color: #FFF;
  top: 50%;
  left: 10px;
  font-size: 24px;
  font-weight: bold;
  margin-top: -17px;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}
.gritter-image {
  margin-top: 3px;
}
/*Facebook Style*/
.gritter-item-wrapper.facebook {
  background: #5d82d1;
}
.gritter-item-wrapper.facebook .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.facebook .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Twitter Style*/
.gritter-item-wrapper.twitter {
  background: #50bff5;
}
.gritter-item-wrapper.twitter .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.twitter .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Google+ */
.gritter-item-wrapper.google-plus {
  background: #eb5e4c;
}
.gritter-item-wrapper.google-plus .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.google-plus .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Dribbble */
.gritter-item-wrapper.dribbble {
  background: #f7659c;
}
.gritter-item-wrapper.dribbble .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.dribbble .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Flickr */
.gritter-item-wrapper.flickr {
  background: #0063dc;
}
.gritter-item-wrapper.flickr .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.flickr .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Linkedin */
.gritter-item-wrapper.linkedin {
  background: #238cc8;
}
.gritter-item-wrapper.linkedin .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.linkedin .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Youtube */
.gritter-item-wrapper.youtube {
  background: #ef4e41;
}
.gritter-item-wrapper.youtube .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.youtube .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Pinterest */
.gritter-item-wrapper.pinterest {
  background: #e13138;
}
.gritter-item-wrapper.pinterest .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.pinterest .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Github */
.gritter-item-wrapper.github {
  background: #333;
}
.gritter-item-wrapper.github .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.github .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Tumblr */
.gritter-item-wrapper.tumblr {
  background: #426d9b;
}
.gritter-item-wrapper.tumblr .gritter-item {
  color: #FFF;
}
.gritter-item-wrapper.tumblr .gritter-close {
  background-color: transparent;
  top: 5px;
}
/*Colors */
.gritter-item-wrapper.primary {
  background: #7ebd1d;
}
.gritter-item-wrapper.success {
  background: #60C060;
}
.gritter-item-wrapper.info {
  background: #5BC0DE;
}
.gritter-item-wrapper.warning {
  background: #FF9900;
}
.gritter-item-wrapper.danger {
  background: #DF4B33;
}
.gritter-item-wrapper.dark {
  background: #23262B;
}
/*Nano Scroller*/
.nano > .pane > .slider {
  width: 100%;
  background-color: #827e7e;
}
.jqstooltip {
  padding: 3px 10px 5px 5px;
  min-height: 25px;
  min-width: 30px;
}
.tree > div {
  /* overflow: auto; */
}
.tree .content {
  padding: 0 !important;
}
.tree .title {
  padding: 20px;
}
.treeview {
  display: block;
}
.treeview label {
  font-weight: 400;
  margin: 0;
  padding: 7px 18px;
  display: block;
  border-bottom: 0px solid #E5E5E5;
}
.treeview label:hover,
.treeview label.open {
  background: #F9F9F9;
}
.treeview li > ul {
  display: none;
}
.treeview li.open > ul {
  display: block;
}
.treeview ul li {
  border-bottom: 0px solid #E5E5E5;
}
.treeview ul li a {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #555;
}
.treeview ul li a:hover {
  background: #F9F9F9;
}
.treeview label i {
  font-size: 14px;
  text-align: center;
  width: 24px;
  color: #4D90FD;
}
.treeview ul li label {
  padding-left: 36px;
}
.treeview ul li ul li label {
  padding-left: 54px;
}
.treeview ul li ul li ul li label {
  padding-left: 72px;
}
.treeview ul li ul li ul li ul li label {
  padding-left: 90px;
}
.treeview ul li a {
  padding-left: 36px;
}
.treeview ul li ul li a {
  padding-left: 54px;
}
.treeview ul li ul li ul li a {
  padding-left: 72px;
}
.treeview ul li ul li ul li a {
  padding-left: 90px;
}
.treeview .tree-toggler {
  cursor: pointer;
}
@media (max-width: 767px) {
  .page-aside.tree .header {
    border-bottom: 1px solid #E5E5E5;
  }
  .tree .nano .content {
    position: static;
    overflow: visible;
  }
  .treeview.collapse {
    display: none;
  }
  .treeview.collapse.in {
    display: block;
  }
}
ul.timeline {
  position: relative;
  padding: 0 0 1px 0;
  margin-bottom: 40px;
}
ul.timeline:before {
  background-color: #E1E1E1;
  bottom: 0;
  content: "";
  left: 31px;
  margin-left: -10px;
  position: absolute;
  top: 2px;
  width: 2px;
}
ul.timeline:after {
  bottom: -9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #E1E1E1;
  position: absolute;
  left: 17px;
  content: "";
}
ul.timeline li {
  position: relative;
  list-style: none;
  margin-bottom: 25px;
}
ul.timeline li > .fa {
  left: 2px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #FFF;
  border-radius: 50%;
  background: #52AEFF;
  padding: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  border: 3px solid #FFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 2;
  top: 0;
}
ul.timeline li .fa.red {
  background: #EA6153;
}
ul.timeline li .fa.green {
  background: #7ebd1d;
}
ul.timeline li .fa.purple {
  background: #A66BBE;
}
ul.timeline li .content {
  position: relative;
  border-radius: 3px;
  background: #FFF;
  margin-left: 75px;
  padding: 12px 10px;
  border: 1px solid #E8E8E6;
  line-height: 15px;
}
ul.timeline li .content p {
  margin: 0;
}
ul.timeline li .content .img-thumbnail {
  border-radius: 0;
  margin: 10px 5px 0 0;
  cursor: pointer;
}
ul.timeline li .content blockquote {
  margin: 10px 0 0 0;
}
ul.timeline li .content blockquote p {
  margin: 0 0 5px 0 !important;
  font-size: 14px;
}
ul.timeline li .content:before {
  background: #FFF;
  position: absolute;
  top: 14px;
  content: "";
  width: 10px;
  height: 10px;
  left: -6px;
  border-left: 1px solid #E8E8E6;
  border-bottom: 1px solid #E8E8E6;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
}
ul.timeline li > .date {
  background: white;
  position: absolute;
  top: 49px;
  display: block;
  width: 44px;
  padding: 0 5px;
  text-align: center;
  border: 1px solid #E1E1E1;
  font-size: 11px;
  border-radius: 1px;
  text-shadow: 0 1px 0 #fafafa;
  color: #222;
  line-height: 19px;
}
@media screen and (min-width: 1500px) {
  .cbp_tmtimeline > li .cbp_tmtime {
    padding-right: 130px;
  }
}
/*Validation*/
.parsley-errors-list {
  margin-top: 4px;
  padding: 0;
}
.parsley-errors-list li {
  padding: 0;
  list-style: none;
  color: #cc0000;
}
.parsley-error {
  border-color: #c00 !important;
}
/*Tickets Widget*/
.list-group.tickets .list-group-item {
  overflow: hidden;
  margin-bottom: 10px;
  background: #F2F2F2;
  border-radius: 4px;
  border: 0;
}
.list-group.tickets .list-group-item {
  padding: 13px 15px;
}
.list-group.tickets .list-group-item .avatar {
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.list-group.tickets .list-group-item .name {
  margin: 0 0 5px 0;
  font-size: 13px;
  font-weight: 600;
}
.list-group.tickets .list-group-item p {
  margin: 0;
  line-height: 12px;
}
.list-group.tickets .list-group-item .date {
  font-size: 11px;
  color: #999;
}
.list-group.tickets .list-group-item .label {
  line-height: 16px;
}
/*Todo Widget*/
.list-group.todo .list-group-item {
  font-size: 13px;
  border: 0;
  margin-bottom: 10px;
  background: #FFF;
  border-bottom: 1px solid #E2E2E2;
  border-left: 1px solid #EFEFEF;
  border-radius: 3px;
  border-right: 1px solid #EFEFEF;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
  padding: 13px 15px;
}
.list-group.todo .list-group-item .date {
  font-size: 12px;
  float: right;
}
.fc-header {
  display: block;
}
.fc-header td {
  border-bottom: 0;
  border-left: 0;
}
.fc-header td:last-child {
  border-right: 0;
}
.calendar-env .calendar-body .fc-header .fc-header-left {
  padding: 20px;
}
.calendar-env .calendar-body .fc-header .fc-header-right {
  padding: 20px;
}
.fc-header-title {
  padding: 0 10px;
}
.fc-header .fc-button {
  margin: 0;
  vertical-align: middle;
}
.fc-button {
  padding: 10px;
  display: inline !important;
}
.fc-state-default.fc-corner-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.fc-state-default.fc-corner-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0px;
}
.fc-state-default {
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  color: #666;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.fc-state-active,
.fc-state-active .fc-button-inner,
.fc-state-active,
.fc-button-today .fc-button-inner,
.fc-state-hover,
.fc-state-hover .fc-button-inner {
  background: #88c727;
  color: #fff;
  box-shadow: none;
  text-shadow: none;
}
.fc-event {
  background: #7ebd1d;
  border-color: #7ebd1d;
}
.external-event {
  /* try to mimick the look of a real event */
  margin: 2px 0;
  padding: 2px 4px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #7ebd1d;
  color: #fff;
  font-size: 0.85em;
  cursor: pointer;
  display: inline-block;
}
#external-events p {
  padding-top: 5px;
  margin-top: 5px;
}
@media (max-width: 767px) {
  .fc-header .fc-header-left,
  .fc-header .fc-header-center,
  .fc-header .fc-header-right {
    text-align: center;
    display: block;
    width: 100%;
    padding: 20px;
  }
  .fc-header .fc-header-center {
    padding: 0;
  }
}
/*Multiselect*/
.ms-container {
  width: 100%;
}
.multiselect-container > li > label.multiselect-group {
  font-size: 13px;
  font-weight: 600;
}
.ms-container .ms-list {
  box-shadow: none;
}
.ms-container .ms-list.ms-focus {
  border-color: #7ebd1d;
  box-shadow: none;
}
.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  background: #7ebd1d;
}
#ms-custom-headers .custom-header {
  background: #7ebd1d;
  color: #FFFFFF;
  padding: 7px 10px;
  font-size: 14px;
}
#ms-custom-headers .ms-list {
  border-radius: 0 0 3px 3px;
  border-top: none;
}
#ms-searchable .ms-list {
  border-radius: 0 0 3px 3px;
  border-top: none;
}
/*Input Group Fix*/
.input-group-addon ~ .btn-group {
  margin-left: 0;
}
/*RESPONSIVE*/
/*Responsive CSS*/
@media (max-width: 979px) {
  .cl-mcont {
    max-width: calc(100vw - 69px);
  }
}
@media screen and (min-width: 979px) {
  .cl-mcont {
    max-width: calc(100vw - 231px);
  }
}
@media (min-width: 1200px) {
  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }
}
@media (min-width: 980px) and (max-width: 1199px) {
  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 979px) {
  .cl-vnavigation {
    width: 54px !important;
  }
  .butstyle {
    width: 30.9%;
  }
  .butstyle:first-child {
    margin-left: 0;
  }
  .navbar-default .navbar-brand span {
    display: none;
  }
  .cl-sidebar {
    width: 55px;
  }
  #pcont {
    margin-left: 55px;
  }
  #cl-wrapper.fixed-menu .menu-space {
    width: 55px;
  }
  .cl-vnavigation li {
    position: relative;
  }
  .cl-vnavigation .parent a {
    background: none;
  }
  .cl-vnavigation li a i {
    margin: 0;
    font-size: 17px;
  }
  .cl-vnavigation li a span {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .cl-sidebar .side-user {
    display: none;
  }
  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }
  .cl-vnavigation li ul {
    display: none !important;
  }
  .cl-sidebar .collapse-button {
    display: none;
  }
  .cl-sidebar .collapse-button .search {
    display: none;
  }
}
@media (max-width: 767px) {
  #cl-wrapper {
    padding-top: 0;
    display: block;
  }
  #pcont,
  .sb-collapsed #pcont {
    margin-left: 0;
    display: block;
  }
  .cl-mcont {
    padding: 15px;
    max-width: 100%;
  }
  /*nav bar*/
  .navbar {
    padding-right: 10px;
  }
  .navbar-fixed-top {
    position: relative;
    margin: 0;
  }
  /*dropdown menu*/
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #FFF;
  }
  .navbar-default .navbar-nav .dropdown-submenu > a:after {
    border-width: 0;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #FFF;
  }
  .navbar-nav .dropdown-menu > li > a:hover,
  .navbar-nav .dropdown-menu > li > a:focus,
  .navbar-nav .dropdown-submenu:hover > a {
    background-color: transparent;
  }
  .navbar-nav .dropdown-submenu ul {
    display: block;
    padding-left: 20px;
  }
  .navbar-nav .dropdown-header {
    color: #FFF;
  }
  .navbar-nav .col-menu-2 > li > ul > li a {
    color: #FFF;
    padding-left: 45px;
  }
  .navbar-nav .col-menu-3 > li > ul > li a {
    color: #FFF;
    padding-left: 45px;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:active {
    background: transparent !important;
    color: #FFF !important;
  }
  .navbar-default .navbar-nav > li > a,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus,
  .navbar-nav > li > a:active {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }
  .not-nav {
    text-align: center;
    background: #7ebd1d;
    border-top: 1px solid #97d636;
    margin: 0;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: #97d636;
  }
  .navbar-default .navbar-nav > li.button {
    float: none;
    display: inline-block;
    padding: 10px 4px;
    top: 100%;
  }
  .not-nav > li > .dropdown-menu {
    text-align: left;
  }
  .navbar-default .navbar-nav > li.button > a > i {
    font-size: 17px;
    vertical-align: middle;
  }
  .navbar-default .navbar-nav > li.button .dropdown-menu {
    position: absolute;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    border-top: 0;
    margin-left: -110px;
  }
  .navbar-default .navbar-nav > li.button .dropdown-menu > li > a {
    text-align: left;
  }
  .navbar-collapse {
    padding: 0 !important;
  }
  .navbar-collapse .navbar-nav {
    margin: 0;
  }
  .profile_menu {
    margin: 0;
  }
  .profile_menu .dropdown-toggle {
    padding-left: 15px;
  }
  .navbar-default .navbar-brand {
    width: auto;
  }
  .user-nav {
    margin: 7.5px -15px;
  }
  .navbar-collapse.in {
    overflow: visible;
  }
  #head-nav {
    padding: 0;
  }
  #head-nav .navbar-nav {
    padding: 0 10px;
  }
  .navbar-header {
    padding: 0 10px;
  }
  .col-menu-2 > div:first-child {
    border-right: 0;
  }
  /*end - dropdown*/
  .cl-sidebar,
  .sb-collapsed .cl-sidebar {
    background: none repeat scroll 0 0 #2B2D34;
    width: 100%;
    position: relative;
    z-index: 1020;
    display: block;
    padding-bottom: 0;
  }
  #cl-wrapper.fixed-menu .menu-space,
  #cl-wrapper.fixed-menu.sb-collapsed .menu-space {
    position: relative;
    height: auto !important;
    width: 100%;
  }
  #cl-wrapper .menu-space {
    min-height: 0 !important;
  }
  #cl-wrapper.fixed-menu .menu-space .content,
  #cl-wrapper.fixed-menu.sb-collapsed .menu-space .content {
    overflow: visible;
    position: relative;
  }
  .sb-collapsed .cl-vnavigation .parent > a {
    background: url("./images/arrow.png") no-repeat scroll right 14px top 18px rgba(0, 0, 0, 0);
  }
  .sb-collapsed .cl-vnavigation li a span {
    opacity: 1;
  }
  .sb-collapsed .cl-vnavigation li a i {
    font-size: 14px;
    margin-right: 7px;
    text-align: center;
    width: 20px;
  }
  .sb-collapsed .cl-vnavigation li.open ul {
    display: block !important;
  }
  .cl-sidebar .cl-toggle {
    display: block;
  }
  .cl-sidebar .cl-vnavigation {
    display: none;
  }
  .cl-sidebar .side-user {
    display: none;
  }
  /*Stats*/
  .butstyle {
    width: 100%;
    margin-right: 10px;
    margin-left: 0;
    max-width: 45%;
  }
  /*Forms*/
  .form-horizontal.group-border .form-group {
    padding: 5px 0 15px 0;
  }
  .form-horizontal.group-border .form-group label {
    margin-bottom: 7px;
  }
  .form-horizontal.group-border-dashed .form-group label {
    margin-bottom: 7px;
  }
  /*Buttons*/
  /*Login*/
  .login-container .middle-login {
    width: 100%;
    margin-left: 0px;
    padding: 0 15px;
    left: auto;
  }
  .cl-sidebar .collapse-button {
    display: none;
  }
}
/*PQS*/
.tab-invalid {
  background-color: rgba(169, 68, 66, 0.2);
}
.max-width-200 {
  max-width: 200px;
}
.max-width-80 {
  max-width: 90%;
}
.pointer {
  cursor: pointer;
}
.inline {
  display: inline;
}
::-webkit-input-placeholder {
  font-style: italic;
  color: #999;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #999;
}
:-ms-input-placeholder {
  font-style: italic;
  color: #999;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
.btn {
  font-weight: 600;
}
.red,
.error {
  color: red;
}
.green,
.success {
  color: #7ebd1d;
}
.warning,
.amber {
  color: #ff7700;
}
.heading-separator {
  padding-left: 10px;
  color: #DDD;
}
textarea {
  resize: vertical;
}
@media (min-width: 768px) {
  .notifications-panel-row {
    overflow: hidden;
  }
  .notifications-panel-parent {
    top: 0;
    bottom: 110px;
    right: 0;
    background-color: #eaeaea;
    padding: 0;
    position: absolute;
  }
  .notifications-panel {
    background: #eaeaea;
    padding: 26px 18px;
    min-height: 100%;
  }
  .notifications-panel h1 {
    border-bottom: 1px solid #CCC;
    padding-bottom: 12px;
    margin-bottom: 30px;
  }
}
.apply-for-pqs {
  position: relative;
  background: #272930 url(../images/pqs-register-bg.png) center right no-repeat;
  padding-left: 1.25em;
  padding-right: 1.2em;
  padding-bottom: 0.5em;
  background-clip: padding-box;
}
.apply-for-pqs h3 {
  font-weight: 300;
}
.apply-for-pqs h3 a {
  color: white;
}
.apply-for-pqs .btn-primary {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -23.5px;
  font-weight: 600;
  font-size: 1.2em;
}
.search-bar {
  width: 200px;
}
.search-bar li.dropdown.button {
  padding-bottom: 0;
}
.search-bar input[type="text"].form-control.search {
  height: 30px;
  padding: 4px 8px;
}
.search-bar .input-group-btn .btn.btn-primary {
  padding: 4px 8px;
}
.no-margin {
  margin: 0;
}
.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.no-padding {
  padding: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-top {
  padding-top: 0 !important;
}
.double-input-left {
  border-right-color: white;
}
.double-input-left,
.double-input-right {
  margin-top: -1px;
  width: 50%;
  padding-right: 0px;
}
.square-select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0;
  background-image: url("/images/down.png");
  background-position: 98% 50%;
  background-repeat: no-repeat;
}
.btn[disabled] {
  background-color: #4c8b00;
}
.register-sub-title {
  font-size: 1.1em;
}
.semi-bold {
  font-weight: 500;
}
/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 110px;
  background-color: #f5f5f5;
  padding: 20px 30px;
}
.footer .pqs-logo-large {
  padding: 6px 20px 0 0;
}
.footer .dark-links {
  padding-right: 20px;
}
.footer .dark-links a {
  color: #444;
}
.login-footer {
  position: absolute;
  bottom: 0;
}
.footer.mini-footer {
  position: static;
  display: inline-block;
  text-align: center;
}
@media (min-width: 768px) {
  .panel-min-height {
    min-height: 330px;
  }
}
.padding-left {
  padding-left: 15px;
}
.monitoring-title .radio-inline.first {
  padding-left: 0;
}
ul.timeline li > .date {
  padding: 0;
}
.row.row-slim {
  margin: 0 -2px 0 -2px !important;
}
.row.row-slim div[class^="col"] {
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.row.row-basket {
  margin-top: -10px !important;
}
.row.row-no-vertical-margins {
  margin-top: -20px !important;
}
.accordion.accordion-semi .panel-heading a,
.accordion.accordion-semi .panel-heading a.collapsed {
  background-color: white !important;
  border-bottom: 1px solid #EEE;
  font-size: 16px;
  padding: 10px 10px;
}
.accordion.accordion-semi .panel-heading.success a,
.accordion.accordion-semi .panel-heading.success a.collapsed {
  background-color: #7ebd1d !important;
  color: white !important;
  font-size: 16px;
}
.accordion.accordion-semi .panel-heading.danger a,
.accordion.accordion-semi .panel-heading.danger a.collapsed {
  background-color: #DA4932 !important;
  color: white !important;
  font-size: 16px;
}
.accordion .panel-heading a,
.accordion .panel-heading a.collapsed {
  color: #444 !important;
  font-size: 24px;
}
.accordion.accordion-semi .panel-collapse .panel-body {
  padding: 10px 15px;
}
.mark-assessment-as-complete label {
  margin: 0;
  margin-top: 12px;
  margin-left: 8px;
  font-size: 18px;
}
.mark-assessment-as-complete .btn-group .btn {
  margin-bottom: 0 !important;
}
.note-save-message {
  display: none;
}
.bg-danger {
  background: #f4c0b7 !important;
}
.bg-warning {
  background: #ffd699 !important;
}
.bg-success {
  background: #c4ed86 !important;
}
.btn.btn-success.disabled {
  background: #afe65a;
  border-color: #99df2e;
}
.profile-avatar {
  max-width: 256px;
  max-height: 256px;
}
.lone-icon {
  margin-right: 0 !important;
}
table thead th {
  font-weight: 600;
}
.delete-cross {
  margin-left: 15px;
  color: red;
  cursor: pointer;
  line-height: 15px;
}
.fade {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
.lead {
  font-weight: 300;
}
@media (max-width: 480px) {
  .text-center-xs {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .text-center-sm {
    text-align: center;
  }
  .text-left-sm {
    text-align: left;
  }
}
.basket-header {
  margin-top: 5px;
}
.basket-button {
  margin-top: 27px;
  margin-left: 0px;
}
@media (max-width: 768px) {
  .basket-button {
    margin-top: 15px;
  }
}
.summary-item {
  margin-top: 0px !important;
  margin-bottom: 0px;
}
h4 {
  margin-top: 0px;
  margin-bottom: 5px;
}
hr.short {
  margin: 5px 0 5px 0;
}
[class*='col-'].no-gutter {
  padding-right: 0;
  padding-left: 0;
}
label.not-bold {
  font-weight: 400;
}
.panel-pqs {
  border-color: #7ebd1d;
}
.panel-pqs .panel-heading {
  border-color: #7ebd1d;
  background-color: #afe65a;
}
.dataTables_paginate {
  text-align: right;
  padding-top: 0.25em;
}
.dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover {
  color: #4f8703 !important;
  font-weight: bold !important;
  font-size: 14pt !important;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  background: transparent;
  box-shadow: none;
}
.dataTables_paginate .paginate_button:hover {
  color: #4f8703 !important;
}
.dataTables_paginate .ellipsis {
  padding: 0 1em;
}
.scrollable {
  width: 100%;
  max-height: 150px;
  padding-right: 20px;
  overflow: auto;
  white-space: normal;
}
.notice-scrollable {
  width: 100%;
  max-height: 400px;
  padding-right: 20px;
  overflow: auto;
  white-space: normal;
}
.superlink {
  font-weight: bold;
  font-size: 12pt;
}
.upload-table-wrapper {
  overflow-x: scroll;
}
.upload-table {
  table-layout: fixed;
}
ul.typeahead-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
ul.typeahead__list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pagination > li > a,
.pagination > li > span {
  color: #7ebd1d !important;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: white !important;
  background-color: #7ebd1d;
  border-color: #7ebd1d;
}
#toggle-notifications {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  font-family: Arial;
  color: #ffffff;
  background: #1f2126;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  border: none;
  margin-top: 15px;
}
button:focus,
button:hover,
button:active {
  outline-color: #7ebd1d !important;
}
.no-borders {
  border: none !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none;
}
.no-border-bottom {
  border-bottom: none !important;
}
.reverse-highlight li.active > a,
.reverse-highlight li.active > a:hover,
.reverse-highlight li.active > a:focus {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #7ebd1d;
  box-shadow: none;
}
.reverse-highlight li.active > a:after,
.reverse-highlight li.active > a:hover:after,
.reverse-highlight li.active > a:focus:after {
  background: transparent;
}
.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
.panel-heading.introduction {
  background: #fff;
  font-size: 18px;
  padding: 17px 16px;
}
fieldset.form-group .row {
  margin-top: 10px;
}
.notification-user {
  padding-top: 5px;
}
.user-holder .panel,
.subscription-holder .panel {
  margin-bottom: 5px;
}
.flot-x-axis .flot-tick-label {
  white-space: nowrap;
  transform: translate(-9px, 0) rotate(-60deg);
  text-indent: -100%;
  transform-origin: top right;
  text-align: right !important;
}
#graph:not(:empty) {
  height: 300px;
  margin: 5px;
}
.graph-data:not(:empty) {
  margin-top: 50px;
}
#legendholder {
  margin-left: 15px;
}
#legendholder td {
  text-align: left;
  border: none;
  margin: 0px;
  padding: 0px;
}
#legendholder td:last-child {
  border: none;
}
#legendholder td.legendColorBox div {
  border: none;
}
table > tbody > tr > td {
  vertical-align: top;
}
.cl-mcont .header {
  border-bottom: 1px solid #dadada;
}
.cl-mcont .content {
  padding-top: 15px;
}
.cl-mcont .content.slim {
  padding-top: 5px;
}
.table-responsive {
  border: none;
  overflow-x: auto;
  overflow-y: initial;
}
.role-panel .panel-heading a {
  padding: 7px;
}
.inline-block {
  display: inline-block !important;
}
table.requirements-table {
  font-size: 85% !important;
}
label.normal-weight {
  font-weight: normal !important;
}
.bootstrap-select .btn {
  font-weight: 400;
}
.bootstrap-select li.selected.active {
  width: 100% !important;
}
.bootstrap-select li.selected.active a {
  background-color: #7ebd1d !important;
}
.bootstrap-select .btn:focus {
  outline: none !important;
}
.bootstrap-select .btn:hover {
  outline: none !important;
}
.fuelux .wizard > ul.steps li.active,
.fuelux .wizard > .steps-container > ul.steps li.active {
  color: #7ebd1d;
}
.fuelux .wizard > ul.steps li.active .badge,
.fuelux .wizard > .steps-container > ul.steps li.active .badge {
  background-color: #7ebd1d !important;
}
.cl-vnavigation .active > a {
  border-left: 2px solid #7ebd1d;
}
.typeahead__field input:focus,
.typeahead__field input:active {
  border-color: #7ebd1d;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #7ebd1d;
}
.table-input {
  width: 100% !important;
}
.dataTables_paginate .paginate_button {
  padding: 0px;
}
th.clear {
  background: #c4ed86 !important;
}
td.day.active {
  background-color: #7ebd1d !important;
}
.list-group.thin {
  margin-bottom: 5px;
}
.list-group-item.thin {
  padding: 5px;
}
.dataTable tr.selected {
  background-color: #7ebd1d !important;
}
.dataTable tr.selected td {
  background-color: #7ebd1d !important;
}
.text-danger > a {
  color: #a94442;
}
.butpro .sub span.text-danger {
  color: #a94442;
}
.butpro .sub span.text-success {
  color: #3c763d;
}
.butpro .sub span.text-warning {
  color: #8a6d3b;
}
.fuelux .wizard > ul.steps,
.fuelux .wizard > .steps-container > ul.steps {
  width: auto !important;
}
td.day.active {
  background-color: #7ebd1d !important;
}
.datetimepicker table tr td span.active,
.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active.disabled:hover {
  background-color: #7ebd1d !important;
  background-image: none;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}
.padding-left-20 {
  padding-left: 20px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.well-success {
  background-color: rgba(96, 192, 96, 0.5);
  border-bottom: 1px solid #54A754;
}
.well-warning {
  background-color: rgba(255, 153, 0, 0.5);
  border-bottom: 1px solid #D68000;
}
.well-danger {
  background-color: rgba(218, 73, 50, 0.5);
  border-bottom: 1px solid #CA452E;
}
.toast-success {
  background-color: rgba(96, 192, 96);
}
.toast-warning {
  background-color: rgba(255, 153, 0);
}
.toast-error {
  background-color: rgba(218, 73, 50);
}
.well.well-slim {
  padding: 10px;
  margin-bottom: 10px;
}
.well.well-slim:last-child {
  margin-bottom: 0px;
}
.well.well-slim:first-child {
  margin-bottom: 10px;
}
.assessment-panel {
  margin-bottom: 10px;
}
.assessment-panel .panel-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.dg-btn.dg-btn--ok {
  border-radius: 0px;
  color: #fff;
  background-color: #7ebd1d;
  border-color: #6aa909;
  font-size: 13px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.dg-btn.dg-btn--cancel {
  border-radius: 0px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  font-size: 13px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
}
.compliance-section {
  background-color: #FFF;
  border-radius: 4px;
  margin-top: -3px;
  padding: 6px;
  border: 1px solid #DDD;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.compliance-section label {
  margin: 0px;
}
.compliance-section:nth-child(2) {
  margin-left: 10px;
}
.declaration-section,
.choice-section {
  background-color: #FFF;
  border-radius: 4px;
  margin-top: -3px;
  padding: 6px;
  border: 1px solid #DDD;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.declaration-section label,
.choice-section label {
  font-weight: 400;
  margin: 0px;
}
.vdp-datepicker__calendar .cell.selected {
  background: #7ebd1d !important;
}
.vdp-datepicker__calendar .cell.selected:hover {
  background: #7ebd1d !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #6aa909 !important;
}
thead {
  background-color: #f8f8f8;
}
.basket-scrollable {
  max-height: 80vh;
  overflow-y: auto;
}
.contents-scrollable {
  max-height: 15vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.template-scrollable-mini {
  max-height: 70vh;
  overflow-y: auto;
}
.template-scrollable {
  max-height: 80vh;
  overflow-y: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
table .add-on-table tbody td {
  border: none;
}
table .add-on-table tbody td:last-child {
  vertical-align: middle;
}
h1.dashboard-title {
  margin-top: 15px;
}
.company-swap {
  padding: 10px 6px;
}
.width-90-percent {
  width: 90%;
}
.padding-left-0 {
  padding-left: 0px;
}
input[aria-invalid='true'] {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error {
  border-color: #a94442;
}
table.basket-table td {
  border-bottom: none;
  background-color: #FFF;
  padding: 5px 5px;
}
table.basket-table td:last-child {
  border-right: none;
}
table.basket-table td:first-child {
  border-left: none;
}
.basket-parent-td {
  padding: 0px;
}
.templategroup-preview {
  max-width: 100px;
}
.freak-table {
  border-collapse: collapse;
}
.freak-table td {
  vertical-align: top;
}
.freak-table tr:after {
  content: '\00a0';
  display: table-cell;
}
.table-layout-fixed {
  table-layout: fixed;
}
.dataTables_scroll {
  overflow-x: auto;
}
table.custom-width {
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
}
.butstyle:hover {
  border-color: #7ebd1d;
}
.basket-contents {
  margin-bottom: 20px;
}
.price-footer {
  position: absolute;
  bottom: 0px;
  height: 95px;
}
.basket-container {
  position: relative;
}
@media (min-width: 768px) {
  .modal-xl {
    width: 85%;
    max-width: 1600px;
  }
}
.ql-container {
  font-family: inherit !important;
  font-size: inherit !important;
}
.ql-editor {
  font-family: inherit !important;
  font-size: inherit !important;
}
.assessment-criterion-text h1 em {
  font-size: 2em !important;
}
.assessment-criterion-text h2 em {
  font-size: 1.5em !important;
}
.assessment-criterion-text h3 em {
  font-size: 1.17em !important;
}
.assessment-criterion-text h4 em {
  font-size: 1em !important;
}
.assessment-criterion-text h5 em {
  font-size: 0.83em !important;
}
.assessment-criterion-text h6 {
  font-size: 0.67em !important;
}
.assessment-criterion-text a {
  line-break: anywhere;
}
.multiselect__select {
  height: 100% !important;
}
.dashboard-logo {
  max-height: 35px;
}
.footer-logo {
  max-height: 52px;
}
.nav-pills > li {
  margin-bottom: 5px;
}
.btn.assessment-file-button {
  margin-bottom: 0px !important;
}
.btn-xs.assessment-file-button {
  padding: 1px 3px;
}
@media (max-width: 980px) {
  .collapses thead {
    display: none;
  }
  .collapses tr {
    display: block;
    margin-bottom: 15px;
  }
  .collapses tr td {
    display: block;
    border: none;
    text-align: left;
    padding: 10px;
  }
}
@media (max-width: 767.98px) {
  .custom-header h1 {
    font-size: 24px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 6px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    border: 2px solid #ffffff;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #ffffff;
  }
}
table.unstyled tr {
  background: none;
}
table.unstyled tr:nth-child(2n) {
  background: none;
}
table.unstyled tr td {
  padding: 0px;
  border: none;
}
ul.nav-tabs > li > a {
  text-decoration: underline;
}
ul.nav-pills > li > a {
  text-decoration: underline;
}
.blockHead {
  background-color: white;
  /*width: 150px; */
  height: 48px;
  display: inline-block;
  position: relative;
  margin-right: 24px;
  width: inherit;
  margin-bottom: 10px;
}
.blockContent {
  text-align: center;
  margin-left: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.blockHead:after {
  color: white;
  border-left: 24px solid;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  right: -24px;
  top: 0;
}
.blockHead.bg-danger:after {
  color: #f4c0b7 !important;
}
.blockHead.bg-warning:after {
  color: #ffd699 !important;
}
.blockHead.bg-success:after {
  color: #c8e4a3 !important;
}
.blockHead.bg-bronze:after {
  color: rgba(195, 90, 32, 0.6) !important;
}
.blockHead.bg-silver:after {
  color: rgba(200, 200, 200, 0.6) !important;
}
.blockHead.bg-gold:after {
  color: rgba(190, 143, 31, 0.6) !important;
}
.blockHead.bg-platinum:after {
  color: rgba(127, 127, 127, 0.6) !important;
}
.blockHead:before {
  color: #F6F6F6;
  border-left: 24px solid;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
}
.consultant-spacer {
  width: 100%;
  background: #7f7f7f;
  height: 2px;
}
.consultant-spacer.bg-danger {
  background: #e64d35 !important;
}
.consultant-spacer.bg-warning {
  background: #FF9900 !important;
}
.consultant-spacer.bg-success {
  background: #7ebd1d !important;
}
.underline {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.underline:after {
  border-bottom: 1px solid #f00;
  content: '';
  display: block;
  width: 50%;
}
.underline--left:after {
  margin-right: auto;
  /* ...or just leave it off */
}
.underline--center:after {
  margin-left: auto;
  margin-right: auto;
}
.underline--right:after {
  margin-left: auto;
}
.badge.badge-consultant {
  background-color: #7f7f7f;
}
.input-filter .form-control {
  font-size: 10px;
}
.input-filter .form-control > option {
  padding: 0px;
}
.dataTable > tbody {
  font-size: 12px;
}
td.details-control {
  text-align: center;
  vertical-align: middle;
}
.assessment-access-scroller {
  max-height: 50vh;
  overflow: auto;
  padding: 15px;
  border: 1px solid #efefef;
}
.bg-bronze {
  background-color: rgba(195, 90, 32, 0.6);
}
.bg-silver {
  background-color: rgba(200, 200, 200, 0.6);
}
.bg-gold {
  background-color: rgba(190, 143, 31, 0.6);
}
.bg-platinum {
  background-color: rgba(127, 127, 127, 0.6);
}
.login-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.row.equal {
  display: flex;
  flex-wrap: wrap;
}
.equal > div[class*='col-'] {
  display: flex;
}
.font-size-16 {
  font-size: 16px;
}
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}
/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}
/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}
/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}
/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}
/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}
.typeahead-container {
  position: relative;
}
.typeahead-container * {
  box-sizing: border-box;
}
.typeahead-query {
  width: 100%;
}
.typeahead-filter {
  position: relative;
}
.typeahead-filter button {
  min-width: 66px;
}
.typeahead-field {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
}
.typeahead-field > * {
  display: table-cell;
  vertical-align: top;
}
.typeahead-button {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
}
.typeahead-button button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.typeahead-field input,
.typeahead-select {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #555555;
  background: transparent;
  border: 1px solid #cccccc;
  border-radius: 2px 0 0 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.typeahead-field input {
  -webkit-appearance: none;
  /*Bootstrap 4 override*/
  box-sizing: border-box;
}
.typeahead-container .typeahead-field input {
  background: #fff;
}
.typeahead-container.hint .typeahead-field input {
  background: transparent;
}
.typeahead-container.hint .typeahead-field input:last-child,
.typeahead-hint {
  background: #fff;
}
.typeahead-container button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ccc;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 13px;
  height: 32px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333333;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.typeahead-container button:focus,
.typeahead-container button:active {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.typeahead-container button:hover,
.typeahead-container button:focus {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.typeahead-container button:active,
.typeahead-container button.active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.typeahead-container input.disabled,
.typeahead-container input[disabled],
.typeahead-container button.disabled,
.typeahead-container button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
  background-color: #ffffff;
  border-color: #cccccc;
}
.typeahead-query {
  position: relative;
  z-index: 2;
}
.typeahead-filter,
.typeahead-button {
  z-index: 1;
}
.typeahead-filter button,
.typeahead-button button {
  margin-left: -1px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.typeahead-filter:hover,
.typeahead-filter:active,
.typeahead-filter:focus,
.typeahead-button:hover,
.typeahead-button:active,
.typeahead-button:focus {
  z-index: 1001;
}
.typeahead-filter:hover button:focus,
.typeahead-filter:hover button:active,
.typeahead-filter:active button:focus,
.typeahead-filter:active button:active,
.typeahead-filter:focus button:focus,
.typeahead-filter:focus button:active,
.typeahead-button:hover button:focus,
.typeahead-button:hover button:active,
.typeahead-button:active button:focus,
.typeahead-button:active button:active,
.typeahead-button:focus button:focus,
.typeahead-button:focus button:active {
  z-index: 1001;
}
.typeahead-container.filter .typeahead-filter {
  z-index: 1001;
}
.typeahead-list,
.typeahead-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 13px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.typeahead-result.detached .typeahead-list {
  position: relative;
  z-index: 1041;
  top: auto;
  left: auto;
}
.typeahead-dropdown {
  right: 0;
  left: auto;
  z-index: 1001;
}
.typeahead-list > li {
  position: relative;
  border-top: solid 1px rgba(0, 0, 0, 0.15);
}
.typeahead-list > li:first-child {
  border-top: none;
}
.typeahead-list > li > a,
.typeahead-dropdown > li > a {
  display: block;
  padding: 6px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  text-decoration: none;
}
.typeahead-list > li > a:hover,
.typeahead-list > li > a:focus,
.typeahead-list > li.active > a,
.typeahead-dropdown > li > a:hover,
.typeahead-dropdown > li > a:focus,
.typeahead-dropdown > li.active > a {
  background-color: #ebebeb;
  color: #333;
}
.typeahead-list.empty > li > a {
  cursor: default;
}
.typeahead-list.empty > li > a:hover,
.typeahead-list.empty > li > a:focus,
.typeahead-list.empty > li.active > a {
  background-color: transparent;
}
.typeahead-list > li.typeahead-group > a,
.typeahead-list > li.typeahead-group > a:hover,
.typeahead-list > li.typeahead-group > a:focus,
.typeahead-list > li.typeahead-group.active > a {
  border-color: #9cb4c5;
  color: #305d8c;
  background-color: #d6dde7;
  cursor: default;
}
.typeahead-container.result .typeahead-list,
.typeahead-container.filter .typeahead-dropdown,
.typeahead-container.hint .typeahead-hint,
.typeahead-container.backdrop + .typeahead-backdrop {
  display: block !important;
}
.typeahead-container .typeahead-list,
.typeahead-container .typeahead-dropdown,
.typeahead-container .typeahead-hint,
.typeahead-container + .typeahead-backdrop {
  display: none !important;
}
.typeahead-dropdown .divider {
  height: 1px;
  margin: 5px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.typeahead-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.typeahead-search-icon {
  min-width: 40px;
  height: 18px;
  font-size: 13px;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABH0lEQVR4nJ3SvyvFYRTH8deVkkJ3UUZJIbJ8bzJjMtyMym6w2Njs/gCDP0AGCyWjxYDF5GdJYpS6xaIUw/d8771dT7qc+vZ8vs95zvuc5zmnlGWZsG6sYBGjsXeNHWzjQ8JKARjCEUZSh3CJeTy3OjoicxF8hwX0oi/0HSZwiK4UYKUpeBoHeMdb6OnwTWI5BVgMvYZaovwa1kMvpQBjoY8TwVp84ylAO/YV62cKcBt65hfAbKwPKcBu6E2UE8Hl8MF+CrCFG/nwnKKKnviqONOYj6NWQDFIg/I+/3ikFnuUX6d+lY4mR4ZVnMvnoIYLbKCCp0h0otG5egXt2HAED+BFPmAP7bYR7jGHV/RjCjr/AICryFzB3n8ARSX3xc83qRk4q9rDNWcAAAAASUVORK5CYII=) no-repeat scroll center center transparent;
}
h3.addon {
  margin-top: 0px;
  margin-bottom: 8px;
}
#selected {
  background-color: #F6F6F6;
  min-height: 55px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border: black;
  border-width: 1px;
  border-style: dashed;
}
#available .accordion {
  margin-bottom: 10px;
}
#available .panel-body {
  padding: 10px 0px;
}
.dragula .draggable-content {
  padding: 5px 10px 5px 10px;
  color: #FFF;
  background-color: #7ebd1d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.dragula .draggable-content button {
  position: absolute;
  left: 0;
  bottom: 0;
}
.dragula .draggable-content button.btn {
  padding: 6px 10px 6px 10px;
  margin-bottom: 0px !important;
}
.dragula .draggable-content button.btn i {
  margin-right: 0px;
}
.dragula .draggable {
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.nav-pills {
  padding-bottom: 10px;
}
.nav-pills > li {
  position: relative;
  overflow: visible;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
}
.nav-pills > li + li {
  margin-left: 0;
}
.nav-pills > li:first-child {
  border-left: 0;
}
.nav-pills > li:first-child a {
  border-radius: 5px 0 0 5px;
}
.nav-pills > li:last-child {
  border-right: 0;
}
.nav-pills > li:last-child a {
  border-radius: 0 5px 5px 0;
}
.nav-pills > li a {
  border-radius: 0;
  background-color: #eee;
}
.nav-pills > li:not(:last-child) a:after {
  position: absolute;
  content: "";
  top: 0px;
  right: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: transparent transparent transparent #eee;
  z-index: 150;
}
.nav-pills > li:not(:first-child) a:before {
  position: absolute;
  content: "";
  top: 0px;
  left: -20px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: #eee #eee #eee transparent;
  z-index: 150;
}
.nav-pills > li:hover:not(:last-child) a:after {
  border-color: transparent transparent transparent #fff;
}
.nav-pills > li:hover:not(:first-child) a:before {
  border-color: #fff #fff #fff transparent;
}
.nav-pills > li:hover a {
  background-color: #fff;
  color: #333;
}
.nav-pills > li.active:not(:last-child) a:after {
  border-color: transparent transparent transparent #fff;
}
.nav-pills > li.active:not(:first-child) a:before {
  border-color: #fff #fff #fff transparent;
}
.nav-pills > li.active a {
  background-color: #fff;
  color: #333;
}
.nav-pills > li.active a:focus {
  background-color: #fff;
  color: #333;
}
.nav-pills > li.active a:hover {
  background-color: #fff;
  color: #333;
}
.multiselect__tag {
  background: #7ebd1d;
}
.multiselect__option--highlight {
  background: #7ebd1d;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #7ebd1d;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 13px;
}
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #7ebd1d;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 13px;
  border: solid grey;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
