.nav-pills {
  padding-bottom: 10px;

  > li {

    position: relative;
    overflow: visible;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;

    + li {
      margin-left: 0;
    }

    &:first-child {
      border-left: 0;

      a {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {
      border-right: 0;

      a {
        border-radius: 0 5px 5px 0;
      }
    }

    a {
      border-radius: 0;
      background-color: #eee;
    }

    &:not(:last-child) a:after {
      position: absolute;
      content: "";
      top: 0px;
      right: -20px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 20px 0 20px 20px;
      border-color: transparent transparent transparent #eee;
      z-index: 150;
    }

    &:not(:first-child) a:before {
      position: absolute;
      content: "";
      top: 0px;
      left: -20px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 20px 0 20px 20px;
      border-color: #eee #eee #eee transparent;
      z-index: 150;
    }

    &:hover {
      &:not(:last-child) a:after {
        border-color: transparent transparent transparent #fff;
      }

      &:not(:first-child) a:before {
        border-color: #fff #fff #fff transparent;
      }

      a {
        background-color: #fff;
        color: #333;
      }
    }

    &.active {
      &:not(:last-child) a:after {
        border-color: transparent transparent transparent #fff;
      }

      &:not(:first-child) a:before {
        border-color: #fff #fff #fff transparent;
      }

      a {
        background-color: #fff;
        color: #333;
        &:focus {
          background-color: #fff;
          color: #333;
        }

        &:hover {
          background-color: #fff;
          color: #333;
        }
      }
    }
  }
}