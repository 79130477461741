/*Labels*/
.label {
	border-radius:0;
	-webkit-border-radius:0;
	 font-weight: normal;
	font-size: 95%;
}
.label-default {background-color:#a1a1a1;}
.label i{font-size:100% !important;color: inherit !important;width: auto !important;min-width: 11px;}
.label-success {background-color: #60C060;}
.label-primary {background-color: #4D90FD;}
.label-warning {background-color: #fcad37;}
.label-danger {background-color: #ED5B56;}
.label-info {background-color: #5DC4EA;}

/*Bagde*/
.badge {
	font-weight: normal;
	font-size: 95%;
	padding:4px 6px;
	background-color:#a1a1a1;
}

.badge-primary{background-color: #4D90FD !important;}
.badge-success{background-color:  #60C060 !important;}
.badge-info{background-color: #5DC4EA !important;}
.badge-warning{background-color: #fcad37 !important;}
.badge-danger{background-color: #ED5B56 !important;}

