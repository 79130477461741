html, body {
    height: 100%;
}
.container-fluid,
.cl-mcont {
    min-height: 100%;
    height:auto !important; /* cross-browser */
    height: 100%; /* cross-browser */
    // overflow-y: hidden; /* don't show content that exceeds my height */
}

.cl-mcont{
	max-width: 1800px;
	background-color: #F6F6F6;
    color: inherit;
    // font-size: 13px;
    font-weight: 500;
	line-height: 21px;
    padding: 15px 20px 200px 20px;
    margin-top: 0;

    &.dashboard {
        min-height: 100%;
        height:auto !important; /* cross-browser */
        height: 100%; /* cross-browser */
        margin-top: -17px;
        padding-top: 0;
        padding-right: 15px;
        padding-bottom: 110px;
    }
}

.cl-mcont.white{background:#fff;}

.cl-mcont h1 {
    line-height: 1.3em;
}

#cl-wrapper{
  padding-top: 52px;
}
