/*Alerts*/
.alert {border-radius:0;-webkit-border-radius:0;box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11);}
.alert .sign{font-size:20px;vertical-align: middle;margin-right:5px;text-align:center;width:25px; display: inline-block;}
.alert-success {
    background-color: #DBF6D3;
    border-color: #AED4A5;
    color: #569745;
}
.alert-info {
    background-color: #D9EDF7;
    border-color: #98cce6;
    color: #3A87AD;
}
.alert-warning {
    background-color: #FCF8E3;
    border-color: #F1DAAB;
    color: #C09853;
}
.alert-danger {
    background-color: #F2DEDE;
    border-color: #e0b1b8;
    color: #B94A48;
}

.alert-white{
	background-image: linear-gradient(to bottom, #FFFFFF, #F9F9F9);
	border-top-color: #d8d8d8;
	border-bottom-color: #bdbdbd;
	border-left-color: #cacaca;
	border-right-color: #cacaca;
	color:#404040;
	padding-left: 61px;
	position:relative;
}

.alert-white .icon{
	text-align:center;
	width:45px;
	height: 103%;
	position:absolute;
	top:-1px;
	left:-1px;
	border:1px solid #BDBDBD;
	padding-top:15px;
}
.alert-white .icon:after{
  -webkit-transform:  rotate(45deg);
     -moz-transform:  rotate(45deg);
      -ms-transform:  rotate(45deg);
       -o-transform:  rotate(45deg);
		  transform:  rotate(45deg);

	display:block;
	content: '';
	width:10px;
	height:10px;
	border:1px solid #BDBDBD;
	position:absolute;
	border-left:0;
	border-bottom:0;
	top:50%;
	right:-6px;
	margin-top:-3px;
	background:#fff;
}

.alert-white.rounded{border-radius:3px;-webkit-border-radius:3px;}
.alert-white.rounded .icon{border-radius:3px 0 0 3px;-webkit-border-radius:3px 0 0 3px;}

.alert-white .icon i{
	font-size: 20px;
	color:#FFF;
	left: 12px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
}

.alert-white.alert-danger .icon, .alert-white.alert-danger .icon:after{
	border-color:#ca452e;
	background:#da4932;
}

.alert-white.alert-info .icon, .alert-white.alert-info .icon:after{
	border-color:#3A8ACE;
	background:#4D90FD;
}

.alert-white.alert-warning .icon, .alert-white.alert-warning .icon:after{
	border-color:#D68000;
	background:#FC9700;
}

.alert-white.alert-success .icon, .alert-white.alert-success .icon:after{
	border-color:@pqsgreenborder;
	background:@pqsgreen;
}

.dashboard-alert {
    margin-top: 20px;
    margin-bottom: 0;
}
