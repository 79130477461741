/*Responsive CSS*/
@media (max-width: 979px) {

  .cl-mcont {

    max-width: calc(100vw - 69px);
  }
}

@media screen and (min-width: 979px) {

  .cl-mcont {

    max-width: calc(100vw - 231px);
  }
}

@media (min-width: 1200px) {
  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }
}

@media (min-width: 980px) and (max-width: 1199px) {
  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 979px) {

  // Added 9/11/2021
  .cl-vnavigation {
    width: 54px !important;
  }

  .butstyle {
    width: 30.9%;
  }

  .butstyle:first-child {
    margin-left: 0;
  }

  .navbar-default .navbar-brand span {
    display: none;
  }

  .cl-sidebar {
    width: 55px;
  }

  #pcont {
    margin-left: 55px;
  }

  #cl-wrapper.fixed-menu .menu-space {
    width: 55px;
  }

  .cl-vnavigation li {
    position: relative;
  }

  .cl-vnavigation li a {
  }

  .cl-vnavigation .parent a {
    background: none;
  }

  .cl-vnavigation li a i {
    margin: 0;
    font-size: 17px;
  }

  .cl-vnavigation li a span {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .cl-sidebar .side-user {
    display: none;
  }

  .cl-sidebar .cl-vnavigation {
    display: block !important;
  }

  .cl-vnavigation li ul {
    display: none !important;
  }

  .cl-sidebar .collapse-button {
    display: none;
  }

  .cl-sidebar .collapse-button .search {
    display: none;
  }
}

@media (max-width: 767px) {
  #cl-wrapper {
    padding-top: 0;
    display: block;
  }

  #pcont, .sb-collapsed #pcont {
    margin-left: 0;
    display: block;
  }

  .cl-mcont {
    padding: 15px;
    max-width: 100%;

  }

  /*nav bar*/
  .navbar {
    padding-right: 10px;
  }

  .navbar-fixed-top {
    position: relative;
    margin: 0;
  }

  /*dropdown menu*/
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #FFF;
  }

  .navbar-default .navbar-nav .dropdown-submenu > a:after {
    border-width: 0;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #FFF;
  }

  .navbar-nav .dropdown-menu > li > a:hover, .navbar-nav .dropdown-menu > li > a:focus, .navbar-nav .dropdown-submenu:hover > a {
    background-color: transparent;
  }

  .navbar-nav .dropdown-submenu ul {
    display: block;
    padding-left: 20px;
  }

  .navbar-nav .dropdown-header {
    color: #FFF;
  }

  .navbar-nav .col-menu-2 > li > ul > li a {
    color: #FFF;
    padding-left: 45px;
  }

  .navbar-nav .col-menu-3 > li > ul > li a {
    color: #FFF;
    padding-left: 45px;
  }

  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus,
  .navbar-nav > .active > a:active {
    background: transparent !important;
    color: #FFF !important;
  }

  .navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover, .navbar-nav > li > a:focus, .navbar-nav > li > a:active {
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
  }


  .not-nav {
    text-align: center;
    background: @pqsgreen;
    border-top: 1px solid @pqsgreen + 25%;
    margin: 0;
  }

  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: @pqsgreen + 25%;
  }

  .navbar-default .navbar-nav > li.button {
    float: none;
    display: inline-block;
    padding: 10px 4px;
    top: 100%;
  }

  .not-nav > li > .dropdown-menu {
    text-align: left;
  }

  .navbar-default .navbar-nav > li.button > a {

  }

  .navbar-default .navbar-nav > li.button > a > i {
    font-size: 17px;
    vertical-align: middle;
  }

  .navbar-default .navbar-nav > li.button .dropdown-menu {
    position: absolute;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    border-top: 0;
    margin-left: -110px;
  }

  .navbar-default .navbar-nav > li.button .dropdown-menu > li > a {
    text-align: left;
  }

  .navbar-collapse {
    padding: 0 !important;
  }

  .navbar-collapse .navbar-nav {
    margin: 0;
  }

  .profile_menu {
    margin: 0;
  }

  .profile_menu .dropdown-toggle {
    padding-left: 15px;
  }

  .navbar-default .navbar-brand {
    width: auto;
  }

  .user-nav {
    margin: 7.5px -15px;
  }

  .navbar-collapse.in {
    overflow: visible;
  }

  #head-nav {
    padding: 0;
  }

  #head-nav .navbar-nav {
    padding: 0 10px;
  }


  .navbar-header {
    padding: 0 10px;
  }

  .col-menu-2 > div:first-child {
    border-right: 0;
  }

  /*end - dropdown*/
  .cl-sidebar, .sb-collapsed .cl-sidebar {
    background: none repeat scroll 0 0 @sidebar-background;
    width: 100%;
    position: relative;
    z-index: 1020;
    display: block;
    padding-bottom: 0;
  }


  #cl-wrapper.fixed-menu .menu-space, #cl-wrapper.fixed-menu.sb-collapsed .menu-space {
    position: relative;
    height: auto !important;
    width: 100%;
  }

  #cl-wrapper .menu-space {
    min-height: 0 !important;
  }

  #cl-wrapper.fixed-menu .menu-space .content, #cl-wrapper.fixed-menu.sb-collapsed .menu-space .content {
    overflow: visible;
    position: relative;
  }

  .sb-collapsed .cl-vnavigation .parent > a {
    background: url("../images/arrow.png") no-repeat scroll right 14px top 18px rgba(0, 0, 0, 0);
  }

  .sb-collapsed .cl-vnavigation li a span {
    opacity: 1;
  }

  .sb-collapsed .cl-vnavigation li a i {
    font-size: 14px;
    margin-right: 7px;
    text-align: center;
    width: 20px;
  }

  .sb-collapsed .cl-vnavigation li.open ul {
    display: block !important;
  }

  .cl-sidebar .cl-toggle {
    display: block;
  }

  .cl-sidebar .cl-vnavigation {
    display: none;
  }

  .cl-sidebar .side-user {
    display: none;
  }

  /*Stats*/
  .butstyle {
    width: 100%;
    margin-right: 10px;
    margin-left: 0;
    max-width: 45%;
  }

  /*Forms*/
  .form-horizontal.group-border .form-group {
    padding: 5px 0 15px 0;
  }

  .form-horizontal.group-border .form-group label {
    margin-bottom: 7px;
  }

  .form-horizontal.group-border-dashed .form-group label {
    margin-bottom: 7px;
  }

  /*Buttons*/
  /*Login*/
  .login-container .middle-login {
    width: 100%;
    margin-left: 0px;
    padding: 0 15px;
    left: auto;
  }

  .cl-sidebar .collapse-button {
    display: none;
  }
}
