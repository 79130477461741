/*Modals*/
.modal {overflow: auto;}

.modal-content {border-radius:5px;-webkit-border-radius:5px;border:0;}

.custom-width{
  width: 40%;
  max-width: none;
}

.modal-content > .close {
	margin-right:10px;
	margin-top: 10px;
}
.md-content {
    background:#FFFFFF;
    border: 0 none;
    border-radius: 5px;
    color: #555555;
    overflow: hidden;
}
.md-content .modal-header {
    border-bottom: 0 none;
}
.modal-content .modal-header {
	border-bottom:0;
}
.colored-header .modal-header{
  padding: 20px;
  border-bottom: 1px solid @primary-color - 20% !important;
  background-color: @primary-color;
  color: #fff;
}
.colored-header.success .modal-header{
  padding: 20px;
  border-bottom: 1px solid @green - 20% !important;
  background-color: @green;
  color: #fff;
}
.colored-header.info .modal-header{
  padding: 20px;
  border-bottom: 1px solid @blue - 20% !important;
  background-color: @blue;
  color: #fff;
}
.colored-header.warning .modal-header{
  padding: 20px;
  border-bottom: 1px solid @orange - 20% !important;
  background-color: @orange;
  color: #fff;
}
.colored-header.danger .modal-header{
  padding: 20px;
  border-bottom: 1px solid @red - 20% !important;
  background-color: @red;
  color: #fff;
}


.md-dark .modal-header{background-color: @primary-color;color: #fff;}
.md-dark .modal-header{background-color: @primary-color;color: #fff;}
.md-dark .md-content{
  background-color: #23262B;
  color: #BBBEC3;
}
.md-dark .md-content input{
  background-color: transparent;
  color: #b2b2b2;
}
.md-dark .modal-content .modal-footer{
  border: 0;
 background-color: transparent;
}
.md-dark .md-content .modal-footer{
  border: 0;
  background-color: transparent;
}
.md-dark .md-content .modal-footer .btn{
  margin-bottom: 0 !important;
}


.modal-content h4, .modal-content h4{
	font-weight:400;
}

.modal-content h3, .modal-content h3{
	font-weight:300;
  margin-top:0;
}

.modal-content h2{
  margin:0;
}

.modal-content .modal-footer{
	background:#FBFBFB;
	border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
}
@media (min-width: 768px) and (max-width: 979px) {
  .custom-width{
    width: 60%;
    max-width: none;
  }

}
@media (max-width: 767px) {
  .custom-width{
    width: 90%;
    max-width: none;
  }
}