/*Progress*/

.progress {
	height:18px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
}

.progress .progress-bar {
	font-size:11px;
	line-height: 17px;
}

.progress-bar-info {
    background-color: #4D90FD;
}

.progress-bar-success {
    background-color: #60C060;
}
.progress-bar-warning {
    background-color: #FC9700;
}
.progress-bar-danger {
    background-color: #DA4932;
}
