.tab-invalid {
  background-color: rgba(169, 68, 66, 0.2);
}

.max-width-200 {
  max-width: 200px;
}

.max-width-80 {

  max-width: 90%;
}

.pointer {
  cursor: pointer;
}

.inline {

  display: inline;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: #999;
}

::-moz-placeholder { /* Firefox 19+ */
  font-style: italic;
  color: #999;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #999;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


.btn {
  font-weight: 600;
}


.red,
.error {
  color: red;
}

.green,
.success {
  color: @pqsgreen;
}

.warning,
.amber {
  color: #ff7700;
}

.heading-separator {
  padding-left: 10px;
  color: #DDD;
}

textarea {
  resize: vertical;
}

@applyBgColour: #272930;

@media (min-width: 768px) {
  .notifications-panel-row {
    overflow: hidden;
  }

  .notifications-panel-parent {
    // position: absolute;
    top: 0;
    bottom: 110px;
    right: 0;
    background-color: #eaeaea;
    padding: 0;
    position: absolute;
  }

  .notifications-panel {
    // display: inline-block;
    background: #eaeaea;
    padding: 26px 18px;
    min-height: 100%;

    h1 {
      border-bottom: 1px solid #CCC;
      padding-bottom: 12px;
      margin-bottom: 30px;
    }
  }
}


.apply-for-pqs {
  position: relative;
  background: @applyBgColour url(../../images/pqs-register-bg.png) center right no-repeat;
  padding-left: 1.25em;
  padding-right: 1.20em;
  padding-bottom: 0.5em;
  background-clip: padding-box;

  h3 {
    font-weight: 300;

    a {
      color: white;
    }
  }

  .btn-primary {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -23.5px;
    font-weight: 600;
    font-size: 1.2em;
  }

  i {
    //color: @applyBgColour;
  }
}


.search-bar {
  width: 200px;

  li.dropdown.button {
    padding-bottom: 0;
  }

  input[type="text"].form-control.search {
    height: 30px;
    padding: 4px 8px;
  }

  .input-group-btn .btn.btn-primary {
    padding: 4px 8px;
  }
}


.no-margin {
  margin: 0;
}

.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-top {
  padding-top: 0 !important;
}


.double-input-left {
  border-right-color: white;
}

.double-input-left,
.double-input-right {
  margin-top: -1px;
  width: 50%;
  padding-right: 0px;
}

.square-select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0;
  background-image: url("/images/down.png");
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.btn[disabled] {
  background-color: @pqslink;
}


.register-sub-title {
  font-size: 1.1em;
}

.semi-bold {
  font-weight: 500;
}


/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  //margin-bottom: 80px;
}

.footer {
  position: absolute;
  border-top: 1px solid #e1e1e1;
  margin-top: 40px;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 110px;
  background-color: #f5f5f5;
  padding: 20px 30px;

  .pqs-logo-large {
    padding: 6px 20px 0 0;
  }

  .dark-links {

    padding-right: 20px;

    a {
      color: #444;
    }
  }
}

.login-footer {

  position: absolute;
  bottom: 0;
}

.footer.mini-footer {
  position: static;
  display: inline-block;
  //height: 200px;
  text-align: center;
}

@media (min-width: 768px) {

  .panel-min-height {
    min-height: 330px;
  }

}


.padding-left {
  padding-left: 15px;
}

.monitoring-title {
  .radio-inline.first {
    padding-left: 0;
  }
}

ul.timeline li > .date {
  padding: 0;
}


// .reduce-gutter(@size: 5px) {
//     .row {
//         .make-row(@size);
//     }
//     .row .col:first-child,
//     .row .col:last-child {
//         padding-right: @size;
//         padding-left: @size;
//     }
// }


.row {
  &.row-slim {
    margin: 0 -2px 0 -2px !important;

    div[class^="col"] {
      padding-right: 2px !important;
      padding-left: 2px !important;
    }
  }

  &.row-basket {
    margin-top: -10px !important;

  }

  &.row-no-vertical-margins {
    margin-top: -20px !important;

  }
}


// Overwrite Bootstrap CSS for the Assessment accordion

.accordion.accordion-semi .panel-heading a,
.accordion.accordion-semi .panel-heading a.collapsed {
  background-color: white !important;
  border-bottom: 1px solid #EEE;
  font-size: 16px;
  padding: 10px 10px;
}

.accordion.accordion-semi .panel-heading.success a,
.accordion.accordion-semi .panel-heading.success a.collapsed {
  background-color: @pqsgreen !important;
  color: white !important;
  font-size: 16px;
}

.accordion.accordion-semi .panel-heading.danger a,
.accordion.accordion-semi .panel-heading.danger a.collapsed {
  background-color: #DA4932 !important;
  color: white !important;
  font-size: 16px;
}

.accordion .panel-heading a,
.accordion .panel-heading a.collapsed {
  color: #444 !important;
  font-size: 24px;
}

.accordion.accordion-semi .panel-collapse .panel-body {
  padding: 10px 15px;
}


.mark-assessment-as-complete {

  label {
    margin: 0;
    margin-top: 12px;
    margin-left: 8px;
    font-size: 18px;
  }

  .btn-group .btn {
    margin-bottom: 0 !important;
  }
}


.note-save-message {
  display: none;
}

.bg-danger {
  background: lighten(@red, 30%) !important;
}

.bg-warning {
  background: lighten(@orange, 30%) !important;
}

.bg-success {
  background: lighten(@pqsgreen, 30%) !important;
}

.btn.btn-success.disabled {
  background: lighten(@pqsgreen, 20%);
  border-color: lighten(@pqsgreen, 10%);
}

.profile-avatar {
  max-width: 256px;
  max-height: 256px;
}

.lone-icon {
  margin-right: 0 !important;
}

table thead th {
  font-weight: 600;
}

.delete-cross {
  margin-left: 15px;
  color: red;
  cursor: pointer;
  line-height: 15px;
}

.fade {
  opacity: 0.5;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
}

// Overwrites
.lead {
  font-weight: 300;
}


@media (max-width: 480px) {
  .text-center-xs {
    text-align: center;
  }
}


@media (max-width: 768px) {
  .text-center-sm {
    text-align: center;
  }

  .text-left-sm {
    text-align: left;
  }
}

.basket-header {
  margin-top: 5px;
}

.basket-button {

  margin-top: 27px;
  margin-left: 0px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
}


.summary-item {
  margin-top: 0px !important;
  margin-bottom: 0px;
}

h4 {
  margin-top: 0px;
  margin-bottom: 5px;
}


hr {
  &.short {
    margin: 5px 0 5px 0;
  }
}


[class*='col-'] {
  &.no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}

label {
  &.not-bold {
    font-weight: 400;
  }
}

.panel-pqs {
  border-color: @pqsgreen;

  .panel-heading {
    border-color: @pqsgreen;
    background-color: lighten(@pqsgreen, 20%);
  }
}

// Paging
.dataTables_paginate {
  text-align: right;
  padding-top: 0.25em;

  .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;

    &.current,
    &.current:hover {
      color: #4f8703 !important;
      font-weight: bold !important;
      font-size: 14pt !important;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:active {
      cursor: default;
      background: transparent;
      box-shadow: none;
    }

    &:hover {
      color: #4f8703 !important;

    }
  }

  .ellipsis {
    padding: 0 1em;
  }
}

.scrollable {
  width: 100%;
  max-height: 150px;
  padding-right: 20px;
  overflow: auto;
  white-space: normal;
}

.notice-scrollable {
  width: 100%;
  max-height: 400px;
  padding-right: 20px;
  overflow: auto;
  white-space: normal;
}

.superlink {
  font-weight: bold;
  font-size: 12pt;
}

.upload-table-wrapper {
  overflow-x: scroll;
}

.upload-table {
  table-layout: fixed;
}

ul.typeahead-list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.typeahead__list {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

// Overwrite pagination

.pagination > li > a,
.pagination > li > span {
  color: @pqsgreen !important; // use your own color here
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: white !important;
  background-color: @pqsgreen;
  border-color: @pqsgreen;
}

#toggle-notifications {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  font-family: Arial;
  color: #ffffff;
  background: #1f2126;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  border: none;
  margin-top: 15px;
}


button:focus, button:hover, button:active {
  outline-color: @pqsgreen !important;
}

// New settings
.no-borders {
  border: none !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: none;
}

.no-border-bottom {
  border-bottom: none !important;
}

.reverse-highlight {

  li.active > a, li.active > a:hover, li.active > a:focus {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #7ebd1d;
    box-shadow: none;
  }

  li.active > a:after, li.active > a:hover:after, li.active > a:focus:after {
    background: transparent;
  }

}

//
//.header {
//	a {
//		margin-top: 15px;
//	}
//	button {
//		margin-top: 15px;
//	}
//}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.panel-heading {
  &.introduction {
    background: #fff;
    font-size: 18px;
    padding: 17px 16px;
  }
}

fieldset {
  &.form-group {
    .row {
      margin-top: 10px;
    }
  }
}

.notification-user {
  padding-top: 5px;
}

.user-holder, .subscription-holder {
  .panel {
    margin-bottom: 5px;
  }
}

// Graphs
.flot-x-axis .flot-tick-label {
  white-space: nowrap;
  transform: translate(-9px, 0) rotate(-60deg);
  text-indent: -100%;
  transform-origin: top right;
  text-align: right !important;

}

#graph:not(:empty) {
  height: 300px;
  margin: 5px;
}

.graph-data:not(:empty) {
  margin-top: 50px;
}


#legendholder {

  margin-left: 15px;

  td {
    text-align: left;
    border: none;
    margin: 0px;
    padding: 0px
  }

  td:last-child {
    border: none;
  }

  td.legendColorBox {
    div {
      border: none;
    }
  }
}

table > tbody > tr > td {
  vertical-align: top;
}

.cl-mcont {

  .header {
    border-bottom: 1px solid #dadada;

  }

  .content {
    padding-top: 15px;

    &.slim {
      padding-top: 5px;
    }
  }
}

.table-responsive {
  border: none;
  overflow-x: auto;
  overflow-y: initial;
}

.role-panel .panel-heading a {
  padding: 7px;
}

.inline-block {
  display: inline-block !important;
}

table.requirements-table {
  font-size: 85% !important;
}

label.normal-weight {
  font-weight: normal !important;
}

.bootstrap-select {

  .btn {
    font-weight: 400;
  }

  li.selected.active {
    width: 100% !important;

    a {
      background-color: @pqsgreen !important;

    }
  }

}

.bootstrap-select {

  .btn:focus {
    outline: none !important;
  }

  .btn:hover {
    outline: none !important;
  }
}

// Fuel UX

// Link green
.fuelux .wizard > ul.steps li.active, .fuelux .wizard > .steps-container > ul.steps li.active {
  color: @pqsgreen;
}

// Badge green
.fuelux .wizard > ul.steps li.active .badge, .fuelux .wizard > .steps-container > ul.steps li.active .badge {
  background-color: @pqsgreen !important;
}

// Navigation active
.cl-vnavigation .active > a {
  border-left: 2px solid @pqsgreen
}

.typeahead__field input:focus, .typeahead__field input:active {
  border-color: @pqsgreen;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  background-color: @pqsgreen;
}

.table-input {
  width: 100% !important;
}

.dataTables_paginate .paginate_button {
  padding: 0px;
}

th.clear {
  background: lighten(@pqsgreen, 30%) !important;
}

td.day {
  &.active {
    background-color: @pqsgreen !important;
  }
}

.list-group {

  &.thin {

    margin-bottom: 5px;
  }
}

.list-group-item {

  &.thin {

    padding: 5px;
  }
}

.dataTable {

  tr {
    &.selected {
      background-color: @pqsgreen !important;

      td {
        background-color: @pqsgreen !important;
      }
    }
  }
}

// Company det
.text-danger > a {
  color: #a94442;
}

.butpro .sub span {

  &.text-danger {
    color: #a94442;
  }

  &.text-success {
    color: #3c763d;
  }

  &.text-warning {
    color: #8a6d3b;
  }
}

.fuelux .wizard > ul.steps, .fuelux .wizard > .steps-container > ul.steps {
  width: auto !important;
}

td.day.active {
  background-color: #7ebd1d !important;
}

.datetimepicker table tr td span.active, .datetimepicker table tr td span.active:hover, .datetimepicker table tr td span.active.disabled, .datetimepicker table tr td span.active.disabled:hover {

  background-color: #7ebd1d !important;
  background-image: none;
}

.padding-5 {

  padding: 5px;
}

.padding-10 {

  padding: 10px;
}

.padding-15 {

  padding: 15px;
}

.padding-left-20 {

  padding-left: 20px;
}

.margin-bottom-5 {

  margin-bottom: 5px;
}

.margin-bottom-10 {

  margin-bottom: 10px;
}

.margin-top-10 {

  margin-top: 10px;
}

// Well .less needed
.well-success {
  background-color: rgba(96, 192, 96, 0.5);
  border-bottom: 1px solid #54A754;
}

.well-warning {
  background-color: rgba(255, 153, 0, 0.5);
  border-bottom: 1px solid #D68000;
}

.well-danger {
  background-color: rgba(218, 73, 50, 0.5);
  border-bottom: 1px solid #CA452E;
}

.toast-success {
  background-color: rgba(96, 192, 96);
}

.toast-warning {
  background-color: rgba(255, 153, 0);
}

.toast-error {
  background-color: rgba(218, 73, 50);
}

.well {
  &.well-slim {

    padding: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:first-child {
      margin-bottom: 10px;
    }
  }
}

.assessment-panel {

  margin-bottom: 10px;

  .panel-body {

    padding-top: 10px;

    padding-bottom: 10px;
  }
}

// vue.less
.dg-btn {

  &.dg-btn--ok {
    border-radius: 0px;
    color: @btn-success-color;
    background-color: @pqsgreen;
    border-color: @pqsgreenborder;
    font-size: 13px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
  }

  &.dg-btn--cancel {
    border-radius: 0px;
    color: @btn-default-color;
    background-color: @btn-default-bg;
    border-color: @btn-default-border;
    font-size: 13px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
  }
}

.compliance-section {

  background-color: #FFF;
  border-radius: 4px;
  margin-top: -3px;
  padding: 6px;
  border: 1px solid #DDD;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  label {
    margin: 0px;
  }

  &:nth-child(2) {
    margin-left: 10px;
  }
}

.declaration-section, .choice-section {

  background-color: #FFF;
  border-radius: 4px;
  margin-top: -3px;
  padding: 6px;
  border: 1px solid #DDD;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  label {
    font-weight: 400;
    margin: 0px;
  }
}

.vdp-datepicker__calendar .cell {

  &.selected {

    background: @pqsgreen !important;

    &:hover {

      background: @pqsgreen !important;
    }
  }
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid @pqsgreenborder !important;
}

thead {

  background-color: #f8f8f8;
  //
  //&.thead-grey {
  //	background-color: #f8f8f8;
  //}
}

table {

  //&.assessment-table {
  //
  //	thead tr th:first-child {
  //
  //		width:1%;
  //
  //		white-space:nowrap;
  //	}
  //}
}

//#nprogress .bar {
//	background: red !important;
//}
//
//#nprogress .peg {
//	box-shadow: 0 0 10px red, 0 0 5px red !important;
//}
//
//#nprogress .spinner-icon {
//	border-top-color: red !important;
//	border-left-color: red !important;
//}

.basket-scrollable {

  max-height: 80vh;
  overflow-y: auto;
}

.contents-scrollable {

  max-height: 15vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.template-scrollable-mini {

  max-height: 70vh;
  overflow-y: auto;
}

.template-scrollable {

  max-height: 80vh;
  overflow-y: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

table {
  .add-on-table {

    tbody {

      td {
        border: none;

        &:last-child {
          vertical-align: middle;
        }
      }
    }
  }
}

h1 {
  &.dashboard-title {
    margin-top: 15px;
  }
}

.company-swap {
  padding: 10px 6px;
}

.width-90-percent {

  width: 90%;
}

.padding-left-0 {

  padding-left: 0px;
}

input[aria-invalid='true'] {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.has-error {
  border-color: #a94442;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

table.basket-table {

  td {
    border-bottom: none;
    background-color: #FFF;
    padding: 5px 5px;
  }

  td:last-child {

    border-right: none;
  }

  td:first-child {

    border-left: none;
  }
}

.basket-parent-td {
  padding: 0px;
}

.templategroup-preview {
  max-width: 100px;
}

.freak-table {

  border-collapse: collapse;

  td {
    vertical-align: top;
  }

  tr:after {
    content: '\00a0';
    display: table-cell;
  }

}

//.scrolledTable{
//  overflow-y: auto; clear:both;
//}

.table-layout-fixed {

  table-layout: fixed;
}

.dataTables_scroll {
  overflow-x: auto;
}

table.custom-width {
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
}

.butstyle:hover {
  border-color: @pqsgreen;
}

.basket-contents {
  margin-bottom: 20px;
}

.price-footer {
  position: absolute;
  bottom: 0px;
  height: 95px;
}

.basket-container {
  position: relative;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 85%;
    max-width: 1600px;
  }
}

.ql-container {
  font-family: inherit !important;
  font-size: inherit !important;
}

.ql-editor {
  font-family: inherit !important;
  font-size: inherit !important;
}

.assessment-criterion-text {

  h1 {
    em {
      font-size: 2em !important;
    }
  }

  h2 {
    em {
      font-size: 1.5em !important;
    }
  }

  h3 {
    em {
      font-size: 1.17em !important;
    }
  }

  h4 {
    em {
      font-size: 1em !important;
    }
  }

  h5 {
    em {
      font-size: .83em !important;
    }
  }

  h6 {
    font-size: .67em !important;
  }

  a {
    line-break: anywhere;
  }
}

.multiselect__select {
  height: 100% !important;
}

//.row:before, .row:after {
//  display: inline-block;
//}

// IOS bug on clicking icheck
//label > * {
//  display: block;
//  pointer-events: none;
//}

.dashboard-logo {

  max-height: 35px;
}

.footer-logo {

  max-height: 52px;
}

.nav-pills > li {
  margin-bottom: 5px;
}

.btn {
  &.assessment-file-button {
    margin-bottom: 0px !important;
  }
}

.btn-xs {
  &.assessment-file-button {
    padding: 1px 3px;
  }
}

@media (max-width: 980px) {

  .collapses {

    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 15px;

      td {
        display: block;
        border: none;
        text-align: left;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 767.98px) {


  // collapses was ehre
  .custom-header {
    h1 {
      font-size: 24px;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 6px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 4px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #ffffff;
  }
}

table {
  &.unstyled {
    tr {
      background: none;

      &:nth-child(2n) {
        background: none;
      }

      td {
        padding: 0px;
        border: none;
      }
    }
  }
}

ul.nav-tabs > li > a {
  text-decoration: underline;
}

ul.nav-pills > li > a {
  text-decoration: underline;
}

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Extra large devices (large desktops, 1200px and up)
//@media only screen and (min-width: 1800px) {
//
//  .blockHead {
//    margin-bottom: 0px !important;
//    margin-top: 0px !important;
//    //width: 12.5% !important;
//    width: 10% !important;
//  }
//
//  .blockContainer {
//
//    display: flex;
//  }
//}

.blockHead {
  background-color: white;
  /*width: 150px; */
  height: 48px;
  // Used to center - line-height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 24px;
  width: inherit;
  margin-bottom: 10px;
  //line-height: 46px;
}

.blockContent {
  text-align: center;
  margin-left: 24px;
  //margin-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.blockHead:after {
  color: white;
  border-left: 24px solid;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  right: -24px;
  top: 0
}

.blockHead.bg-danger:after {
  color: #f4c0b7 !important;
}

.blockHead.bg-warning:after {
  color: #ffd699 !important;
}

.blockHead.bg-success:after {
  color: #c8e4a3 !important;
}

.blockHead.bg-bronze:after {
  color: rgba(195, 90, 32, 0.6) !important;
}

.blockHead.bg-silver:after {
  color: rgba(200, 200, 200, 0.6) !important;
}

.blockHead.bg-gold:after {
  color: rgba(190, 143, 31, 0.6) !important;
}

.blockHead.bg-platinum:after {
  color: rgba(127, 127, 127, 0.6) !important;
}

.blockHead:before {
  color: #F6F6F6;
  border-left: 24px solid;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  display: inline-block;
  content: '';
  position: absolute;
  left: 0px;
  top: 0
}

.consultant-spacer {
  width: 100%;
  background: #7f7f7f;
  height: 2px;

  &.bg-danger {
    background: #e64d35 !important;
  }

  &.bg-warning {
    background: #FF9900 !important;
  }

  &.bg-success {
    background: #7ebd1d !important;
  }
}

.underline {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.underline:after {
  border-bottom: 1px solid #f00;
  content: '';
  display: block;
  width: 50%;
}

.underline--left:after {
  margin-right: auto; /* ...or just leave it off */
}

.underline--center:after {
  margin-left: auto;
  margin-right: auto;
}

.underline--right:after {
  margin-left: auto
}

.badge.badge-consultant {

  background-color: #7f7f7f;
}

//9.697

// wizard
//a:not(.disabled) {
//  > .wizard-icon-circle {
//    border:none;
//  }
//}

.input-filter .form-control {
  font-size: 10px;
}

.input-filter .form-control > option {
  padding: 0px;
}

.dataTable > tbody {
  font-size: 12px;
}

td.details-control {
  text-align: center;
  vertical-align: middle;
}

.assessment-access-scroller {
  max-height: 50vh;
  overflow: auto;
  padding: 15px;
  border: 1px solid #efefef;
}

.bg-bronze {
  background-color: rgba(195, 90, 32, 0.6);
}

.bg-silver {
  background-color: rgba(200, 200, 200, 0.6);
}

.bg-gold {
  background-color: rgba(190, 143, 31, 0.6);
}

.bg-platinum {
  background-color: rgba(127, 127, 127, 0.6);
}

.login-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.row.equal {
  display: flex;
  flex-wrap: wrap;
}

.equal > div[class*='col-'] {
  display: flex;
  //flex-direction: column;
}

.font-size-16 {

  font-size: 16px;
}
