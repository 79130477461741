#selected{
    background-color: #F6F6F6;
    min-height: 55px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;

    border: black;
    border-width: 1px;
    border-style: dashed;
}

#available {

    .accordion {
        margin-bottom:10px;
    }

    .panel-body {
        padding: 10px 0px;
    }
}
.dragula{

    .draggable-content {
        padding:5px 10px 5px 10px;
        color:#FFF;
        background-color: @pqsgreen;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        button {
            position: absolute;
            left: 0;
            bottom:0;
            &.btn {
                padding: 6px 10px 6px 10px;
                margin-bottom: 0px !important;

                i{
                    margin-right: 0px;
                }
            }
        }
    }
    .draggable {
        margin-bottom: 10px;
        padding-left:5px;
        padding-right:5px;
    }
}