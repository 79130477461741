.red-chart{
	border-left:1px solid #F27B56;
	border-right:1px solid #F27B56;
	border-bottom:1px solid #d66543;
	border-top:1px solid #ee815e;
	background-image:-moz-linear-gradient(center top , #ff8a65 45%, #e26a45 102%);
	  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#e26a45)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #ff8a65, #e26a45); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #ff8a65, #e26a45); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #ff8a65 45%, #e26a45 102%);
	background-color:#3290E2;
}

.red-box{
	background-image:-moz-linear-gradient(center top , #ff8a65 45%, #F07853 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#F07853)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #ff8a65, #F07853); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #ff8a65, #F07853); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #ff8a65 45%, #F07853 102%);
	background-color:#ff8a65;
	color:#FFFFFF;
}

.red2-box{
	background-image:-moz-linear-gradient(center top , #FF7877 45%, #ED5554 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#FF7877), to(#ED5554)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #FF7877, #ED5554); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #FF7877, #ED5554); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #FF7877 45%, #ED5554 102%);
	background-color:#FF7877;
	color:#FFFFFF;
}

.blue-box{
	background-image:-moz-linear-gradient(center top ,  #52AEFF 45%, #3D9CEF 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#52AEFF), to(#3D9CEF)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #52AEFF, #3D9CEF); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #52AEFF, #3D9CEF); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #52AEFF 45%, #3D9CEF 102%);
	background-color:#52AEFF;
	color:#FFFFFF;
}

.blue-box.borders{
    border-color: #499DE5 #258FEC #1E73BD;
    border-left: 1px solid #258FEC;
    border-right: 1px solid #258FEC;
    border-style: solid;
    border-width: 1px;
}

.green-box{
	background-image:-moz-linear-gradient(center top , #82c261 45%, #70BE46 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#82c261), to(#70BE46)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #82c261, #70BE46); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #82c261, #70BE46); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #82c261 45%, #70BE46 102%);
	background-color:#82c261;
	color:#FFFFFF;
}

.violet-box{
	background-image:-moz-linear-gradient(center top , #b450b2 45%, #853685 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#b450b2), to(#853685)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #b450b2, #853685); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #b450b2, #853685); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #b450b2 45%, #853685 102%);
	background-color:#82c261;
	color:#FFFFFF;
}

.yellow-box{
	background-image:-moz-linear-gradient(center top , #FFC91C 45%, #FAB219 102%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#FFC91C), to(#FAB219)); /* Chrome, Safari 4+ */
	background-image: -webkit-linear-gradient(top, #FFC91C, #FAB219); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background-image:      -o-linear-gradient(top, #FFC91C, #FAB219); /* Opera 11.10-12.00 */
	background-image:         linear-gradient(to bottom, #FFC91C 45%, #FAB219 102%);
	background-color:#82c261;
	color:#FFFFFF;
}

.white-box{
  background:#FFF;
}
