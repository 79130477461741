/*Circle*/
.i-circle{
	border:5px solid #F1F1F1;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	height:80px;
	width:80px;
	display:inline-block;
	padding-top:15px;
	margin-bottom:7px;
}

.i-circle i{
	font-size:34px;
	vertical-align:middle;
	color:#666;
}

.i-circle.success i{color:#60C060;}
.i-circle.primary i{color:#4D90FD;}
.i-circle.info i{color:@blue;}
.i-circle.warning i{color:#FC9700;}
.i-circle.danger i{color:#DA4932;}
