/*Nav Pills*/
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: #4D90FD;
}

/*List Group*/
.list-group-item:first-child {border-radius:0;-webkit-border-radius:0;}
.list-group-item:last-child {border-radius:0;-webkit-border-radius:0;}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #4D90FD;
}
.list-group-item i{min-width:15px;display:inline-block !important;text-align:center;}
.list-group-item h4{margin-bottom:8px;font-size:17px;}
.list-group-item h4{margin-bottom:8px;font-size:15px;}
.list-group-item{
	font-size:12px;
	padding: 13px 15px;
	line-height:22px;
}

a.list-group-item:hover{
	border-left:5px solid #4D90FD;
	border-color:#4D90FD;
	padding-left: 11px;
}
a.list-group-item:hover + a.list-group-item{
	border-top-color:#4D90FD;
}
.list-group .active:hover{
	padding-left: 15px;
	border-left:1px solid #428BCA;
}

/*DarkList Widget*/
.dark-list{
 color: #FFF;
}

.dark-list .header{
  background-color:#272930;
  border-bottom-color:#1A1C20;
}
.dark-list .header h3{color:#999;}

.dark-list .items li{
  background-color:#272930;
  border-bottom: 1px solid #1A1C20;
  border-top: 1px solid #32353E;
}

.dark-list .items li:hover{
  background-color: #2A2C34;
}

.dark-list .items li:first-child{
  border-top:1px solid #32353E;
}

.dark-list .items li:last-child{
  border-bottom:0;
}

.dark-list .items li i{
  color:#FFF;
}

.dark-list .total-data{
  border-top: 1px solid #131519;
  box-shadow: 0 1px 0 #2A2C31 inset;
  background:#1F2126;
  border-radius: 0 0 3px 3px;
}

.dark-list .total-data > a{
  color:#C9D4F6;
}